import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface WebpageSourceInfo {
  type: "webpage";
  website_id: string;
  webpage_id: string;
  url: string;
  loaded_at: string;
}

export interface FileSourceInfo {
  type: "file";
  file_id: string;
  name: string;
  page_number: number;
}

export interface SourceNode {
  id: string;
  org_id: string;
  deal_id: string;
  source_info: FileSourceInfo | WebpageSourceInfo;
  content: string;
}

export interface SourceNodeQueryResponse {
  source_node: SourceNode;
  similarity: number;
}

function encodeQueryParams(params: { [key: string]: string }) {
  return Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]),
    )
    .join("&");
}

export const listDealNodes = async (
  user: User,
  orgId: string,
  dealId: string,
  query: string,
  limit: number,
): Promise<SourceNodeQueryResponse[]> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/nodes?${encodeQueryParams({
        query: query,
        limit: limit.toString(),
      })}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};
