import { XMarkIcon } from "@heroicons/react/20/solid";
import { FieldArrayWithId, UseFormRegister } from "react-hook-form";
import { FormValues } from "./types";

interface RegistryDocumentsProps {
  registryFiles: FieldArrayWithId<FormValues, "registryFiles", "id">[];
  removeRegistryFile: (index: number) => void;
  register: UseFormRegister<FormValues>;
}

const RegistryDocuments = ({
  registryFiles,
  removeRegistryFile,
  register,
}: RegistryDocumentsProps) => {
  return (
    <div className="col-span-1 content-left flex flex-col border p-4 rounded-lg space-y-3 h-full">
      <label htmlFor="registryFiles" className="font-bold text-md">
        Registry Documents
      </label>
      <div className="space-y-2 h-full">
        <div
          className="flex flex-col space-y-2 overflow-y-auto pr-3"
          style={{ maxHeight: "540px" }}
        >
          {registryFiles.map((field, index) => (
            <div className="mt-1" key={field.id}>
              <div className="flex">
                <input
                  key={field.id}
                  {...register(`registryFiles.${index}.fileName`)}
                  type="text"
                  readOnly
                  className="block w-full bg-transparent border-none focus:ring-0 sm:text-sm"
                />
                <button
                  title="Remove"
                  type="button"
                  onClick={() => removeRegistryFile(index)}
                  className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                >
                  <XMarkIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RegistryDocuments;
