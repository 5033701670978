import { useValidatedParams } from "@/utils/router";
import { useEffect, useMemo, useRef } from "react";
import FileViewer from "@/components/FileViewer";
import {
  Button,
  ResizableHandle,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "capsa-ui";
import { ResizablePanel } from "capsa-ui";
import { useGlobalStore } from "@/store";
import { useSourceViewerStore } from "../store/useSourceViewerStore";
import { useParams } from "react-router-dom";

export default function SourceViewer() {
  const { orgId } = useValidatedParams(["orgId"]);
  const { dealId } = useParams();
  const {
    source,
    isOpen,
    closeSourceViewer,
    fileViewerParams,
    setFileViewerParams,
  } = useSourceViewerStore();
  const { closeDashboardNav } = useGlobalStore();
  const prevDealIdRef = useRef<string | undefined>(dealId);

  useEffect(() => {
    if (source) {
      closeDashboardNav();
    }
  }, [source]);

  useEffect(() => {
    if (
      !dealId ||
      (prevDealIdRef.current && dealId !== prevDealIdRef.current)
    ) {
      closeSourceViewer();
    }

    prevDealIdRef.current = dealId;
  }, [dealId, closeSourceViewer]);

  const name = useMemo(() => {
    if (source?.searchResult.type === "file") {
      return source?.searchResult.fileName;
    }
    return source?.searchResult.url;
  }, [source]);

  if (!isOpen || !dealId) {
    return null;
  }

  return (
    <>
      <ResizableHandle withHandle />
      <ResizablePanel minSize={20} id="source-viewer" order={2}>
        <div className="flex flex-1 flex-col h-full overflow-auto bg-surface-1">
          <div className="flex gap-1 h-11 items-center border-b p-1 pl-3">
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex-1 truncate font-medium text-label">
                  {name}
                </div>
              </TooltipTrigger>
              <TooltipContent align="start">{name}</TooltipContent>
            </Tooltip>
            <Button
              iconLeft="cancel"
              variant="ghost"
              flat
              onClick={closeSourceViewer}
            />
          </div>
          <div className="flex-1 min-h-0">
            <FileViewer
              orgId={orgId}
              dealId={dealId}
              {...fileViewerParams}
              setFileViewerParams={setFileViewerParams}
              loadDelay={400}
            />
          </div>
        </div>
      </ResizablePanel>
    </>
  );
}
