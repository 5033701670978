import * as React from "react";
import * as TogglePrimitive from "@radix-ui/react-toggle";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../../../lib/utils";
import { Icon, IconType } from "../../icons";
import { useMemo } from "react";

const styles = cva(
  "group focus-outer clickable pressable bg-surface border inline-flex items-center justify-center rounded text-content disabled:disabled data-[state=on]:bg-surface-primary [&>svg]:text-content-1 data-[state=on]:border-edge-primary-subtle",
  {
    variants: {
      variant: {
        default: "",
        ghost: "mix-blend-multiply border-transparent",
        inverted:
          "pressable bg-surface-inverted hover:brightness-125 bg-opacity-0 text-content-inverted shadow-none border-transparent",
      },
      size: {
        sm: "h-7 px-1.5 gap-1 text-label",
        default: "h-8 px-2 gap-1.5 text-body",
        lg: "h-10 px-3 gap-2 text-subheading",
      },
      iconOnly: {
        true: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
    compoundVariants: [
      { iconOnly: true, size: "sm", class: "w-7 h-7 min-w-7 min-h-7" },
      { iconOnly: true, size: "default", class: "w-8 h-8 min-w-8 min-h-8" },
      { iconOnly: true, size: "lg", class: "w-10 h-10 min-w-10 min-h-10" },
    ],
  },
);

export type ToggleVariant = VariantProps<typeof styles>["variant"];
export type ToggleSize = VariantProps<typeof styles>["size"];

export interface ToggleProps
  extends React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root>,
    VariantProps<typeof styles> {
  disabled?: boolean;
  iconLeft?: IconType;
  iconRight?: IconType;
  pressed?: boolean;
  onPressedChange?: (pressed: boolean) => void;
}

export const Toggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  ToggleProps
>(
  (
    {
      className,
      variant,
      size,
      pressed,
      disabled,
      onPressedChange,
      children,
      iconLeft,
      iconRight,
      ...props
    },
    ref,
  ) => {
    const iconSize = useMemo(() => {
      switch (size) {
        case "lg":
          return "default";
        default:
          return "sm";
      }
    }, [size]);

    const iconOnly = useMemo(
      () => !children && (!!iconLeft || !!iconRight),
      [children, iconLeft, iconRight],
    );

    return (
      <TogglePrimitive.Root
        ref={ref}
        className={cn(styles({ variant, size, iconOnly, className }))}
        pressed={pressed}
        disabled={disabled}
        onPressedChange={onPressedChange}
        {...props}
      >
        {iconLeft && (
          <Icon
            type={iconLeft}
            size={iconSize}
            className="group-data-[state=on]:text-content-primary"
          />
        )}
        {children}
        {iconRight && (
          <Icon
            type={iconRight}
            size={iconSize}
            className="group-data-[state=on]:text-content-primary"
          />
        )}
      </TogglePrimitive.Root>
    );
  },
);

Toggle.displayName = TogglePrimitive.Root.displayName;
