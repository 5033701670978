import { create } from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";
import { combine } from "zustand/middleware";

export const defaultDealName = "New Deal";

const initialState = {
  dealName: defaultDealName,
  companyName: "",
  files: new DataTransfer().files,
  dealId: "",
};

export const useCreateCompanyStore = create(
  devtools(
    subscribeWithSelector(
      combine(initialState, (set) => ({
        reset: () => set(initialState),
        setDealName: (dealName: string) => set({ dealName }),
        setCompanyName: (companyName: string) => set({ companyName }),
        setFiles: (files: FileList) => set({ files }),
        setDealId: (dealId: string) => set({ dealId }),
      })),
    ),
    { name: "CreateCompanyStore" },
  ),
);
