import {
  Button,
  ResizablePanel,
  ResizableHandle,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Text,
} from "capsa-ui";
import { useEffect, useRef } from "react";
import { ImperativePanelHandle } from "react-resizable-panels";
import { SourceNode } from "@/features/assistant/types/api";
import { useAssistantStore } from "../store/useAssistantStore";
import { useSourceViewerStore } from "@/features/sourceViewer/store/useSourceViewerStore";
import { ChatHistory } from "../components/ChatHistory";
import { ChatMessages } from "../components/ChatMessages";
import { ChatInput } from "../components/ChatInput";
import { useAssistantContext } from "../hooks/useAssistantContext";
import { useParams } from "react-router-dom";

export const Assistant = () => {
  const { dealId } = useParams();
  const panelRef = useRef<ImperativePanelHandle>(null);
  const {
    isOpen,
    toggleAssistant,
    context,
    threadId,
    resetThreadId,
    setThreadStatus,
  } = useAssistantStore();
  const { setSource } = useSourceViewerStore();
  useAssistantContext();

  const handleSourceClick = (source: SourceNode) => {
    switch (source.source_info.type) {
      case "webpage":
        setSource({
          citationNumber: 0,
          searchResult: {
            type: source.source_info.type,
            id: source.id,
            websiteId: source.source_info.website_id,
            webpageId: source.source_info.webpage_id,
            url: source.source_info.url,
          },
        });
        break;

      case "file":
      case "file_table":
        setSource({
          citationNumber: 0,
          searchResult: {
            type: "file",
            id: source.id,
            fileId: source.source_info.file_id,
            fileName: source.source_info.name,
            pageNumber: source.source_info.page_number,
          },
        });
        break;

      case "external_webpage":
        window.open(source.source_info.url, "_blank");
        break;
    }
  };

  useEffect(() => {
    if (dealId) {
      resetThreadId();
    }
  }, [dealId]);

  useEffect(() => {
    if (isOpen) {
      setThreadStatus("ready");
    }
  }, [isOpen]);

  if (!isOpen || !context) return null;

  return (
    <>
      <ResizableHandle withHandle />
      <ResizablePanel
        ref={panelRef}
        minSize={20}
        className="bg-surface-1"
        id="context-window"
        order={1}
      >
        <div className="flex flex-1 flex-col h-full">
          <div className="flex h-11 items-center border-b p-1 pl-3">
            <div className="flex items-center gap-1.5">
              <Text type="label" weight="medium">
                AI Assistant
              </Text>
              {context?.name && (
                <Text type="label" weight="medium">
                  - {context?.name}
                </Text>
              )}
            </div>
            <div className="flex-1" />
            <Tooltip>
              <TooltipTrigger>
                <Button
                  iconLeft="cancel"
                  variant="ghost"
                  flat
                  onClick={toggleAssistant}
                />
              </TooltipTrigger>
              <TooltipContent side="left">Close</TooltipContent>
            </Tooltip>
          </div>
          {context && (
            <div className="flex flex-1 gap-4 flex-col h-0 min-h-0 py-3">
              {threadId && <ChatMessages onSourceClick={handleSourceClick} />}
              <ChatInput />
              {!threadId && <ChatHistory />}
            </div>
          )}
        </div>
      </ResizablePanel>
    </>
  );
};
