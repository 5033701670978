import { APIError } from "../../../api/shared";
import { Table } from "../../../api/dealFileTables";
import { CheckIcon } from "@heroicons/react/20/solid";
import { ClipboardIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import FileViewer, { FileViewerParams } from "../../FileViewer";
import { usePostHog } from "posthog-js/react";
import { useAuth0 } from "@auth0/auth0-react";
import info from "../../../info";
import { CheckBox } from "../../base/CheckBox";
import { useTableAggregationQuery } from "../../../hooks/tableAggregations";
import {
  AggregatedTable,
  File,
  FileTable,
  TableAggregationGroup,
  TableAggregationRead,
} from "../../../api/tableAggregations";
import Skeleton from "../../base/Skeleton";
import CreateTableAggregationExportButton from "./CreateTableAggregationExportButton";
import UpdateTableAggregationButton from "./UpdateTableAggregationButton";
import { useValidatedParams } from "@/utils/router";
import { Main } from "@/components/layout/Main";

function CopyToClipboard({ table }: { table: Table }) {
  const [showCopied, setShowCopied] = useState(false);
  const posthog = usePostHog();
  const { user } = useAuth0();

  const tableToText = (table: Table) => {
    const array = [];
    for (let i = 0; i < table.row_count; i++) {
      const row = [];
      for (let j = 0; j < table.column_count; j++) {
        row.push("");
      }
      array.push(row);
    }
    for (const cell of table.cells) {
      array[cell.row_index][cell.column_index] = cell.content;
    }
    return array
      .map((row) => row.map((cell) => `"${cell}"`).join("\t"))
      .join("\n");
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(tableToText(table));
    setShowCopied(true);
    if (user) {
      posthog.capture("copy_table_aggregation_table", {
        org_id: info.orgId,
        user_id: user.sub,
        user_email: user.email,
      });
    }
    setTimeout(() => setShowCopied(false), 2000);
  };

  if (showCopied) {
    return (
      <div className="flex flex-row items-center space-x-2 hover:cursor-pointer">
        <CheckIcon className="h-4 w-4 text-gray-500" />
        <p className="text-xs text-gray-500">Copied!</p>
      </div>
    );
  }

  return (
    <div
      className="flex flex-row items-center space-x-2 hover:cursor-pointer"
      onClick={copyToClipboard}
    >
      <ClipboardIcon className="h-4 w-4 text-gray-500" />
      <p className="text-xs text-gray-500">Copy table</p>
    </div>
  );
}

interface TableSource {
  file_id: string;
  file_name: string;
  table_id: string;
  table_number: number;
  page_number: number | null;
}

function AggregatedTableSources({
  files,
  tables,
  tableGroup,
  setFileViewerParams,
}: {
  orgId: string;
  dealId: string;
  files: Record<string, File>;
  tables: Record<string, FileTable>;
  tableGroup: TableAggregationGroup;
  setFileViewerParams: (params: FileViewerParams) => void;
}) {
  let sources: TableSource[] = [];
  tableGroup.columns.forEach((column) =>
    column.tables.forEach((table) =>
      sources.push({
        file_id: table.file_id,
        file_name: files[table.file_id].name,
        table_id: table.table_id,
        table_number: tables[table.table_id].number,
        page_number: tables[table.table_id].page_number,
      }),
    ),
  );
  // sort by file_name and table_number
  sources = sources.sort((a, b) => {
    if (a.file_name < b.file_name) {
      return -1;
    } else if (a.file_name > b.file_name) {
      return 1;
    } else {
      return a.table_number - b.table_number;
    }
  });
  return (
    <div className="prose prose-sm mt-3">
      <p>Sources:</p>
      <ul className="">
        {sources.map((source, index) => {
          if (source.page_number === null) {
            return (
              <li
                key={index}
                className="hover:cursor-pointer"
                onClick={() =>
                  setFileViewerParams({
                    fileId: source.file_id,
                    pageNumber: 1,
                    websiteId: null,
                    webpageId: null,
                    waiting: true,
                  })
                }
              >
                <p className="text-xs text-blue-500 my-0 ">
                  {source.file_name}
                </p>
              </li>
            );
          }
          return (
            <li
              key={index}
              className="hover:cursor-pointer"
              onClick={() =>
                setFileViewerParams({
                  fileId: source.file_id,
                  pageNumber: source.page_number,
                  websiteId: null,
                  webpageId: null,
                  waiting: true,
                })
              }
            >
              <p className="text-xs text-blue-500 my-0">
                {source.file_name}, Page {source.page_number}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function TableRender({ table }: { table: Table }) {
  const array = [];
  for (let i = 0; i < table.row_count; i++) {
    const row = [];
    for (let j = 0; j < table.column_count; j++) {
      row.push("");
    }
    array.push(row);
  }

  for (const cell of table.cells) {
    array[cell.row_index][cell.column_index] = cell.content;
  }

  return (
    <div className="outline outline-1 outline-gray-100 rounded-md overflow-auto">
      <table className="w-full">
        <tbody>
          {array.map((row, i) => (
            <tr key={i} className={i % 2 ? "bg-white" : "bg-gray-50"}>
              {row.map((cell, j) => (
                <td key={j} className="px-2 truncate">
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function SkeletonTableRender({
  numRows,
  numColumns,
}: {
  numRows: number;
  numColumns: number;
}) {
  const array = [];
  for (let i = 0; i < numRows; i++) {
    const row = [];
    for (let j = 0; j < numColumns; j++) {
      row.push("");
    }
    array.push(row);
  }

  return (
    <div className="outline outline-1 outline-gray-100 rounded-md overflow-auto">
      <table className="w-full">
        <tbody>
          {array.map((row, i) => (
            <tr key={i} className={i % 2 ? "bg-white" : "bg-gray-50"}>
              {row.map((_, j) => (
                <td key={j} className="px-2">
                  <Skeleton width="100%" height="1.5rem" />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function TableAggregationTablesLoading({
  layout,
}: {
  layout: TableAggregationGroup[];
}) {
  return (
    <ul className="divide-y divide-gray-200 border-gray-200">
      {layout.map((_, index) => (
        <li key={index} className="py-4">
          <div className="flex flex-row items-center pb-2">
            <div className="mr-3">
              <CheckBox state={"unchecked"} onClick={() => {}} />
            </div>

            <p className="text-sm font-semibold text-gray-900">
              Table {index + 1}
            </p>

            <div className="ml-auto" />
          </div>
          <SkeletonTableRender numRows={3} numColumns={3} />
        </li>
      ))}
    </ul>
  );
}

function TableAggregationTablesLoaded({
  orgId,
  dealId,
  files,
  tables,
  layout,
  aggregatedTables,
  selectedTables,
  toggleTable,
  setFileViewerParams,
}: {
  orgId: string;
  dealId: string;
  files: Record<string, File>;
  tables: Record<string, FileTable>;
  layout: TableAggregationGroup[];
  aggregatedTables: AggregatedTable[];
  selectedTables: Set<number>;
  toggleTable: (index: number) => void;
  setFileViewerParams: (params: FileViewerParams) => void;
}) {
  return (
    <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200">
      {aggregatedTables.map((aggregatedTable, index) => (
        <li key={index} className="py-4">
          <div className="flex flex-row items-center pb-2">
            <div className="mr-3">
              <CheckBox
                state={selectedTables.has(index) ? "checked" : "unchecked"}
                onClick={() => toggleTable(index)}
              />
            </div>

            <p className="text-sm font-semibold text-gray-900">
              Table {index + 1}
            </p>

            <div className="ml-auto">
              <CopyToClipboard table={aggregatedTable.table} />
            </div>
          </div>
          <TableRender table={aggregatedTable.table} />
          <AggregatedTableSources
            orgId={orgId}
            dealId={dealId}
            files={files}
            tables={tables}
            tableGroup={layout[index]}
            setFileViewerParams={setFileViewerParams}
          />
        </li>
      ))}
    </ul>
  );
}

interface TablesProps {
  orgId: string;
  dealId: string;
  tableAggregationId: string;
  tableAggregation: TableAggregationRead;
  setFileViewerParams: (params: FileViewerParams) => void;
}

function TableAggregationTables({
  orgId,
  dealId,
  tableAggregationId,
  tableAggregation,
  setFileViewerParams,
}: TablesProps) {
  const [selectedTables, setSelectedTables] = useState<Set<number>>(new Set());

  const layout = tableAggregation.data.layout;
  const aggregatedTables = tableAggregation.data.aggregated_tables;

  const toggleAllTables = () => {
    if (selectedTables.size > layout.length / 2) {
      setSelectedTables(new Set());
    } else {
      setSelectedTables(new Set(layout.map((_, index) => index)));
    }
  };

  const toggleTable = (tableNumber: number) => {
    if (selectedTables.has(tableNumber)) {
      const newSelectedTables = new Set(selectedTables);
      newSelectedTables.delete(tableNumber);
      setSelectedTables(newSelectedTables);
    } else {
      const newSelectedTables = new Set(selectedTables);
      newSelectedTables.add(tableNumber);
      setSelectedTables(newSelectedTables);
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row border-b-[1px] border-gray-200 pb-2 mb-2 ml-6 items-center">
        <div className="ml-1 mr-3">
          <CheckBox
            state={
              selectedTables.size === layout.length
                ? "checked"
                : selectedTables.size === 0
                  ? "unchecked"
                  : "indeterminate"
            }
            onClick={toggleAllTables}
          />
        </div>
        <h1 className="text-lg font-semibold text-gray-900 flex-grow">
          Tables
        </h1>
        <CreateTableAggregationExportButton
          orgId={orgId}
          dealId={dealId}
          tableAggregationId={tableAggregationId}
          aggregatedTableIndices={Array.from(selectedTables)}
        />
      </div>
      <div className="flex-grow overflow-auto prose-sm">
        {aggregatedTables === null && (
          <TableAggregationTablesLoading layout={layout} />
        )}
        {aggregatedTables !== null && (
          <TableAggregationTablesLoaded
            orgId={orgId}
            dealId={dealId}
            files={tableAggregation.files}
            tables={tableAggregation.tables}
            layout={layout}
            aggregatedTables={aggregatedTables}
            selectedTables={selectedTables}
            toggleTable={toggleTable}
            setFileViewerParams={setFileViewerParams}
          />
        )}
      </div>
    </div>
  );
}

function _TableAggregation({
  orgId,
  dealId,
  tableAggregationId,
}: {
  orgId: string;
  dealId: string;
  tableAggregationId: string;
}) {
  const tableAggregationQuery = useTableAggregationQuery(
    orgId,
    dealId,
    tableAggregationId,
  );
  const [fileViewerParams, setFileViewerParams] = useState<FileViewerParams>({
    fileId: null,
    pageNumber: null,
    websiteId: null,
    webpageId: null,
    waiting: false,
  });

  if (tableAggregationQuery.isLoading) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="pb-4 text-sm text-gray-500">
            Loading table aggregation...
          </p>
        </ul>
      </div>
    );
  }

  if (
    tableAggregationQuery.error instanceof APIError &&
    tableAggregationQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="pb-4 text-sm text-gray-500">
            {"You don't have permission to view this table aggregation."}
          </p>
        </ul>
      </div>
    );
  }

  if (tableAggregationQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="pb-4 text-sm text-gray-500">
            Error listing table aggregation.
          </p>
        </ul>
      </div>
    );
  }
  return (
    <div className="flex-1 grid pt-3 grid-cols-2 min-h-0">
      <div className="h-full min-h-0">
        <FileViewer
          orgId={orgId}
          dealId={dealId}
          {...fileViewerParams}
          setFileViewerParams={setFileViewerParams}
        />
      </div>
      <div className="h-full min-h-0">
        <TableAggregationTables
          orgId={orgId}
          dealId={dealId}
          tableAggregationId={tableAggregationId}
          tableAggregation={tableAggregationQuery.data}
          setFileViewerParams={setFileViewerParams}
        />
      </div>
    </div>
  );
}

export default function TableAggregation() {
  const { orgId, dealId, tableAggregationId } = useValidatedParams([
    "orgId",
    "dealId",
    "tableAggregationId",
  ]);

  return (
    <Main
      actions={[
        <UpdateTableAggregationButton
          key="update-table-aggregation-button"
          orgId={orgId}
          dealId={dealId}
          tableAggregationId={tableAggregationId}
        />,
      ]}
      className="max-h-screen"
    >
      <_TableAggregation
        orgId={orgId}
        dealId={dealId}
        tableAggregationId={tableAggregationId}
      />
    </Main>
  );
}
