import {
  Icon,
  SidebarGroup,
  SidebarGroupContent,
  Text,
  useSidebar,
} from "capsa-ui";
import { useUpload } from "../UploadProvider";

export const UploadsManager = () => {
  const { uploads } = useUpload();
  const { state } = useSidebar();
  if (uploads.size === 0) {
    return <></>;
  }
  return (
    <SidebarGroup className="overflow-hidden">
      <SidebarGroupContent>
        <div className="flex flex-row items-center px-2 gap-2 pb-2 min-w-0 truncated ml-px">
          <Icon type="upload" className="text-content-2" />
          {state === "expanded" && (
            <Text color="subtle">Uploads ({uploads.size})</Text>
          )}
        </div>
        {state === "expanded" && (
          <div className="rounded bg-surface-2 p-1 ">
            <div className="overflow-auto max-h-48">
              {Array.from(uploads.keys()).map((key) => {
                const upload = uploads.get(key);
                if (!upload) {
                  return <></>;
                }
                return (
                  <div
                    key={key}
                    className="flex flex-row flex-grow items-center space-x-3 p-1"
                  >
                    <p className="text-label truncated flex-1" title={key}>
                      {key}
                    </p>
                    <p className="text-label">
                      {Math.round(
                        (upload.loadedBytes * 100) / upload.totalBytes,
                      )}
                      %
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </SidebarGroupContent>
    </SidebarGroup>
  );
};
