import { APIError } from "../../../api/shared";
// import DeleteFileButton from "./DeleteFileButton";
import { useDealUsersQuery } from "../../../hooks/dealUsers";
import UpdateDealUserButton from "./UpdateDealUserButton";
import DeleteDealUserButton from "./DeleteDealUserButton";
import Table from "../../Table";
import { UsersIcon } from "@heroicons/react/24/outline";
import CreateDealUserButton from "../CreateDealUserButton";
import { useValidatedParams } from "@/utils/router";
import { Main } from "@/components/layout/Main";

function UsersTable({ orgId, dealId }: { orgId: string; dealId: string }) {
  const usersQuery = useDealUsersQuery(orgId, dealId);

  // Insufficient permissions
  if (
    usersQuery.error instanceof APIError &&
    usersQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to list users."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (usersQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">Error listing users.</p>
        </ul>
      </div>
    );
  }

  let data: React.ReactElement[][] = [];
  if (!usersQuery.isLoading) {
    data = usersQuery.data.map((user) => {
      return [
        <p key={`email-${user.id}`}>{user.email}</p>,
        <p key={`roles-${user.id}`}>
          {user.roles.map((role) => role.role).join(", ")}
        </p>,
        <div
          key={`actions-${user.id}`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <UpdateDealUserButton
            orgId={orgId}
            dealId={dealId}
            userId={user.id}
          />
          <DeleteDealUserButton orgId={orgId} dealId={dealId} user={user} />
        </div>,
      ];
    });
  }

  return (
    <Table
      columnHeaders={["Email", "Roles", ""]}
      data={data}
      noDataIcon={UsersIcon}
      noDataMessage="You don't have any users yet."
      noDataButton={<CreateDealUserButton orgId={orgId} dealId={dealId} />}
      isLoading={usersQuery.isLoading}
    />
  );
}

export default function UsersList() {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);

  return (
    <Main
      title="Deal Access"
      description="Manage and control user access to your deal folder, ensuring secure and streamlined collaboration."
      actions={[
        <CreateDealUserButton key={orgId} orgId={orgId} dealId={dealId} />,
      ]}
    >
      <UsersTable orgId={orgId} dealId={dealId} />
    </Main>
  );
}
