import { Document } from "react-pdf";
import { useDealFileQuery } from "../../hooks/deals";
import { DocumentError, StillProcessing } from "./PlaceholderStates";
import { SelectedFileProps } from "./types";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { PDFPage } from "./PDFPage";
import { Spinner } from "capsa-ui";
import { FileViewerToolbar } from "./FileViewerToolbar";
import { useFileViewer } from "./useFileViewer";
import { useEffect } from "react";

export function SelectedFile({
  orgId,
  dealId,
  fileId,
  pageNumber,
  waiting,
  setFileViewerParams,
}: SelectedFileProps) {
  const fileQuery = useDealFileQuery(orgId, dealId, fileId);

  const {
    pdfState,
    onDocumentLoad,
    goToPage,
    onPageInView,
    handleZoom,
    containerRef,
    documentRef,
    pageRefs,
    initialised,
    updateScale,
  } = useFileViewer({ pageNumber: pageNumber || 1, waiting });

  const url =
    fileQuery.isSuccess && fileQuery.data.download_url
      ? process.env.NODE_ENV === "development"
        ? fileQuery.data.download_url.replace(
            "http://storage",
            "http://localhost",
          )
        : fileQuery.data.download_url
      : "";

  useEffect(() => {
    if (pdfState.currentPage !== pageNumber && initialised) {
      setFileViewerParams({
        pageNumber: pdfState.currentPage,
        waiting: false,
        fileId,
        websiteId: null,
        webpageId: null,
      });
    }
  }, [pdfState.currentPage]);

  if (fileQuery.isLoading) {
    return (
      <div className="w-full h-full flex-center">
        <Spinner />
      </div>
    );
  }

  if (fileQuery.isError) {
    return <p>Error loading pages.</p>;
  }

  if (!fileQuery.data.download_url) {
    return <StillProcessing />;
  }

  return (
    <div className="h-full w-full flex flex-col">
      <FileViewerToolbar
        currentPage={pdfState.currentPage}
        totalPages={pdfState.totalPages}
        scale={pdfState.pageScale}
        fileUrl={url}
        fileName={fileQuery.data.name || `file-${fileId}.pdf`}
        onPageInputSubmit={goToPage}
        onZoomIn={() => handleZoom(0.1)}
        onZoomOut={() => handleZoom(-0.1)}
        onFitToView={updateScale}
      />
      <div className="flex-1 relative overflow-auto" ref={containerRef}>
        <div ref={documentRef} className="flex flex-1 flex-col">
          {url && (
            <Document
              file={url}
              onLoadSuccess={onDocumentLoad}
              onLoadError={() => <DocumentError />}
              loading=""
              className="flex flex-col min-h-full gap-3 py-3 px-3 mx-auto"
            >
              {pdfState.isDocumentLoaded &&
                pdfState.totalPages > 0 &&
                Array.from(new Array(pdfState.totalPages), (_, index) => (
                  <PDFPage
                    key={`page_${index + 1}`}
                    ref={(el) => (pageRefs.current[index] = el)}
                    pageIndex={index}
                    scale={pdfState.pageScale}
                    pageDimensions={pdfState.pageDimensions}
                    containerRef={containerRef}
                    onPageInView={onPageInView}
                    initialised={initialised}
                  />
                ))}
            </Document>
          )}
        </div>
      </div>
    </div>
  );
}
