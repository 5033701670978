import { useEffect, useState, useRef } from "react";
import { ThreadMessage } from "../types/api";

type Props = {
  messages: ThreadMessage[];
  scrollableRef: React.RefObject<HTMLDivElement>;
};

export const useChatPosition = ({ messages, scrollableRef }: Props) => {
  const [scrollPadding, setScrollPadding] = useState(0);
  const userAtBottom = useRef(true);
  const prevMessagesLengthRef = useRef(0);
  const userJustSentMessageRef = useRef(true);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollableRef.current;
        userAtBottom.current = scrollTop + clientHeight >= scrollHeight - 10;
      }
    };

    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollableRef]);

  useEffect(() => {
    if (messages.length > prevMessagesLengthRef.current) {
      const newMessage = messages[messages.length - 1];
      if (newMessage && newMessage.role === "user") {
        userJustSentMessageRef.current = true;
      }
    }
    prevMessagesLengthRef.current = messages.length;
  }, [messages]);

  useEffect(() => {
    if (scrollableRef.current) {
      const lastReply = document.querySelector('[data-last-reply="true"]');

      if (lastReply && userAtBottom.current) {
        scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
      }
    }
    if (scrollableRef.current && userJustSentMessageRef.current) {
      const lastMessage = document.querySelector('[data-last-message="true"]');
      if (lastMessage) {
        const containerRect = scrollableRef.current.getBoundingClientRect();
        const messageRect = lastMessage.getBoundingClientRect();
        const scrollOffset = messageRect.top - containerRect.top;

        scrollableRef.current.scrollBy({
          top: scrollOffset,
          behavior: "smooth",
        });

        const paddingNeeded = Math.max(
          0,
          containerRect.height - messageRect.height - 20,
        );

        setScrollPadding(paddingNeeded);

        userJustSentMessageRef.current = false;
      }
    }
  }, [messages]);

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollBy({
        top: scrollableRef.current.scrollHeight,
        behavior: "smooth",
      });

      userJustSentMessageRef.current = false;
    }
  }, [scrollPadding]);

  return {
    scrollPadding,
  };
};
