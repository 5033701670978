import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Button,
  Icon,
  SidebarTrigger,
  useSidebar,
} from "capsa-ui";
import React, { useMemo } from "react";
import { Params, useMatches, useNavigate, useParams } from "react-router-dom";
import { Handle, MatchParam } from "@/types/router";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { DealRead } from "@/api/deals";
import { FileRead } from "@/api/dealFiles";
import { WebsiteRead } from "@/api/websites";
import { TableAggregationRead } from "@/api/tableAggregations";
import { DealUserRead } from "@/api/dealUsers";
import { WidgetTemplate } from "@/features/widgetTemplates/types";
import { OrgInviteRead } from "@/api/invites";
import { Dashboard } from "@/features/dashboards/types/api";
import { useAssistantStore } from "@/features/assistant/store/useAssistantStore";
export default function Header() {
  const matches = useMatches();
  const navigate = useNavigate();
  const params = useParams();
  const queryClient = useQueryClient();
  const queriesUpdating = useIsFetching();
  const sidebar = useSidebar();
  const assistant = useAssistantStore();

  const crumbs = useMemo(
    () =>
      matches
        .filter((match) => match.handle && (match.handle as Handle).crumb)
        .map((match) => ({
          label: handleDynamicLabel(
            (match.handle as Handle).crumb.label,
            params,
            queryClient,
          ),
          icon: (match.handle as Handle).crumb.icon,
          to: match.pathname,
        })),
    [matches, params, queriesUpdating],
  );

  return (
    <header>
      <div className="flex items-center overflow-auto gap-2 bg-surface-1 h-14 px-2 border-b">
        <div className="flex mix-blend-multiply">
          {sidebar.state === "collapsed" && (
            <SidebarTrigger variant="ghost" className="text-content-2" />
          )}
          <Button
            iconLeft="arrowLeft"
            variant="ghost"
            flat
            onClick={() => navigate(-1)}
            disabled={!(window.history.state.idx !== 0)}
          />
          <Button
            iconLeft="arrowRight"
            variant="ghost"
            flat
            onClick={() => navigate(1)}
            disabled={!(window.history.state.idx < window.history.length - 1)}
          />
        </div>
        <Breadcrumb>
          <BreadcrumbList>
            {crumbs.map((crumb, index) => (
              <React.Fragment key={crumb.label}>
                <BreadcrumbItem>
                  {crumb.icon && <Icon type={crumb.icon} />}
                  {index === crumbs.length - 1 ? (
                    <BreadcrumbPage>{crumb.label}</BreadcrumbPage>
                  ) : (
                    <>
                      <BreadcrumbLink onClick={() => navigate(crumb.to)}>
                        {crumb.label}
                      </BreadcrumbLink>
                    </>
                  )}
                </BreadcrumbItem>
                {index < crumbs.length - 1 && (
                  <BreadcrumbSeparator key={`Seperator-${index}`} />
                )}
              </React.Fragment>
            ))}
          </BreadcrumbList>
        </Breadcrumb>
        <div className="flex-1" />
        {!!params.dealId && (
          <Button iconRight="capsa" onClick={assistant.toggleAssistant}>
            Assistant
          </Button>
        )}
      </div>
    </header>
  );
}

const handleDynamicLabel = (
  match: string,
  params: Readonly<Params<string>>,
  queryClient: ReturnType<typeof useQueryClient>,
) => {
  const {
    orgId,
    dealId,
    fileId,
    websiteId,
    tableAggregationId,
    userId,
    widgetTemplateId,
    inviteId,
    dashboardId,
  } = params;
  let data;
  switch (match) {
    case MatchParam.fileId:
      data = queryClient.getQueryData([
        orgId,
        "deals",
        dealId,
        "files",
        fileId,
      ]);
      if (!data) return "File";
      return (data as FileRead).name;

    case MatchParam.dealId:
      data = queryClient.getQueryData([orgId, "deals", dealId]);
      if (!data) return "Deal";
      return (data as DealRead).name;

    case MatchParam.websiteId:
      data = queryClient.getQueryData([
        orgId,
        "deals",
        dealId,
        "websites",
        websiteId,
      ]);
      if (!data) return "Website";
      return (data as WebsiteRead).url;

    case MatchParam.tableAggregationId:
      data = queryClient.getQueryData([
        orgId,
        "deals",
        dealId,
        "table-aggregations",
        tableAggregationId,
      ]);
      if (!data) return "Aggregation";
      return (data as TableAggregationRead).name || "unamed";

    case MatchParam.userId:
      data = queryClient.getQueryData([
        orgId,
        "deals",
        dealId,
        "users",
        userId,
      ]);
      if (!data) return "User";
      return (data as DealUserRead).email;

    case MatchParam.widgetTemplateId:
      data = queryClient.getQueryData([
        orgId,
        "widget-templates",
        widgetTemplateId,
      ]);
      if (!data) return "Widget Template";
      return (data as WidgetTemplate).name || "Widget Template";

    case MatchParam.inviteId:
      data = queryClient.getQueryData([orgId, "invites", inviteId]);
      if (!data) return "Invite";
      return (data as OrgInviteRead).user_email || "Invite";

    case MatchParam.dashboardId:
      data = queryClient.getQueryData([
        orgId,
        "deals",
        dealId,
        "dashboards",
        dashboardId,
      ]);
      if (!data) return "Dashboard";
      return (data as Dashboard).name || "Dashboard";

    default:
      return match;
  }
};
