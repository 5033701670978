import { clsx, type ClassValue } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

const merge = extendTailwindMerge({
  extend: {
    classGroups: {
      "font-size": [
        "text-h1",
        "text-h2",
        "text-h3",
        "text-h4",
        "text-h5",
        "text-h6",
        "text-hero",
        "text-heading",
        "text-subheading",
        "text-body",
        "text-label",
        "text-caption",
      ],
      "text-color": [
        "text",
        "text-inherit",
        "text-current",
        "text-transparent",
        "text-content",
        "text-content-1",
        "text-content-2",
        "text-content-3",
        "text-content-inverted",
        "text-content-primary",
        "text-content-success",
        "text-content-warning",
        "text-content-danger",
        "text-brand-text",
        "text-brand-mark",

        // components
        "text-component-button-primary-text",
        "text-component-button-danger-text",
      ],
      "bg-color": [
        "bg",
        "bg-inherit",
        "bg-current",
        "bg-transparent",
        "bg-surface",
        "bg-surface-1",
        "bg-surface-2",
        "bg-surface-3",
        "bg-surface-inverted",
        "bg-surface-primary",
        "bg-surface-primary-subtle",
        "bg-surface-primary-accent",
        "bg-surface-success",
        "bg-surface-success-accent",
        "bg-surface-warning",
        "bg-surface-warning-accent",
        "bg-surface-danger",
        "bg-surface-danger-accent",

        // components
        "bg-component-button-primary-bg",
        "bg-component-button-primary-edge",
        "bg-component-button-danger-bg",
        "bg-component-button-danger-edge",
      ],
    },
    conflictingClassGroups: {
      "font-size": ["font-size"],
      "text-color": ["text-color"],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return merge(clsx(inputs));
}
