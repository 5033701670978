import { useEffect, useRef, useState } from "react";

import { useCallback } from "react";
import { debounce } from "lodash";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";

export const useFileViewer = ({
  pageNumber,
  waiting,
}: {
  pageNumber: number;
  waiting: boolean;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const documentRef = useRef<HTMLDivElement>(null);
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const initialPageLoadedRef = useRef(false);
  const [initialised, setInitialised] = useState(false);
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [pdfState, setPdfState] = useState({
    isDocumentLoaded: false,
    totalPages: 0,
    currentPage: 1,
    pageScale: 1,
    pageDimensions: null as { width: number; height: number } | null,
  });

  const onDocumentLoad = async (pdf: PDFDocumentProxy) => {
    initialPageLoadedRef.current = false;
    setInitialised(false);
    const page = await pdf.getPage(1);
    const { width, height } = page.getViewport({ scale: 1 });

    setPdfState((prev) => ({
      ...prev,
      currentPage: pageNumber,
      pageDimensions: { width, height },
      totalPages: pdf.numPages,
      isDocumentLoaded: true,
    }));

    requestAnimationFrame(() => {
      setTimeout(() => {
        goToPage(pageNumber);
        setInitialised(true);
      }, 500);
      setTimeout(() => {
        initialPageLoadedRef.current = true;
      }, 1000);
    });
  };

  useEffect(() => {
    if (waiting) {
      goToPage(pageNumber);
    }
  }, [pageNumber]);

  const currentPageRef = useRef(pdfState.currentPage);
  useEffect(() => {
    currentPageRef.current = pdfState.currentPage;
  }, [pdfState.currentPage]);

  const maintainScrollPosition = (
    container: HTMLDivElement,
    currentPageEl: HTMLDivElement,
    callback: () => void,
  ) => {
    const pageRect = currentPageEl.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    const relativeOffset = (pageRect.top - containerRect.top) / pageRect.height;

    callback();

    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    scrollTimeoutRef.current = setTimeout(() => {
      requestAnimationFrame(() => {
        if (!container || !currentPageEl || !container.contains(currentPageEl))
          return;

        const newPageRect = currentPageEl.getBoundingClientRect();
        const newScrollTop =
          currentPageEl.offsetTop - relativeOffset * newPageRect.height;

        container.scrollTop = newScrollTop;

        scrollTimeoutRef.current = null;
      });
    }, 0);
  };

  const updateScale = useCallback(
    debounce(() => {
      if (
        documentRef.current &&
        pdfState.pageDimensions &&
        containerRef.current
      ) {
        const container = containerRef.current;
        const containerWidth = documentRef.current.clientWidth;
        const margin = 24;
        const newScale =
          (containerWidth - margin) / pdfState.pageDimensions.width;

        const currentPage = currentPageRef.current;
        const currentPageEl = pageRefs.current[currentPage - 1];

        if (currentPageEl) {
          maintainScrollPosition(container, currentPageEl, () => {
            setPdfState((prev) => ({ ...prev, pageScale: newScale }));
          });
        } else {
          setPdfState((prev) => ({ ...prev, pageScale: newScale }));
        }
      }
    }, 200),
    [pdfState.pageDimensions, documentRef.current, containerRef.current],
  );

  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver(updateScale);
    resizeObserver.observe(containerRef.current);
    updateScale();

    return () => {
      if (initialised) {
        resizeObserver.disconnect();
      }
    };
  }, [containerRef.current, documentRef.current, initialised]);

  const goToPage = (pageNumber: number) => {
    const pageRef = pageRefs.current[pageNumber - 1];
    if (!pageRef) return;

    setPdfState((prev) => ({ ...prev, currentPage: pageNumber }));
    updateScale.cancel();

    if (containerRef.current) {
      const container = containerRef.current;
      const containerRect = container.getBoundingClientRect();
      const pageRect = pageRef.getBoundingClientRect();
      const scrollOffset =
        pageRect.top - containerRect.top + container.scrollTop - 12;
      container.scrollTop = scrollOffset;
    }

    if (containerRef.current) {
      containerRef.current.scrollTop = pageRef.offsetTop - 12;
    }
  };

  const onPageInView = useCallback((pageNumber: number) => {
    if (initialPageLoadedRef.current) {
      setPdfState((prev) => ({ ...prev, currentPage: pageNumber }));
    }
  }, []);

  const handleZoom = (delta: number) => {
    if (containerRef.current) {
      const container = containerRef.current;
      const currentPageEl = pageRefs.current[pdfState.currentPage - 1];
      if (currentPageEl) {
        maintainScrollPosition(container, currentPageEl, () => {
          setPdfState((prev) => ({
            ...prev,
            pageScale: prev.pageScale + delta,
          }));
        });
      } else {
        setPdfState((prev) => ({ ...prev, pageScale: prev.pageScale + delta }));
      }
    } else {
      setPdfState((prev) => ({ ...prev, pageScale: prev.pageScale + delta }));
    }
  };

  return {
    pdfState,
    onDocumentLoad,
    goToPage,
    onPageInView,
    handleZoom,
    containerRef,
    documentRef,
    pageRefs,
    initialised,
    updateScale,
  };
};
