import type React from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "../../../lib/utils";

export const textStyles = cva("", {
  variants: {
    type: {
      hero: "text-hero",
      heading: "text-heading",
      subheading: "text-subheading",
      body: "text-body",
      label: "text-label",
      caption: "text-caption",
    },
    truncated: {
      true: "whitespace-nowrap overflow-hidden text-ellipsis",
    },
    weight: {
      default: "font-default",
      medium: "font-medium",
      bold: "font-bold",
    },
    color: {
      default: "text-content",
      subtle: "text-content-1",
      faint: "text-content-2",
      primary: "text-content-primary",
      danger: "text-content-danger",
      inverted: "text-content-inverted",
    },
    style: {
      default: "",
      underline: "underline",
    },
  },
  defaultVariants: {
    type: "body",
    color: "default",
    style: "default",
  },
});

export interface TextProps extends VariantProps<typeof textStyles> {
  children: React.ReactNode;
  className?: string;
}

export const Text = ({
  children,
  type,
  truncated,
  weight,
  color,
  style,
  className = "",
}: TextProps) => {
  return (
    <span
      className={cn(
        textStyles({ type, truncated, weight, color, style }),
        className,
      )}
    >
      {children}
    </span>
  );
};
