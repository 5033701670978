import {
  Button,
  cn,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "capsa-ui";
import { useCallback, useEffect, useMemo } from "react";
import { useGlobalStore } from "@/store";
import { useDealQuery } from "@/hooks/deals";
import { useValidatedParams } from "@/utils/router";
import { useWebsitesQuery } from "@/hooks/websites";
import { Topic } from "@/types/widgets";
import { DashboardCTAs } from "../components/dashboard/DashboardCTAs";
import { WidgetContent } from "../components/widget/WidgetContent";
import { getCompanyLogoDomain } from "@/utils/image";
import { DashboardExport } from "../components/dashboard/DashboardExport";
import { useDashboardStore } from "../store/useDashboardStore";
import { useDomainLogo } from "../api/getDomainLogo";
import { useWidgets } from "../api/getWidgets";
import { useRefreshWidgetGenerationJob } from "../api/refreshWidgetGenerationJob";
import { useWidgetGenerationJobs } from "../api/getWidgetGenerationJobs";
import { removeCitations } from "../utils/bullets";
import { Main } from "@/components/layout/Main";
import { useAssistantStore } from "@/features/assistant/store/useAssistantStore";
import { createDashboardContext } from "@/features/assistant/utils/context";
import { usePostHog } from "posthog-js/react";
import { useDashboard } from "../api/getDashboard";
import { useDashboards } from "../api/getDashboards";
import { useNavigate } from "react-router-dom";
import { paths } from "@/config/paths";

export const DashboardView = () => {
  const { dealId, orgId, dashboardId } = useValidatedParams([
    "dealId",
    "orgId",
    "dashboardId",
  ]);
  const navigate = useNavigate();
  const dashboards = useDashboards({ orgId, dealId });
  const { setSelectedDashboards } = useGlobalStore();
  const dashboardQuery = useDashboard({ orgId, dealId, dashboardId });
  const dealQuery = useDealQuery(orgId, dealId);
  const { setSelectedCompany, selectedCompany } = useGlobalStore();
  const { setExportDialogOpen } = useDashboardStore();
  const websites = useWebsitesQuery(orgId, selectedCompany?.id || "");
  const widgetIds = dashboardQuery.data?.widgets.map((w) => w.id) || [];
  const widgetsQuery = useWidgets({ orgId, dealId, widgetIds });
  const refreshWidgetJob = useRefreshWidgetGenerationJob({ orgId, dealId });
  const refreshingWidgetJobs = useWidgetGenerationJobs({
    orgId,
    dealId,
    status: ["pending", "in_progress"],
  });
  const domainLogoQuery = useDomainLogo({
    domain: getCompanyLogoDomain(websites.data) || "",
  });
  const assistant = useAssistantStore();
  const posthog = usePostHog();

  const handleDashboardChange = (value: string) => {
    setSelectedDashboards(orgId, dealId, value);
    navigate(
      paths.orgs.selected.deals.selected.dashboards.selected.getHref(
        orgId,
        dealId,
        value,
      ),
    );
  };

  useEffect(() => {
    dashboardQuery.data?.widgets?.forEach((widget) => {
      if (widget.status === "pending") {
        handleRefresh(widget.id);
      }
    });
  }, [dashboardQuery.data]);

  const refreshingIds = useMemo(() => {
    return (
      refreshingWidgetJobs.data
        ?.map((job) => job.widgetId)
        .filter((id) => widgetIds.includes(id)) || []
    );
  }, [refreshingWidgetJobs.data, widgetIds]);

  useEffect(() => {
    if (dealQuery.data) {
      setSelectedCompany(dealQuery.data);
    }
  }, [dealQuery.data]);

  const noData = useMemo(() => {
    if (!dashboardQuery.data) return true;
    if (dashboardQuery.data.widgets.length === 0) return true;
    return widgetsQuery.every((w) => !w.data || w.data.empty);
  }, [dashboardQuery.data, widgetsQuery]);

  const handleRefresh = (id: string) => {
    refreshWidgetJob.mutate({ widgetId: id });
  };

  const triggerRefresh = useCallback(() => {
    widgetIds
      .filter((id) => !refreshingIds.includes(id))
      .forEach((id) => {
        handleRefresh(id);
      });
  }, [widgetIds, refreshWidgetJob, refreshingIds]);

  const oneLinerContent = useMemo(() => {
    const widgets = dashboardQuery.data?.widgets || [];
    const oneLinerWidget = widgets.find((w) => w.name === "company_one_liner");
    if (!oneLinerWidget || widgetsQuery[0].data?.contentType !== "markdown") {
      return undefined;
    }
    const content = widgetsQuery[0].data.content as Topic[];
    return removeCitations(content[0]?.entry?.options[0]?.content || "");
  }, [dashboardQuery.data, widgetsQuery]);

  const mainWidgets = useMemo(
    () =>
      widgetsQuery
        .filter((w) => w.data?.name !== "company_one_liner")
        .map((w) => w.data)
        .filter((w) => !!w) || [],
    [widgetsQuery],
  );

  const focusedWidget = useMemo(() => {
    return widgetsQuery.find(
      (w) =>
        assistant.context?.type === "widget" &&
        assistant.context.widget_id === w.data?.id,
    );
  }, [widgetsQuery, assistant.context]);

  useEffect(() => {
    const widget = document.querySelector(`[data-widget-focused="true"]`);
    if (widget) {
      setTimeout(() => {
        widget.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 0);
    }
  }, [focusedWidget]);

  useEffect(() => {
    if (dashboardQuery.data) {
      assistant.setContext(
        createDashboardContext(
          orgId,
          dealId,
          dashboardQuery.data.id,
          "Dashboard",
        ),
      );
    }
  }, [dashboardQuery.data]);

  return (
    <>
      <DashboardExport
        companyName={dealQuery.data?.company_name || ""}
        companyDescription={oneLinerContent || ""}
        widgets={mainWidgets}
        companyLogo={domainLogoQuery.data?.url}
      />
      <Main
        title={dealQuery.data?.company_name || ""}
        description={oneLinerContent || ""}
        refreshing={refreshingIds.length > 0}
        loading={dashboardQuery.isLoading}
        actions={[
          posthog.isFeatureEnabled("dashboard_selector") && (
            <Select
              key="dashboard"
              value={dashboardId}
              onValueChange={handleDashboardChange}
            >
              <SelectTrigger
                className="w-fit"
                flat
                loading={dashboards.isLoading}
              >
                {!dashboards.isLoading && (
                  <SelectValue placeholder="Select a dashboard" />
                )}
              </SelectTrigger>
              <SelectContent>
                {dashboards.data?.map((d) => (
                  <SelectItem key={d.id} value={d.id}>
                    {d.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          ),
          <Button
            key="refresh"
            variant="outline"
            iconLeft="refresh"
            loading={refreshingIds.length >= widgetIds.length}
            onClick={triggerRefresh}
            flat
          >
            Refresh
          </Button>,
          !noData && (
            <Button
              key="export"
              iconLeft="export"
              variant="outline"
              onClick={() => setExportDialogOpen(true)}
              flat
            >
              Export
            </Button>
          ),
        ]}
      >
        <DashboardCTAs dashboardId={dashboardQuery.data?.id} />
        <div className="@container contain-layout">
          <div
            id="dashboard-grid"
            className={cn("grid grid-cols-1 @6xl:grid-cols-2 gap-3")}
          >
            {widgetsQuery?.map(
              (query, index) =>
                dashboardQuery.data?.widgets[index].name !==
                  "company_one_liner" && (
                  <WidgetContent
                    key={widgetIds[index]}
                    query={query}
                    onRefresh={() => handleRefresh(widgetIds[index])}
                    refreshing={refreshingIds.includes(widgetIds[index])}
                  />
                ),
            )}
          </div>
        </div>
      </Main>
    </>
  );
};
