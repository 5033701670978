import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";
import { useAuth } from "@/hooks/shared";
import { assertUserIsAuthenticated } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AssistantContext } from "../types/state";

export const sendMessage = async (
  user: User,
  orgId: string,
  threadId: string,
  message: string,
  context: AssistantContext[],
) => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/users/current/assistant-threads/${threadId}/commands/send-message`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify({ message, context }),
      },
    ),
    true,
  );
};

type useSendMessageOptions = {
  orgId: string;
  threadId?: string;
};

export const useSendMessage = ({ orgId, threadId }: useSendMessageOptions) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      message,
      context,
    }: {
      message: string;
      context: AssistantContext[];
    }) => {
      if (!threadId) {
        throw new Error("Cannot send message: threadId is undefined");
      }

      return sendMessage(user, orgId, threadId, message, context);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [orgId, "assistant-threads", threadId],
      });
    },
  });
};
