import {
  Button,
  TooltipContent,
  Text,
  TooltipTrigger,
  Tooltip,
  toast,
} from "capsa-ui";
import { SourceNode } from "../types/api";
import { useState } from "react";
import { motion } from "framer-motion";

type Props = {
  sources?: Record<string, SourceNode>;
  onSourceClick: (source: SourceNode) => void;
  sourceList: string[];
  message: string;
};

export const ChatToolbar = ({
  sources,
  onSourceClick,
  sourceList,
  message,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onCopy = () => {
    const withoutCitations = message.replace(/【[a-zA-Z0-9]{5}】/g, "");
    navigator.clipboard.writeText(withoutCitations);
    toast("Copied to clipboard.");
  };

  return (
    <div className="flex-1 flex flex-col gap-1.5 -mt-1">
      <div className="flex items-center -ml-1.5">
        {sourceList.length > 0 && (
          <Button
            iconRight={isExpanded ? "chevronUp" : "chevronDown"}
            size="sm"
            variant="ghost"
            flat
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            Sources
          </Button>
        )}
        <Tooltip>
          <TooltipTrigger>
            <Button
              iconLeft="copy"
              size="sm"
              variant="ghost"
              flat
              onClick={onCopy}
            />
          </TooltipTrigger>
          <TooltipContent>Copy</TooltipContent>
        </Tooltip>
      </div>
      <motion.div
        className="flex flex-col gap-0.5 overflow-hidden"
        initial={{ height: 0 }}
        animate={{ height: isExpanded ? "auto" : 0 }}
        transition={{ duration: 0.3 }}
      >
        {sourceList.map((id) => {
          const source = sources?.[id];
          let name = "source";

          if (!source) {
            return null;
          }

          switch (source.source_info.type) {
            case "file":
            case "file_table":
              name = source.source_info.name;
              break;

            case "webpage":
              name = source.source_info.url;
              break;

            case "external_webpage":
              name = source.source_info.url;
              break;

            default:
              break;
          }

          return (
            <a
              key={id}
              className="cursor-pointer hover:brightness-90 no-underline"
              onClick={() => onSourceClick(source)}
            >
              <Text className="text-label text-content-primary">
                {name}
                {source.source_info.type === "file" &&
                  ` (Page ${source.source_info.page_number})`}
              </Text>
            </a>
          );
        })}
      </motion.div>
    </div>
  );
};
