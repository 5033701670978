import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";
import { useAuth } from "@/hooks/shared";
import { assertUserIsAuthenticated } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Thread, ThreadConfig } from "../types/api";

export const configureThread = async (
  user: User,
  orgId: string,
  threadId: string,
  threadConfig: ThreadConfig,
) => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/users/current/assistant-threads/${threadId}/commands/configure-thread`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify({ config: threadConfig }),
      },
    ),
    true,
  );
};

type useConfigureThreadOptions = {
  orgId: string;
  threadId?: string;
};

export const useConfigureThread = ({
  orgId,
  threadId,
}: useConfigureThreadOptions) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  const queryClient = useQueryClient();

  const queryKey = [orgId, "assistant-threads", threadId];

  return useMutation({
    mutationFn: (threadConfig: ThreadConfig) => {
      if (!threadId) {
        throw new Error("Cannot configure thread: threadId is undefined");
      }
      return configureThread(user, orgId, threadId, threadConfig);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey,
      });
    },
    onMutate: async (newConfig) => {
      await queryClient.cancelQueries({
        queryKey,
      });
      const previous = queryClient.getQueryData<Thread>(queryKey);
      queryClient.setQueryData<Thread>(queryKey, (old) => {
        if (!old) return old;
        return {
          ...old,
          state: {
            ...old.state,
            config: newConfig,
          },
        };
      });
      return { previous };
    },
    // eslint-disable-next-line
    onError: (_err, _newTodo, context: any) => {
      queryClient.setQueryData(queryKey, context.previous);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey,
      });
    },
  });
};
