import { useMemo, useRef } from "react";
import { ChatMessage } from "./ChatMessage";
import { useValidatedParams } from "@/utils/router";
import { useThreadStream } from "../api/getThread";
import { ChatThinking } from "./ChatThinking";
import { ChatFailed } from "./ChatFailed";
import { useAssistantStore } from "../store/useAssistantStore";
import { SourceNode, ThreadMessage } from "../types/api";
import { useChatPosition } from "../hooks/useChatPosition";
import { useGlobalStore } from "@/store";

type Props = {
  onSourceClick: (source: SourceNode) => void;
};

export const ChatMessages = ({ onSourceClick }: Props) => {
  const { orgId } = useValidatedParams(["dealId", "orgId"]);
  const { superAdminReviewModeEnabled } = useGlobalStore();
  const { threadId, threadStatus } = useAssistantStore();

  const { threadState, currentToolCall } = useThreadStream({
    orgId,
    threadId,
    superAdminReviewModeEnabled,
  });

  const messages = useMemo(() => {
    if (!threadId) return [];
    const state = threadState;
    const data = state?.messages;
    const order = state?.message_order;

    if (!data || !order) return [];

    return order.reduce<ThreadMessage[]>((acc, id) => {
      const message = data[id];
      if (
        message &&
        message.content_parts &&
        message.content_parts.length > 0 &&
        message.role !== "tool" // currently only show user or assistant messages
      ) {
        acc.push(message);
      }
      return acc;
    }, []);
  }, [threadState, threadId]);

  const scrollableRef = useRef<HTMLDivElement>(null);
  const { scrollPadding } = useChatPosition({
    messages,
    scrollableRef,
  });

  const thinking = useMemo(() => {
    if (!messages.length) return false;
    const lastMessage = messages[messages.length - 1];
    return lastMessage?.role !== "assistant" || currentToolCall;
  }, [messages, currentToolCall]);

  return (
    <div
      ref={scrollableRef}
      className="flex flex-col flex-1 overflow-y-auto min-h-0"
    >
      <div className="flex flex-col p-1 gap-3">
        {messages.map((message, index) => {
          return (
            <div
              key={message.id}
              style={{
                minHeight:
                  index === messages.length - 1 && message.role === "assistant"
                    ? scrollPadding
                    : undefined,
              }}
            >
              <ChatMessage
                message={message}
                onSourceClick={onSourceClick}
                sources={threadState?.source_nodes}
                lastMessage={
                  messages.findLastIndex((m) => m.role === "user") ===
                  messages.indexOf(message)
                }
                lastReply={
                  index === messages.length - 1 && message.role === "assistant"
                }
              />
            </div>
          );
        })}
        {thinking && (
          <div
            style={{
              minHeight: scrollPadding,
            }}
          >
            <ChatThinking toolCall={currentToolCall} />
          </div>
        )}
        {threadStatus === "failed" && (
          <div style={{ minHeight: scrollPadding }}>
            <ChatFailed />
          </div>
        )}
      </div>
    </div>
  );
};
