import * as React from "react";
import { cn } from "../../../../lib/utils";
import { useSidebar } from "./useSidebar";

interface SidebarProps extends React.ComponentProps<"div"> {
  side?: "left" | "right";
  transitionDuration?: string;
}

export const Sidebar = React.forwardRef<HTMLDivElement, SidebarProps>(
  (
    { className, children, side = "left", transitionDuration, ...props },
    ref,
  ) => {
    const { state } = useSidebar();

    return (
      <div
        ref={ref}
        className="group peer block text-content-1"
        data-state={state}
        data-collapsible={state === "collapsed" ? "icon" : ""}
      >
        <div
          className={cn(
            "duration-200 relative h-svh w-(--sidebar-width) bg-transparent transition-[width] ease-in-out",
            state === "collapsed" && "w-(--sidebar-width-icon)",
            transitionDuration,
          )}
        />
        <div
          className={cn(
            "duration-200 fixed inset-y-0 z-10 h-svh w-(--sidebar-width) transition-[left,right,width] ease-in-out",
            side === "left" ? "left-0 border-r" : "right-0 border-l",
            state === "collapsed" && "w-(--sidebar-width-icon)",
            transitionDuration,
            className,
          )}
          {...props}
        >
          <div
            data-sidebar="sidebar"
            className="flex h-full w-full flex-col bg-surface-1"
          >
            {children}
          </div>
        </div>
      </div>
    );
  },
);
Sidebar.displayName = "Sidebar";
