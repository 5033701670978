import { TreeNode } from "capsa-ui";
import _ from "lodash";

export const buildTree = <T extends TreeNode>(items: T[]): T[] => {
  const grouped = _.groupBy(items, (item) => item.parentId || "root");

  const buildTree = (parentId: string | null): T[] => {
    const children = grouped[parentId || "root"] || [];
    return children.map((item) => ({
      ...item,
      children: buildTree(item.id),
    }));
  };

  return buildTree(null);
};

export const getAllDescendantIds = (
  flatList: TreeNode[],
  parentId: string,
): string[] => {
  const descendants: string[] = [];

  const collectDescendants = (id: string) => {
    flatList.forEach((item) => {
      if (item.parentId === id) {
        descendants.push(item.id);
        collectDescendants(item.id);
      }
    });
  };

  collectDescendants(parentId);
  return descendants;
};

export const downloadFileUrl = async (fileUrl: string, fileName: string) => {
  if (fileUrl) {
    try {
      const response = await fetch(fileUrl, {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Cache-Control": "no-cache",
        },
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch: ${response.status} ${response.statusText}`,
        );
      }

      const fileBlob = await response.blob();
      const newBlob = new Blob([fileBlob], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = blobUrl;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      }, 300);
    } catch (error) {
      console.error("Download failed:", error);
    }
  }
};
