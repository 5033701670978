import { Link, useMatches, useNavigate } from "react-router-dom";
import { configNav, featuresNav, UserNavigationItem } from "./common";
import {
  Avatar,
  Button,
  CapsaLogo,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarTrigger,
} from "capsa-ui";
import { useEffect, useMemo, useState } from "react";
import { useDealsQuery } from "@/hooks/deals";
import { usePostHog } from "posthog-js/react";
import { useAuth0 } from "@auth0/auth0-react";
import { show, hide, onHide, onShow } from "@intercom/messenger-js-sdk";
import { useGlobalStore } from "@/store";
import { paths } from "@/config/paths";
import { useValidatedParams } from "@/utils/router";
import { DealSelector } from "../ui/DealSelector";
import { UploadsManager } from "../ui/UploadsManager";
import { useAssistantStore } from "@/features/assistant/store/useAssistantStore";
import { useCurrentDashboard } from "@/features/dashboards/api/getCurrentDashboard";

interface DashboardLayoutProps {
  userNavigation: UserNavigationItem[];
}

export default function Navigation({ userNavigation }: DashboardLayoutProps) {
  const { orgId } = useValidatedParams(["orgId"]);
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { user } = useAuth0();
  const [messengerVisible, setMessengerVisible] = useState(false);
  const deals = useDealsQuery(orgId, { staleTime: Infinity });
  const { selectedCompany, resetSelectedCompany } = useGlobalStore();
  const matches = useMatches();
  const assistant = useAssistantStore();
  const dashboard = useCurrentDashboard({
    orgId,
    dealId: selectedCompany?.id || "",
  });

  const isActive = (path: string): boolean => {
    return matches.some((match) => match.pathname === path);
  };

  useEffect(() => {
    if (deals.data?.length === 0) {
      resetSelectedCompany();
    }
  }, [deals.data]);

  const enabledFeatures = useMemo(() => {
    const filtered = featuresNav(
      orgId,
      selectedCompany?.id,
      dashboard?.id,
    ).filter((item) => {
      if (item.feature) {
        return !!posthog.isFeatureEnabled(item.feature);
      }
      return true;
    });

    return filtered.filter(
      (item, index) =>
        !(item.type === "label" && filtered[index + 1]?.type === "label"),
    );
  }, [orgId, selectedCompany?.id, dashboard?.id]);

  const enabledConfig = useMemo(
    () =>
      configNav(orgId).filter((item) => {
        if (item.feature) {
          return !!posthog.isFeatureEnabled(item.feature);
        }
        return true;
      }),
    [orgId],
  );

  useEffect(() => {
    onShow(() => {
      setMessengerVisible(true);
    });
    onHide(() => {
      setMessengerVisible(false);
    });
  }, []);

  const toggleIntercomChat = () => {
    if (messengerVisible) {
      hide();
    } else {
      show();
    }
  };

  return (
    <Sidebar>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem className="flex items-center overflow-hidden pr-1">
            <Link
              className="flex flex-1 items-center px-2 gap-2 cursor-pointer"
              to={paths.orgs.selected.getHref(orgId)}
            >
              <CapsaLogo
                height={20}
                variant="icon"
                className="ml-[3px] shrink-0"
              />
              <CapsaLogo height={20} variant="text" className="shrink-0" />
            </Link>
            <div className="mix-blend-multiply">
              <SidebarTrigger className="text-content-2" />
            </div>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  onClick={() =>
                    navigate(paths.orgs.selected.deals.getHref(orgId))
                  }
                  isActive={
                    paths.orgs.selected.deals.getHref(orgId) ===
                    window.location.pathname
                  }
                  tooltip="Deals"
                >
                  <Icon type="company" />
                  <span>Deals</span>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        {deals.data && deals.data?.length > 0 && (
          <SidebarGroup>
            <SidebarGroupContent>
              <SidebarMenu>
                <SidebarMenuItem>
                  <DealSelector />
                </SidebarMenuItem>
              </SidebarMenu>
            </SidebarGroupContent>
            {selectedCompany?.id && (
              <SidebarGroupContent>
                <SidebarMenu>
                  {enabledFeatures.map((item) => {
                    if (item.type === "nav") {
                      return (
                        <SidebarMenuItem key={item.name}>
                          <SidebarMenuButton
                            onClick={() => {
                              switch (item.href) {
                                case "toggleAssistant":
                                  assistant.toggleAssistant();
                                  break;

                                default:
                                  navigate(item.href);
                                  break;
                              }
                            }}
                            isActive={
                              item.href === "toggleAssistant"
                                ? assistant.isOpen
                                : isActive(item.href)
                            }
                            tooltip={item.name}
                          >
                            <Icon type={item.icon} />
                            <span>{item.name}</span>
                          </SidebarMenuButton>
                        </SidebarMenuItem>
                      );
                    } else {
                      return (
                        <SidebarGroupLabel key={item.name}>
                          {item.name}
                        </SidebarGroupLabel>
                      );
                    }
                  })}
                </SidebarMenu>
              </SidebarGroupContent>
            )}
          </SidebarGroup>
        )}
      </SidebarContent>
      <SidebarFooter>
        <UploadsManager />
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              {enabledConfig.map((item) => (
                <SidebarMenuItem key={item.name}>
                  <SidebarMenuButton
                    onClick={() => {
                      switch (item.href) {
                        case "toggleIntercomChat":
                          toggleIntercomChat();
                          break;

                        default:
                          navigate(item.href);
                          break;
                      }
                    }}
                    isActive={
                      item.href === "toggleIntercomChat"
                        ? messengerVisible
                        : isActive(item.href)
                    }
                    tooltip={item.name}
                  >
                    <Icon type={item.icon} />
                    <span>{item.name}</span>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <Popover>
                  <PopoverTrigger asChild>
                    <SidebarMenuButton tooltip="Account">
                      <Avatar initials={user?.name?.slice(0, 1)} />
                      <span>{user?.email}</span>
                    </SidebarMenuButton>
                  </PopoverTrigger>
                  <PopoverContent
                    side="top"
                    align="start"
                    className="p-2 w-[var(--radix-popover-trigger-width)] min-w-fit"
                  >
                    {userNavigation.map((item, index) => (
                      <Button
                        key={index}
                        onClick={item.callback}
                        className="w-full justify-start"
                        variant="ghost"
                        flat
                      >
                        <span>{item.name}</span>
                      </Button>
                    ))}
                  </PopoverContent>
                </Popover>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarFooter>
    </Sidebar>
  );
}
