import { paths } from "@/config/paths";
import { IconType } from "capsa-ui";
import { posthog } from "posthog-js";
import { useGlobalStore } from "@/store";

interface NavItem {
  type: "nav";
  name: string;
  href: string;
  icon: IconType;
  feature?: string;
}

interface LabelItem {
  type: "label";
  name: string;
  feature?: string;
}

export const featuresNav = (
  orgId: string,
  companyId?: string,
  dashboardId?: string,
): (NavItem | LabelItem)[] => [
  {
    type: "nav",
    name: "Company Dashboard",
    href: dashboardId
      ? paths.orgs.selected.deals.selected.dashboards.selected.getHref(
          orgId,
          companyId,
          dashboardId,
        )
      : paths.orgs.selected.deals.selected.dashboards.current.getHref(
          orgId,
          companyId,
        ),
    icon: "dashboard",
    feature: "dashboard",
  },
  {
    type: "label",
    name: "DATA SOURCES",
  },
  {
    type: "nav",
    name: "Files",
    href: paths.orgs.selected.deals.selected.files.getHref(orgId, companyId),
    icon: "folderClosed",
  },
  {
    type: "nav",
    name: "Websites",
    href: paths.orgs.selected.deals.selected.websites.getHref(orgId, companyId),
    icon: "website",
    feature: "websites",
  },
  {
    type: "nav",
    name: "Registry Search",
    href: paths.orgs.selected.deals.selected.registrySearchJobs.getHref(
      orgId,
      companyId,
    ),
    icon: "registrySearch",
    feature: "legal_entity_search",
  },
  {
    type: "label",
    name: "TOOLS",
  },
  {
    type: "nav",
    name: "Table Aggregations",
    href: paths.orgs.selected.deals.selected.tableAggregations.getHref(
      orgId,
      companyId,
    ),
    icon: "table",
    feature: "table_aggregations",
  },
  {
    type: "label",
    name: "MANAGE",
  },
  {
    type: "nav",
    name: "Deal Access",
    href: paths.orgs.selected.deals.selected.users.getHref(orgId, companyId),
    icon: "lock",
  },
  {
    type: "label",
    name: "INTERNAL",
  },
  {
    type: "nav",
    name: "Search",
    href: paths.orgs.selected.deals.selected.search.getHref(orgId, companyId),
    icon: "search",
    feature: "search",
  },
];

export const configNav = (orgId: string): NavItem[] => [
  {
    type: "nav",
    name: "Dashboard Templates",
    href: paths.orgs.selected.dashboardTemplates.getHref(orgId),
    icon: "dashboard",
    feature: "dashboard_templates_config",
  },
  {
    type: "nav",
    name: "Widgets Catalog",
    href: paths.orgs.selected.widgetTemplates.getHref(orgId),
    icon: "dashboard",
    feature: "dashboard_templates_config",
  },
  {
    type: "nav",
    name: "Settings",
    href: paths.orgs.selected.settings.getHref(orgId),
    icon: "settings",
    feature: "settings",
  },
  {
    type: "nav",
    name: "Support",
    href: "toggleIntercomChat",
    icon: "chat",
  },
  {
    type: "nav",
    name: "Users",
    href: paths.orgs.selected.users.getHref(orgId),
    icon: "user",
  },
];

export interface UserNavigationItem {
  name: string;
  callback: () => void;
  icon: IconType;
  current: boolean;
}

export function getUserNavigation(logout: () => void): UserNavigationItem[] {
  const { superAdminReviewModeEnabled, setSuperAdminReviewModeEnabled } =
    useGlobalStore();
  const navigation: UserNavigationItem[] = [
    {
      name: "Logout",
      callback: logout,
      icon: "cancel",
      current: false,
    },
  ];
  if (posthog.isFeatureEnabled("super_admin_review_mode")) {
    navigation.push({
      name: `${superAdminReviewModeEnabled ? "Disable" : "Enable"} Review Mode`,
      callback: superAdminReviewModeEnabled
        ? () => setSuperAdminReviewModeEnabled(false)
        : () => setSuperAdminReviewModeEnabled(true),
      icon: "chat",
      current: false,
    });
  }
  return navigation;
}
