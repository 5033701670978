import { useAuth0 } from "@auth0/auth0-react";
import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import Analytics from "./Analytics";
import { paths } from "@/config/paths";
import { GlobalLoader } from "./GlobalLoader";

interface Props {
  children: ReactElement;
}

export default function AuthenticatedRoute({ children }: Props) {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <GlobalLoader />;
  }

  if (!isAuthenticated) {
    return <Navigate to={paths.auth.login.getHref()} />;
  }

  if (!user?.email_verified) {
    return <Navigate to={paths.auth.verify.getHref()} />;
  }

  return <Analytics>{children}</Analytics>;
}
