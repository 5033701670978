import { DealRead } from "@/api/deals";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { combine } from "zustand/middleware";
import { subscribeWithSelector } from "zustand/middleware";

const initialState = {
  selectedCompany: undefined as DealRead | undefined,
  dashboardNavOpen: true,
  superAdminReviewModeEnabled: false,
  selectedDashboards: {} as Record<string, string>,
};

export const useGlobalStore = create(
  devtools(
    persist(
      subscribeWithSelector(
        combine(initialState, (set) => ({
          setSelectedCompany: (selectedCompany: DealRead) =>
            set({ selectedCompany }),
          resetSelectedCompany: () => set({ selectedCompany: undefined }),
          openDashboardNav: () => set({ dashboardNavOpen: true }),
          closeDashboardNav: () => set({ dashboardNavOpen: false }),
          setDashboardNavOpen: (open: boolean) =>
            set({ dashboardNavOpen: open }),
          setSuperAdminReviewModeEnabled: (enabled: boolean) =>
            set({ superAdminReviewModeEnabled: enabled }),
          setSelectedDashboards: (
            orgId: string,
            dealId: string,
            dashboardId: string,
          ) =>
            set((state) => ({
              selectedDashboards: {
                ...state.selectedDashboards,
                [`${orgId},${dealId}`]: dashboardId,
              },
            })),
        })),
      ),
      {
        name: "GlobalStore",
      },
    ),
  ),
);
