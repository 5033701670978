import { cn } from "capsa-ui";
import {
  MDXEditor,
  listsPlugin,
  headingsPlugin,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  linkPlugin,
  MDXEditorMethods,
  MDXEditorProps,
  imagePlugin,
  linkDialogPlugin,
  frontmatterPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { ForwardedRef, useEffect, useRef, useState } from "react";

export interface MarkdownEditorProps extends MDXEditorProps {
  editorRef?: ForwardedRef<MDXEditorMethods> | null;
  setMarkdown?: (markdown: string) => void;
  className?: string;
}

export const MarkdownEditor = ({
  editorRef,
  markdown,
  className,
  ...props
}: MarkdownEditorProps) => {
  const elementRef = useRef<MDXEditorMethods>(null);
  const ref = (editorRef || elementRef) as React.RefObject<MDXEditorMethods>;

  const [value, setValue] = useState<string>(markdown || "");

  useEffect(() => {
    const editor = ref?.current;
    if (editor) {
      editor.setMarkdown(markdown || "");
      setValue(markdown || "");
    }
  }, [markdown]);

  return (
    <MDXEditor
      ref={ref}
      plugins={[
        listsPlugin(),
        headingsPlugin({}),
        quotePlugin(),
        thematicBreakPlugin(),
        markdownShortcutPlugin(),
        tablePlugin(),
        linkPlugin(),
        linkDialogPlugin(),
        imagePlugin(),
        frontmatterPlugin(),
      ]}
      contentEditableClassName={cn(
        // editor
        "text-content font-sans text-body max-w-full !p-0 flex flex-col",

        // headings
        "[&_h1]:text-h1 [&_h1]:font-bold",
        "[&_h2]:text-h2 [&_h2]:font-bold",
        "[&_h3]:text-h3 [&_h3]:font-bold",
        "[&_h4]:text-h4 [&_h4]:font-bold",
        "[&_h5]:text-h5 [&_h5]:font-bold",
        "[&_h6]:text-h6 [&_h6]:font-bold",

        // text
        "[&_strong]:font-medium",
        "[&_small]:text-label",
        "[&_small]:text-content-1 [&_small]:font-medium",

        // hr
        "[&_hr]:border-t [&_hr]:!my-1.5",

        // lists
        "[&_ul]:pl-4 [&_ul]:list-disc marker:text-content-2",
        "[&_ol]:pl-4 [&_ol]:list-decimal marker:text-content-2",
        "[&_li]:mb-2 [&_li]:!mt-0 [&_li]:pl-0",

        // tables
        "[&_table]:!table-auto [&_table]:mb-6",
        "[&_[data-tool-cell]]:hidden [&_tfoot]:hidden [&_thead]:hidden",
        "[&_td]:!p-3 [&_td]:!px-1.5 [&_td]:!border-0 [&_td]:!border-t",
        "[&_th]:!p-3 [&_th]:!px-1.5 [&_th]:!border-0 [&_th]:!text-label [&_th]:text-content-1",
        `[&_[data-active="true"]]:!outline-blue-600`,

        // blockquote
        "[&_blockquote]:!pl-3 [&_blockquote]:!border-l-2 [&_blockquote]:!border-l-subdued [&_blockquote]:!text-body [&_blockquote]:!text-content-1 [&_blockquote]:!font-medium [&_blockquote]:py-2",

        className,
      )}
      markdown={value}
      {...props}
    />
  );
};
