import { Page } from "react-pdf";
import { cn, Spinner } from "capsa-ui";
import { useInView } from "react-intersection-observer";
import { forwardRef, useEffect, useState } from "react";

export const PDFPage = forwardRef<
  HTMLDivElement,
  {
    containerRef: React.RefObject<HTMLDivElement>;
    pageIndex: number;
    scale: number;
    pageDimensions: { width: number; height: number } | null;
    onPageInView: (pageNumber: number) => void;
    initialised: boolean;
  }
>(
  (
    {
      containerRef,
      pageIndex,
      scale,
      pageDimensions,
      onPageInView,
      initialised,
    },
    ref,
  ) => {
    const [aspectRatio, setAspectRatio] = useState<string | null>(null);

    // For lazy loading
    const { ref: lazyRef, inView: isNearView } = useInView({
      threshold: 0,
      rootMargin: "500px",
      triggerOnce: false,
      root: containerRef.current,
    });

    // For precise viewport tracking
    const { ref: viewportRef, inView: isInView } = useInView({
      threshold: 0,
      triggerOnce: false,
      root: containerRef.current,
    });

    const combinedRef = (element: HTMLDivElement | null) => {
      lazyRef(element);
      viewportRef(element);
      if (typeof ref === "function") {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }
    };

    useEffect(() => {
      if (isInView) {
        onPageInView(pageIndex + 1);
      }
    }, [isInView, pageIndex, onPageInView]);

    const scaledWidth = pageDimensions ? pageDimensions.width * scale : 0;
    const scaledHeight = aspectRatio
      ? scaledWidth /
        (Number(aspectRatio.split("/")[0]) / Number(aspectRatio.split("/")[1]))
      : pageDimensions
        ? pageDimensions.height * scale
        : 0;

    return (
      <div
        key={`page_${pageIndex + 1}`}
        ref={combinedRef}
        className={cn(
          "flex-center rounded-layout overflow-hidden border bg-surface relative",
          "will-change-transform",
          "transition-none",
        )}
        style={{
          width: scaledWidth,
          height: scaledHeight,
          minHeight: scaledHeight,
          maxHeight: scaledHeight,
          aspectRatio: pageDimensions
            ? `${pageDimensions.width}/${pageDimensions.height}`
            : undefined,
        }}
      >
        {!initialised && (
          <div className="absolute inset-0 bg-surface z-[1] flex-center">
            <Spinner />
          </div>
        )}
        {isNearView ? (
          <Page
            pageNumber={pageIndex + 1}
            renderAnnotationLayer={true}
            renderTextLayer={true}
            loading={() => <Spinner />}
            className="bg-surface-1"
            width={scaledWidth}
            height={scaledHeight}
            onLoadSuccess={(page) => {
              const viewport = page.getViewport({ scale: 1 });
              setAspectRatio(`${viewport.width}/${viewport.height}`);
            }}
          />
        ) : (
          <Spinner />
        )}
      </div>
    );
  },
);

PDFPage.displayName = "PDFPage";
