import { Avatar, CommandGroup, CommandItem, Combobox, Icon } from "capsa-ui";
import { paths } from "@/config/paths";
import { useDealsQuery } from "@/hooks/deals";
import { useWebsitesQuery } from "@/hooks/websites";
import { useGlobalStore } from "@/store";
import { getCompanyLogoFromWebsites } from "@/utils/image";
import { useValidatedParams } from "@/utils/router";
import { SidebarGroupLabel } from "capsa-ui";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const DealSelector = () => {
  const { orgId } = useValidatedParams(["orgId"]);
  const { dealId } = useParams();
  const { setSelectedCompany, selectedCompany } = useGlobalStore();
  const navigate = useNavigate();
  const { data, refetch } = useDealsQuery(orgId, { staleTime: Infinity });
  const websites = useWebsitesQuery(orgId, selectedCompany?.id || "");

  const dealList = useMemo(
    () =>
      data?.map((deal) => ({
        label: deal.name,
        value: deal.id,
      })) || [],
    [data],
  );

  const selectedId = useMemo(
    () => selectedCompany?.id || "",
    [selectedCompany],
  );

  const handleSelect = (value: string) => {
    if (value) {
      const company = data?.find((deal) => deal.id === value);
      if (company) {
        setSelectedCompany(company);
        if (dealId) {
          if (location.pathname.endsWith("dashboards/current")) {
            navigate(
              paths.orgs.selected.deals.selected.dashboards.current.getHref(
                orgId,
                company.id,
              ),
            );
            return;
          }
          if (location.pathname.match(/dashboards\/[^/]+$/)) {
            navigate(
              paths.orgs.selected.deals.selected.dashboards.current.getHref(
                orgId,
                company.id,
              ),
            );
            return;
          }

          const currentPath = location.pathname.split(dealId);
          // Get route after dealId and only return feature path
          const featureRoute = currentPath[1].split("/").filter(Boolean)[0];
          navigate(
            `${paths.orgs.selected.deals.selected.getHref(orgId, value)}/${featureRoute}`,
          );
        } else {
          navigate(
            `${paths.orgs.selected.deals.selected.getHref(orgId, company.id)}`,
          );
        }
      }
    }
  };

  const logoUrl = useMemo(
    () => getCompanyLogoFromWebsites(websites.data || []),
    [websites.data],
  );

  if (!data) {
    return null;
  }

  const addNewCompany = () => {
    navigate(paths.orgs.selected.deals.create.getHref(orgId));
  };

  const onCompaniesOpen = () => {
    refetch();
  };

  return (
    <>
      <SidebarGroupLabel className="-mt-1">DEAL</SidebarGroupLabel>
      <Combobox
        options={dealList}
        selected={selectedId}
        onSelect={handleSelect}
        placeholder="Select a deal"
        width="100%"
        searchPlaceholder="Search deals..."
        avatar={
          selectedCompany ? (
            <Avatar initials={selectedCompany.name[0]} src={logoUrl} />
          ) : undefined
        }
        onOpen={onCompaniesOpen}
        className="bg-surface-1"
      >
        <>
          <CommandGroup forceMount className="border-t">
            <CommandItem key="add new deal" onSelect={addNewCompany}>
              <Icon type="add" />
              <span>Add new deal</span>
            </CommandItem>
          </CommandGroup>
        </>
      </Combobox>
      <div className="h-2" />
    </>
  );
};
