import { useEffect, useState, useRef } from "react";
import { Props, FileViewerParams } from "./types";
import { Unselected } from "./PlaceholderStates";
import { SelectedFile } from "./SelectedFile";
import { SelectedWebpage } from "./SelectedWebpage";

/**
 * FileViewer component that displays either a PDF file or a webpage screenshot
 */
export default function FileViewer({
  orgId,
  dealId,
  fileId,
  pageNumber,
  websiteId,
  webpageId,
  waiting,
  setFileViewerParams,
  loadDelay,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  // Keep track of the last parameters we've seen to prevent unnecessary updates
  const lastParamsRef = useRef<{
    fileId: string | null;
    pageNumber: number | null;
  }>({ fileId: null, pageNumber: null });

  // Only set waiting to true if params have actually changed
  useEffect(() => {
    // Only update if navigating to a file with a specific page and parameters have changed
    if (
      fileId !== null &&
      pageNumber !== null &&
      (fileId !== lastParamsRef.current.fileId ||
        pageNumber !== lastParamsRef.current.pageNumber)
    ) {
      // Update our ref to track these parameters
      lastParamsRef.current = { fileId, pageNumber };

      // Set waiting to true to trigger navigation in child component
      setFileViewerParams({
        fileId,
        pageNumber,
        websiteId: null,
        webpageId: null,
        waiting: true,
      });
    }
  }, [fileId, pageNumber, setFileViewerParams]);

  useEffect(() => {
    if (loadDelay) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, loadDelay);
    }
  }, [loadDelay]);

  if (isLoading) {
    return null;
  }

  // Related to container size
  if (fileId === null && websiteId === null) {
    return <Unselected />;
  }

  if (fileId !== null) {
    return (
      <SelectedFile
        orgId={orgId}
        dealId={dealId}
        fileId={fileId}
        pageNumber={pageNumber}
        waiting={waiting}
        setFileViewerParams={setFileViewerParams}
      />
    );
  }

  if (websiteId !== null && webpageId !== null) {
    return (
      <SelectedWebpage
        orgId={orgId}
        dealId={dealId}
        websiteId={websiteId}
        webpageId={webpageId}
        waiting={waiting}
        setFileViewerParams={setFileViewerParams}
      />
    );
  }

  throw new Error("Invalid FileViewerParams.");
}

export type { FileViewerParams };
