import React, { forwardRef, useMemo } from "react";
import { Icon } from "../../icons";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { cn } from "../../../lib/utils";
import { cva, VariantProps } from "class-variance-authority";

const checkboxVariants = cva("", {
  variants: {
    size: {
      sm: "w-4 h-4",
      default: "w-5 h-5",
    },
  },
  defaultVariants: {
    size: "default",
  },
});

const checkboxContainerVariants = cva("", {
  variants: {
    size: {
      sm: "gap-1.5",
      default: "gap-2",
    },
  },
  defaultVariants: {
    size: "default",
  },
});

export type CheckboxSize = VariantProps<typeof checkboxVariants>["size"];

export interface CheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
    VariantProps<typeof checkboxVariants> {
  checked?: boolean;
  label?: string;
  disabled?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  truncate?: boolean;
  checkboxClassName?: string;
  labelClassName?: string;
}

export const Checkbox = forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(
  (
    {
      className,
      size,
      label,
      truncate,
      checkboxClassName,
      labelClassName,
      disabled,
      ...props
    },
    ref,
  ) => {
    const labelSize = useMemo(() => {
      switch (size) {
        case "sm":
          return "text-label";
        case "default":
          return "text-body";
        default:
          return "text-body";
      }
    }, [size]);

    return (
      <label
        className={cn(
          "flex clickable min-w-0",
          checkboxContainerVariants({ size }),
          disabled && "disabled",
          className,
        )}
      >
        <CheckboxPrimitive.Root
          ref={ref}
          className={cn(
            "focus-outer peer shrink-0 transition flex cursor-pointer justify-center items-center bg-surface text-content-inverted rounded border",
            "data-[state=checked]:bg-surface-primary-accent data-[state=checked]:border-edge-primary",
            checkboxVariants({
              size,
            }),
            disabled && "bg-content-3 border-transparent",
            checkboxClassName,
          )}
          id={props.id || label}
          disabled={disabled}
          {...props}
        >
          <CheckboxPrimitive.Indicator
            className={cn("flex items-center justify-center text-current")}
          >
            <Icon type="check" className="!w-full !h-full" />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {label && (
          <span
            className={cn(
              labelSize,
              labelClassName,
              truncate ? "truncate" : "",
            )}
          >
            {label}
          </span>
        )}
      </label>
    );
  },
);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;
