import { downloadFileUrl } from "@/utils/files";
import {
  Button,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "capsa-ui";
import { useEffect, useState } from "react";

interface Props {
  currentPage: number;
  totalPages: number;
  scale: number;
  fileUrl: string;
  fileName: string;
  onPageInputSubmit: (pageNumber: number) => void;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onFitToView: () => void;
}

export function FileViewerToolbar({
  currentPage,
  totalPages,
  scale,
  fileUrl,
  fileName,
  onPageInputSubmit,
  onZoomIn,
  onZoomOut,
  onFitToView,
}: Props) {
  const [pageInput, setPageInput] = useState(currentPage);

  useEffect(() => {
    setPageInput(currentPage);
  }, [currentPage]);

  const handlePageInputSubmit = (
    e?: React.FormEvent<HTMLFormElement> | React.FocusEvent<HTMLInputElement>,
  ) => {
    e?.preventDefault();
    onPageInputSubmit(pageInput);
  };

  const onPreviousPage = () => {
    onPageInputSubmit(currentPage - 1);
  };

  const onNextPage = () => {
    onPageInputSubmit(currentPage + 1);
  };

  return (
    <div className="flex items-center px-1 py-2 border-b">
      <div className="flex gap-1 flex-1 shrink-0">
        <Tooltip delayDuration={250}>
          <TooltipTrigger asChild>
            <Button
              onClick={onPreviousPage}
              variant="ghost"
              flat
              iconLeft="chevronUp"
              size="sm"
              disabled={currentPage <= 1}
            />
          </TooltipTrigger>
          <TooltipContent side="top" sideOffset={5}>
            Previous Page
          </TooltipContent>
        </Tooltip>
        <form
          onSubmit={handlePageInputSubmit}
          className="flex items-center space-x-1"
        >
          <input
            type="number"
            value={pageInput}
            onChange={(e) => setPageInput(parseInt(e.target.value))}
            className="w-12 px-2 py-0.5 text-content-1 text-label border border-edge rounded bg-transparent [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            onBlur={handlePageInputSubmit}
          />
          <Text type="label" color="subtle" className="whitespace-nowrap">
            / {totalPages}
          </Text>
        </form>
        <Tooltip delayDuration={250}>
          <TooltipTrigger asChild>
            <Button
              onClick={onNextPage}
              variant="ghost"
              flat
              iconLeft="chevronDown"
              size="sm"
              disabled={currentPage >= totalPages}
            />
          </TooltipTrigger>
          <TooltipContent side="top" sideOffset={5}>
            Next Page
          </TooltipContent>
        </Tooltip>
      </div>
      <div className="flex-center gap-1 flex-1">
        <Tooltip delayDuration={250}>
          <TooltipTrigger asChild>
            <Button
              onClick={onZoomOut}
              variant="ghost"
              flat
              size="sm"
              disabled={scale <= 0.5}
              iconLeft="zoomOut"
            />
          </TooltipTrigger>
          <TooltipContent side="top" sideOffset={5}>
            Zoom Out
          </TooltipContent>
        </Tooltip>
        <Text type="label" color="subtle">
          {Math.round(scale * 100)}%
        </Text>
        <Tooltip delayDuration={250}>
          <TooltipTrigger asChild>
            <Button
              onClick={onZoomIn}
              variant="ghost"
              flat
              size="sm"
              disabled={scale >= 3}
              iconLeft="zoomIn"
            />
          </TooltipTrigger>
          <TooltipContent side="top" sideOffset={5}>
            Zoom In
          </TooltipContent>
        </Tooltip>
        <Tooltip delayDuration={250}>
          <TooltipTrigger asChild>
            <Button
              onClick={onFitToView}
              variant="ghost"
              flat
              size="sm"
              iconLeft="scale"
            />
          </TooltipTrigger>
          <TooltipContent side="top" sideOffset={5}>
            Fit to view
          </TooltipContent>
        </Tooltip>
      </div>
      <div className="flex-1 flex items-center justify-end">
        <Tooltip delayDuration={250}>
          <TooltipTrigger asChild>
            <Button
              onClick={() => downloadFileUrl(fileUrl, fileName)}
              variant="ghost"
              flat
              size="sm"
              iconLeft="download"
            />
          </TooltipTrigger>
          <TooltipContent side="top" sideOffset={5}>
            Download File
          </TooltipContent>
        </Tooltip>
      </div>
    </div>
  );
}
