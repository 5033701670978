import { useNavigate } from "react-router-dom";
import { useUserDealPermissionsQuery } from "../../../hooks/userDealRoles";
import { hasPermission } from "../../../utils";
import Spinner from "../../Spinner";
import { paths } from "@/config/paths";

interface Props {
  orgId: string;
  dealId: string;
  tableAggregationId: string;
}

export default function UpdateTableAggregationButton(props: Props) {
  const permissionsQuery = useUserDealPermissionsQuery(
    props.orgId,
    props.dealId,
  );
  const navigate = useNavigate();

  switch (hasPermission(permissionsQuery, "table_aggregations.update")) {
    case "loading":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          <Spinner w="4" h="4" />
        </button>
      );
    case "true":
      return (
        <>
          <button
            type="button"
            onClick={() =>
              navigate(
                paths.orgs.selected.deals.selected.tableAggregations.selected.update.getHref(
                  props.orgId,
                  props.dealId,
                  props.tableAggregationId,
                ),
              )
            }
            className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Update
          </button>
        </>
      );
    case "false":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
          title="Insufficient permissions, contact org admin."
        >
          Update
        </button>
      );
    default:
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          Update
        </button>
      );
  }
}
