import * as React from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import { cn } from "../../../lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import { useMemo } from "react";

const switchVariants = cva("", {
  variants: {
    size: {
      sm: "h-4 w-7",
      default: "h-5 w-9",
    },
    withLabel: {
      true: "",
    },
  },
  compoundVariants: [
    {
      size: "sm",
      withLabel: true,
      className: "mt-px",
    },
  ],
  defaultVariants: {
    size: "default",
  },
});

const switchContainerVariants = cva("", {
  variants: {
    size: {
      sm: "gap-1.5",
      default: "gap-2",
    },
  },
  defaultVariants: {
    size: "default",
  },
});

export const thumbVariants = cva("", {
  variants: {
    size: {
      sm: "h-3 w-3 data-[state=checked]:translate-x-3",
      default: "h-4 w-4 data-[state=checked]:translate-x-4",
    },
  },
  defaultVariants: {
    size: "default",
  },
});

export type SwitchSize = VariantProps<typeof switchVariants>["size"];

export interface SwitchProps
  extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>,
    VariantProps<typeof switchVariants> {
  checked?: boolean;
  label?: string;
  disabled?: boolean;
  labelClassName?: string;
  truncate?: boolean;
}

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(
  (
    { className, size, disabled, label, labelClassName, truncate, ...props },
    ref,
  ) => {
    const labelSize = useMemo(() => {
      switch (size) {
        case "sm":
          return "text-label";
        case "default":
          return "text-body";
        default:
          return "text-body";
      }
    }, [size]);
    return (
      <label
        className={cn(
          "flex clickable min-w-0",
          switchContainerVariants({ size }),
          disabled && "disabled",
          className,
        )}
      >
        <SwitchPrimitives.Root
          className={cn(
            "peer focus-outer inline-flex cursor-pointer shrink-0 items-center rounded-pill border-2 border-transparent transition-colors",
            "data-[state=checked]:bg-content-primary data-[state=unchecked]:bg-content-3",
            switchVariants({ size, withLabel: !!label }),
          )}
          disabled={disabled}
          name={props.name || label}
          id={props.id || label}
          {...props}
          ref={ref}
        >
          <SwitchPrimitives.Thumb
            className={cn(
              "pointer-events-none block rounded-full bg-content-inverted transition-transform data-[state=unchecked]:translate-x-0",
              thumbVariants({ size }),
            )}
          />
        </SwitchPrimitives.Root>
        {label && (
          <span
            className={cn(
              labelSize,
              labelClassName,
              truncate ? "truncate" : "",
            )}
          >
            {label}
          </span>
        )}
      </label>
    );
  },
);
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
