import React, { useEffect, useState } from "react";
import {
  Button,
  EditableHeading,
  TextField,
  Text,
  Icon,
  Label,
} from "capsa-ui";
import {
  defaultDealName,
  useCreateCompanyStore,
} from "@/features/companies/store/useCreateCompanyStore";
import { useNavigate } from "react-router-dom";

import { useCreateDealMutation } from "@/hooks/deals";
import UploadFilesPixel from "@/components/DealsTab/subcomponents/UploadFilesPixel";
import { sendEvent } from "@/lib/posthog";
import { useSharepointStore } from "@/features/sharepoint/store/useSharepointStore";
import { useInitSharepoint } from "@/features/sharepoint/hooks/config";
import { useCreateSyncJobMutation } from "@/features/sharepoint/hooks/syncJobs";
import { usePostHog } from "posthog-js/react";
import { paths } from "@/config/paths";
import { useValidatedParams } from "@/utils/router";
import { Main } from "@/components/layout/Main";
import FileUploadComponent from "@/components/DealsTab/subcomponents/UploadDocuments";

export const CreateCompany = () => {
  const { orgId } = useValidatedParams(["orgId"]);
  const navigate = useNavigate();
  const {
    reset,
    dealName,
    setDealName,
    companyName,
    files,
    setDealId,
    dealId,
    setCompanyName,
    setFiles,
  } = useCreateCompanyStore();
  const sharepointStore = useSharepointStore();
  useInitSharepoint();
  const sharepointMutation = useCreateSyncJobMutation();

  const mutation = useCreateDealMutation(orgId as string);
  const [filesUploadCompleted, setFilesUploadCompleted] = useState(false);
  const [creatingDeal, setCreatingDeal] = useState(false);
  const posthog = usePostHog();

  const handleCreateDeal = () => {
    setCreatingDeal(true);
    sendEvent("clicked_create_deal", {
      feature: "create_company",
    });
    mutation.mutate(
      {
        name: dealName === defaultDealName ? companyName : dealName,
        companyName: companyName,
        companyAliases:
          dealName === defaultDealName
            ? [companyName]
            : [dealName, companyName],
        website: null,
        companyIdInRegistry: "",
        companyLegalName: "",
        registryFiles: [],
        countryCode: "",
      },
      {
        onSuccess: (data) => {
          setDealId(data.id);
        },
        onSettled: () => {
          setCreatingDeal(false);
        },
      },
    );
  };

  const handleCancel = () => {
    navigate(paths.orgs.selected.deals.getHref(orgId));
  };

  useEffect(() => {
    return () => {
      sharepointStore.reset();
      reset();
    };
  }, []);

  useEffect(() => {
    if (dealId) {
      const { sharepointEnabled, selectedFolderIds, selectedFileIds } =
        sharepointStore;
      if (sharepointEnabled) {
        sharepointMutation.mutate({
          externalFolderIds: selectedFolderIds,
          externalFileIds: selectedFileIds,
          externalDealId: dealId,
        });
      }
      if (files.length === 0 || filesUploadCompleted) {
        reset();
        if (posthog.isFeatureEnabled("dashboard")) {
          navigate(
            paths.orgs.selected.deals.selected.dashboards.current.getHref(
              orgId,
              dealId,
            ),
          );
        } else {
          navigate(
            paths.orgs.selected.deals.selected.files.getHref(orgId, dealId),
          );
        }
      }
    }
  }, [dealId, filesUploadCompleted]);

  return (
    <Main
      title={<EditableHeading value={dealName} onChange={setDealName} />}
      actions={[
        <Button onClick={handleCancel} variant="outline" key="cancel">
          Cancel
        </Button>,
        <Button
          onClick={handleCreateDeal}
          disabled={!companyName}
          loading={creatingDeal}
          key="create"
        >
          Create Deal
        </Button>,
      ]}
    >
      <form
        className="flex flex-col gap-4 flex-1 min-h-0"
        onSubmit={(e) => {
          e.preventDefault();
          handleCreateDeal();
        }}
      >
        {mutation.isError && (
          <div className="p-2 rounded bg-surface-danger flex items-center gap-1.5">
            <Icon
              type="alertFilled"
              size="sm"
              className="text-content-danger"
            />
            <Text type="label">Error creating deal. Try again.</Text>
          </div>
        )}
        <TextField
          name="Company Name"
          label="Company Name"
          required
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          autoFocus
        />
        <div className="flex flex-col gap-2 flex-1 min-h-0">
          <Label>Add Files</Label>
          <FileUploadComponent onFileUpload={setFiles} />
        </div>
      </form>
      {dealId && (
        <UploadFilesPixel
          orgId={orgId || ""}
          dealId={dealId}
          files={files}
          onUploadComplete={() => setFilesUploadCompleted(true)}
        />
      )}
    </Main>
  );
};
