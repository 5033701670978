import { useDealsQuery } from "@/hooks/deals";
import { Main } from "../layout/Main";
import CreateDealButton from "./CreateDealsButton";
import { DealsList } from "./DealsList";
import { useValidatedParams } from "@/utils/router";

export default function DealsTab() {
  const { orgId } = useValidatedParams(["orgId"]);
  const { data } = useDealsQuery(orgId);

  return (
    <Main
      title="Deals"
      description="Locate folders with all the required information and tools you need for your deal analysis."
      actions={
        data && data.length
          ? [<CreateDealButton orgId={orgId} key={orgId} />]
          : []
      }
    >
      <DealsList orgId={orgId} />
    </Main>
  );
}
