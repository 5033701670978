import { useWebpageQuery } from "../../hooks/webpages";
import { SelectedWebpageProps } from "./types";

/**
 * Component for displaying a selected webpage screenshot
 */
export function SelectedWebpage({
  orgId,
  dealId,
  websiteId,
  webpageId,
}: SelectedWebpageProps) {
  const webpageQuery = useWebpageQuery(orgId, dealId, websiteId, webpageId);

  if (webpageQuery.isLoading) {
    return <p>Loading...</p>;
  }

  if (webpageQuery.isError) {
    return <p>Error loading pages.</p>;
  }

  let url = webpageQuery.data.screenshot_download_url;
  if (process.env.NODE_ENV === "development") {
    url = webpageQuery.data.screenshot_download_url.replace(
      "http://storage",
      "http://localhost",
    );
  }

  return (
    <div className="h-full w-full overflow-auto">
      <img src={url} alt="Webpage screenshot" />
    </div>
  );
}
