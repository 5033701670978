import React, { useState } from "react";
import { WidgetTemplate } from "../types";
import { useWidgetTemplatesQuery } from "../hooks/useWidgetTemplates";

interface WidgetSelectionPopupProps {
  orgId: string;
  existingWidgetIds: string[];
  onCancel: () => void;
  onAdd: (selectedWidgets: WidgetTemplate[]) => void;
}

export const WidgetSelectionPopup: React.FC<WidgetSelectionPopupProps> = ({
  orgId,
  existingWidgetIds,
  onCancel,
  onAdd,
}) => {
  const { data: widgetTemplates, isLoading } = useWidgetTemplatesQuery(orgId);
  const [selectedWidgetIds, setSelectedWidgetIds] = useState<Set<string>>(
    new Set(),
  );

  const availableWidgets =
    widgetTemplates?.pages
      .flatMap((page) => page?.data || [])
      .filter((widget) => !existingWidgetIds.includes(widget.id)) || [];

  const handleWidgetToggle = (widgetId: string) => {
    const newSelected = new Set(selectedWidgetIds);
    if (newSelected.has(widgetId)) {
      newSelected.delete(widgetId);
    } else {
      newSelected.add(widgetId);
    }
    setSelectedWidgetIds(newSelected);
  };

  const handleAdd = () => {
    const selectedWidgets = availableWidgets.filter((widget) =>
      selectedWidgetIds.has(widget.id),
    );
    onAdd(selectedWidgets);
  };

  const handleClickOutside = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onCancel();
    }
  };

  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg">Loading...</div>
      </div>
    );
  }

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={handleClickOutside}
    >
      <div className="bg-white p-6 rounded-lg shadow-lg w-[600px] max-h-[80vh] flex flex-col">
        <h2 className="text-xl font-bold mb-4">Add Widgets</h2>
        <div className="flex-grow overflow-y-auto mb-4">
          {availableWidgets.length === 0 ? (
            <p className="text-gray-500 italic">No widgets available to add</p>
          ) : (
            <div className="space-y-2">
              {availableWidgets.map((widget) => (
                <div
                  key={widget.id}
                  className="flex items-center p-2 hover:bg-gray-100 rounded cursor-pointer"
                  onClick={() => handleWidgetToggle(widget.id)}
                >
                  <input
                    type="checkbox"
                    checked={selectedWidgetIds.has(widget.id)}
                    onChange={() => handleWidgetToggle(widget.id)}
                    className="form-checkbox h-5 w-5 fill-current mr-3 rounded"
                  />
                  <div>
                    <div className="font-medium">{widget.prettyName}</div>
                    <div className="text-sm text-gray-500">
                      {widget.contentType
                        .split("_")
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() + word.slice(1),
                        )
                        .join(" ")}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex justify-end space-x-3">
          <button
            onClick={onCancel}
            className="px-4 py-2 border border-gray-300 rounded hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={handleAdd}
            disabled={selectedWidgetIds.size === 0}
            className={`px-4 py-2 rounded text-white ${
              selectedWidgetIds.size === 0
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600"
            }`}
          >
            Add Selected
          </button>
        </div>
      </div>
    </div>
  );
};
