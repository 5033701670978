import { Navigate, RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import AuthenticatedRoute from "@/components/AuthenticatedRoute";
import OrgDashboardLayout from "@/components/layout";
import { companyRoutes } from "./companyRoutes";
import { legalEntitySearchRoutes } from "./legalEntitySearchRoutes";
import { userRoutes } from "./userRoutes";
import { dashboardTemplatesRoutes } from "./dashboardTemplatesRoutes";
import { settingsRoutes } from "./settingsRoutes";
import { widgetTemplatesRoutes } from "./widgetTemplatesRoutes";
import { inviteRoutes } from "./inviteRoutes";

export const orgRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.path,
    element: (
      <AuthenticatedRoute>
        <OrgDashboardLayout />
      </AuthenticatedRoute>
    ),
    children: [
      ...companyRoutes(),
      ...legalEntitySearchRoutes(),
      ...dashboardTemplatesRoutes(),
      ...widgetTemplatesRoutes(),
      ...userRoutes(),
      ...inviteRoutes(),
      ...settingsRoutes(),
      {
        path: "",
        element: <Navigate to="deals" />,
      },
    ],
  },
];
