import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";
import { Dashboard } from "../types/api";
import { defaultRetryFunc } from "@/hooks/shared";
import { useAuth } from "@/hooks/shared";
import { assertUserIsAuthenticated } from "@/utils";
import { useQuery } from "@tanstack/react-query";
import { QueryConfig } from "@/lib/reactQuery";

export const getDashboards = async (
  user: User,
  orgId: string,
  dealId: string,
): Promise<Dashboard[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/dashboards`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};

type useDashboardsOptions = {
  orgId: string;
  dealId: string;
  config?: QueryConfig<typeof getDashboards>;
};

export const useDashboards = ({
  orgId,
  dealId,
  config,
}: useDashboardsOptions) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "dashboards"],
    queryFn: () => getDashboards(user, orgId, dealId),
    enabled: !!user && !!dealId,
    retry: defaultRetryFunc,
    ...config,
  });
};
