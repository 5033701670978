import { useEffect, useRef, useState } from "react";
import {
  File,
  TableAggregationGroup,
} from "../../../../../api/tableAggregationLayouts";
import {
  ColumnDragItemData,
  ColumnDropZoneData,
  DragItemData,
  DragItemType,
  DropZoneData,
  DropZoneType,
  GroupDragItemData,
  GroupDropZoneData,
  RowDropZoneData,
  TableAggregationGroupWithSelection,
  TableDragItemData,
} from "./types";

import {
  classNames,
  removeEmptyColumnsAndGroups,
  useDropZone,
  useScale,
} from "./utils";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import TableAggregationGroupRender from "./TableAggregationGroupRender";
import {
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
} from "@heroicons/react/24/outline";
import { useWizard } from "react-use-wizard";
import { CheckBox } from "../../../../base/CheckBox";
import { useCreateTableAggregationMutation } from "../../../../../hooks/tableAggregations";
import { useNavigate } from "react-router-dom";
import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import invariant from "tiny-invariant";
import { paths } from "@/config/paths";

function GroupDropZone({ groupIndex }: { groupIndex: number }) {
  const { ref, isDraggedOver } = useDropZone({
    type: DropZoneType.GROUP,
    groupIndex,
  });
  return (
    <div className="flex">
      <div className="w-full h-4 flex items-center" ref={ref}>
        {isDraggedOver && (
          <div className="w-full border border-t-2 border-black" />
        )}
      </div>
    </div>
  );
}

function dropTableItemInRowZone(
  tableItem: TableDragItemData,
  rowZone: RowDropZoneData,
  setLayout: React.Dispatch<React.SetStateAction<TableAggregationGroup[]>>,
) {
  setLayout((prevLayout) => {
    let newLayout = JSON.parse(JSON.stringify(prevLayout));

    // Pop table
    const table = newLayout[tableItem.groupIndex].columns[
      tableItem.columnIndex
    ].tables.splice(tableItem.rowIndex, 1)[0];

    // Calculate row offset
    let rowOffset = 0;
    if (
      tableItem.groupIndex === rowZone.groupIndex &&
      tableItem.columnIndex === rowZone.columnIndex &&
      tableItem.rowIndex < rowZone.rowIndex
    ) {
      rowOffset = -1;
    }

    // Insert table
    newLayout[rowZone.groupIndex].columns[rowZone.columnIndex].tables.splice(
      rowZone.rowIndex + rowOffset,
      0,
      table,
    );

    // Remove empty columns and groups
    newLayout = removeEmptyColumnsAndGroups(newLayout);

    return newLayout;
  });
}

function dropTableItemInColumnZone(
  tableItem: TableDragItemData,
  columnZone: ColumnDropZoneData,
  setLayout: React.Dispatch<React.SetStateAction<TableAggregationGroup[]>>,
) {
  setLayout((prevLayout) => {
    let newLayout = JSON.parse(JSON.stringify(prevLayout));

    // Pop table
    const table = newLayout[tableItem.groupIndex].columns[
      tableItem.columnIndex
    ].tables.splice(tableItem.rowIndex, 1)[0];

    // Insert table
    newLayout[columnZone.groupIndex].columns.splice(columnZone.columnIndex, 0, {
      tables: [table],
    });

    // Remove empty columns and groups
    newLayout = removeEmptyColumnsAndGroups(newLayout);

    return newLayout;
  });
}

function dropTableItemInGroupZone(
  tableItem: TableDragItemData,
  groupZone: GroupDropZoneData,
  setLayout: React.Dispatch<React.SetStateAction<TableAggregationGroup[]>>,
) {
  setLayout((prevLayout) => {
    let newLayout = JSON.parse(JSON.stringify(prevLayout));

    // Pop table
    const table = newLayout[tableItem.groupIndex].columns[
      tableItem.columnIndex
    ].tables.splice(tableItem.rowIndex, 1)[0];

    // Insert table
    newLayout.splice(groupZone.groupIndex, 0, {
      columns: [{ tables: [table] }],
    });

    // Remove empty columns and groups
    newLayout = removeEmptyColumnsAndGroups(newLayout);

    return newLayout;
  });
}

function dropColumnItemInRowZone(
  columnItem: ColumnDragItemData,
  rowZone: RowDropZoneData,
  setLayout: React.Dispatch<React.SetStateAction<TableAggregationGroup[]>>,
) {
  setLayout((prevLayout) => {
    let newLayout = JSON.parse(JSON.stringify(prevLayout));

    // Pop column
    const column = newLayout[columnItem.groupIndex].columns.splice(
      columnItem.columnIndex,
      1,
    )[0];

    // Calculate column offset
    let columnOffset = 0;
    if (
      columnItem.groupIndex === rowZone.groupIndex &&
      columnItem.columnIndex < rowZone.columnIndex
    ) {
      columnOffset = -1;
    }

    // Move column into another column
    newLayout[rowZone.groupIndex].columns[
      rowZone.columnIndex + columnOffset
    ].tables.splice(rowZone.rowIndex, 0, ...column.tables);

    // Remove empty columns and groups
    newLayout = removeEmptyColumnsAndGroups(newLayout);
    return newLayout;
  });
}

function dropColumnItemInColumnZone(
  columnItem: ColumnDragItemData,
  columnZone: ColumnDropZoneData,
  setLayout: React.Dispatch<React.SetStateAction<TableAggregationGroup[]>>,
) {
  setLayout((prevLayout) => {
    let newLayout = JSON.parse(JSON.stringify(prevLayout));

    // Pop column
    const column = newLayout[columnItem.groupIndex].columns.splice(
      columnItem.columnIndex,
      1,
    )[0];

    // Calculate column offset
    let columnOffset = 0;
    if (
      columnItem.groupIndex === columnZone.groupIndex &&
      columnItem.columnIndex < columnZone.columnIndex
    ) {
      columnOffset = -1;
    }

    // Insert column
    newLayout[columnZone.groupIndex].columns.splice(
      columnZone.columnIndex + columnOffset,
      0,
      column,
    );

    // Remove empty columns and groups
    newLayout = removeEmptyColumnsAndGroups(newLayout);
    return newLayout;
  });
}

function dropColumnItemInGroupZone(
  columnItem: ColumnDragItemData,
  groupZone: GroupDropZoneData,
  setLayout: React.Dispatch<React.SetStateAction<TableAggregationGroup[]>>,
) {
  setLayout((prevLayout) => {
    let newLayout = JSON.parse(JSON.stringify(prevLayout));

    // Pop column
    const column = newLayout[columnItem.groupIndex].columns.splice(
      columnItem.columnIndex,
      1,
    )[0];

    // Insert column
    newLayout.splice(groupZone.groupIndex, 0, {
      columns: [column],
    });

    // Remove empty columns and groups
    newLayout = removeEmptyColumnsAndGroups(newLayout);
    return newLayout;
  });
}

function dropGroupItemInColumnZone(
  groupItem: GroupDragItemData,
  columnZone: ColumnDropZoneData,
  setLayout: React.Dispatch<React.SetStateAction<TableAggregationGroup[]>>,
) {
  setLayout((prevLayout) => {
    let newLayout = JSON.parse(JSON.stringify(prevLayout));

    // Pop group
    const group = newLayout.splice(groupItem.groupIndex, 1)[0];

    // Calculate group offset
    let groupOffset = 0;
    if (groupItem.groupIndex < columnZone.groupIndex) {
      groupOffset = -1;
    }

    // Insert group
    newLayout[columnZone.groupIndex + groupOffset].columns.splice(
      columnZone.columnIndex,
      0,
      ...group.columns,
    );

    // Remove empty columns and groups
    newLayout = removeEmptyColumnsAndGroups(newLayout);
    return newLayout;
  });
}

function dropGroupItemInGroupZone(
  groupItem: GroupDragItemData,
  groupZone: GroupDropZoneData,
  setLayout: React.Dispatch<React.SetStateAction<TableAggregationGroup[]>>,
) {
  setLayout((prevLayout) => {
    let newLayout = JSON.parse(JSON.stringify(prevLayout));

    // Pop group
    const group = newLayout.splice(groupItem.groupIndex, 1)[0];

    // Calculate group offset
    let groupOffset = 0;
    if (groupItem.groupIndex < groupZone.groupIndex) {
      groupOffset = -1;
    }

    // Insert group
    newLayout.splice(groupZone.groupIndex + groupOffset, 0, group);

    // Remove empty columns and groups
    newLayout = removeEmptyColumnsAndGroups(newLayout);
    return newLayout;
  });
}

function getCheckBoxState(layout: TableAggregationGroupWithSelection[]) {
  const numSelectedGroups = layout.filter((group) => group.isSelected).length;
  let checkBoxState: "unchecked" | "checked" | "indeterminate" = "unchecked";
  if (numSelectedGroups === layout.length) {
    checkBoxState = "checked";
  } else if (numSelectedGroups > 0) {
    checkBoxState = "indeterminate";
  }
  return checkBoxState;
}

function toggleAllGroups(
  setLayout: React.Dispatch<
    React.SetStateAction<TableAggregationGroupWithSelection[]>
  >,
) {
  setLayout((prevLayout) => {
    const numSelectedGroups = prevLayout.filter(
      (group) => group.isSelected,
    ).length;
    if (numSelectedGroups > prevLayout.length / 2) {
      return prevLayout.map((group) => ({ ...group, isSelected: false }));
    }
    const newLayout = prevLayout.map((group) => ({
      ...group,
      isSelected: true,
    }));
    return newLayout;
  });
}

export default function TableAggregationLayoutBuilder(props: {
  orgId: string;
  dealId: string;
  files: File[];
  layout: TableAggregationGroup[];
}) {
  const [layout, setLayout] = useState<TableAggregationGroupWithSelection[]>(
    props.layout,
  );
  const { scalePercentage, scaleStyle, incrementScale, decrementScale } =
    useScale();
  const { previousStep } = useWizard();
  const mutation = useCreateTableAggregationMutation(props.orgId, props.dealId);
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = ref.current;
    invariant(element, "Element ref not set");
    return autoScrollForElements({
      element,
    });
  });

  useEffect(() => {
    return monitorForElements({
      onDrop({ source, location }) {
        const destination = location.current.dropTargets[0];
        if (!destination) {
          // if dropped outside of any drop targets
          return;
        }
        const dragItem = source.data.dragItemData as DragItemData;
        const dropZone = destination.data.dropZoneData as DropZoneData;
        if (
          dragItem.type === DragItemType.TABLE &&
          dropZone.type === DropZoneType.ROW
        ) {
          dropTableItemInRowZone(dragItem, dropZone, setLayout);
        }
        if (
          dragItem.type === DragItemType.TABLE &&
          dropZone.type === DropZoneType.COLUMN
        ) {
          dropTableItemInColumnZone(dragItem, dropZone, setLayout);
        }
        if (
          dragItem.type === DragItemType.TABLE &&
          dropZone.type === DropZoneType.GROUP
        ) {
          dropTableItemInGroupZone(dragItem, dropZone, setLayout);
        }
        if (
          dragItem.type === DragItemType.COLUMN &&
          dropZone.type === DropZoneType.ROW
        ) {
          dropColumnItemInRowZone(dragItem, dropZone, setLayout);
        }
        if (
          dragItem.type === DragItemType.COLUMN &&
          dropZone.type === DropZoneType.COLUMN
        ) {
          dropColumnItemInColumnZone(dragItem, dropZone, setLayout);
        }
        if (
          dragItem.type === DragItemType.COLUMN &&
          dropZone.type === DropZoneType.GROUP
        ) {
          dropColumnItemInGroupZone(dragItem, dropZone, setLayout);
        }
        if (
          dragItem.type === DragItemType.GROUP &&
          dropZone.type === DropZoneType.COLUMN
        ) {
          dropGroupItemInColumnZone(dragItem, dropZone, setLayout);
        }
        if (
          dragItem.type === DragItemType.GROUP &&
          dropZone.type === DropZoneType.GROUP
        ) {
          dropGroupItemInGroupZone(dragItem, dropZone, setLayout);
        }
      },
    });
  }, [layout]);

  const handlePreviousStep = () => {
    previousStep();
  };

  const handleNextStep = () => {
    if (layout.filter((group) => group.isSelected).length === 0) {
      alert("Please select at least one group of tables to aggregate.");
      return;
    }
    mutation.mutate(
      {
        name: null,
        layout: layout.filter((group) => group.isSelected),
      },
      {
        onSuccess: (tableAggregation) => {
          navigate(
            paths.orgs.selected.deals.selected.tableAggregations.selected.getHref(
              props.orgId,
              props.dealId,
              tableAggregation.id,
            ),
          );
        },
        onError: () => {
          alert("Failed to create table aggregation");
        },
      },
    );
  };

  return (
    <div className="w-full">
      <div className="flex flex-row pb-2 items-center">
        <CheckBox
          state={getCheckBoxState(layout)}
          onClick={() => toggleAllGroups(setLayout)}
        />
        <h1 className="ml-2 text-sm font-semibold">Tables to aggregate</h1>
        <div className="flex flex-row ml-auto">
          <button
            type="button"
            onClick={() => {
              if (
                window.confirm("Are you sure you want to reset the layout?")
              ) {
                setLayout(props.layout);
              }
            }}
            className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Reset Layout
          </button>
        </div>
        <div className="flex flex-row space-x-2 group relative ml-4">
          <button onClick={() => decrementScale()}>
            <MagnifyingGlassMinusIcon className="h-5 w-5 text-gray-500" />
          </button>
          <p className="w-10 text-center text-sm">{scalePercentage}%</p>
          <button onClick={() => incrementScale()}>
            <MagnifyingGlassPlusIcon className="h-5 w-5 text-gray-500" />
          </button>
          <div className="hidden group-hover:block absolute top-7 right-0 z-10 bg-white border border-gray-300 shadow-md rounded-md w-64 p-4">
            <h2 className="text-sm font-semibold mb-2">💡 Did you know?</h2>
            <p className="text-sm mb-2">
              You can zoom in and out using your keyboard!
            </p>
            <ul className="text-sm list-disc list-inside">
              <li>Press 1 to zoom out.</li>
              <li>Press 2 to zoom in.</li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className="flex h-[calc(100vh-15rem)] overflow-y-scoll overflow-x-scroll p-2 rounded-md border border-gray-300"
        ref={ref}
      >
        <div className={classNames(scaleStyle, "origin-top-left")}>
          <div className="flex flex-col">
            <GroupDropZone groupIndex={0} />
            {layout.map((group, index) => (
              <>
                <TableAggregationGroupRender
                  key={index}
                  files={props.files}
                  group={group}
                  groupIndex={index}
                  setLayout={setLayout}
                />
                <GroupDropZone groupIndex={index + 1} />
              </>
            ))}
          </div>
        </div>
      </div>
      <div>
        <div className="mt-4 flex flex-row grow justify-end space-x-2">
          <button
            className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
            onClick={() => handlePreviousStep()}
          >
            Previous
          </button>
          <button
            className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
            onClick={() => handleNextStep()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
