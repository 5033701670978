import { useEffect } from "react";
import { useAssistantStore } from "../store/useAssistantStore";
import { useParams, useLocation } from "react-router-dom";
import { useValidatedParams } from "@/utils/router";
import { createDashboardContext, createDealContext } from "../utils/context";
import { useCurrentDashboard } from "@/features/dashboards/api/getCurrentDashboard";
import { paths } from "@/config/paths";

export const useAssistantContext = () => {
  const { orgId } = useValidatedParams(["orgId"]);
  const { dealId } = useParams();
  const location = useLocation();
  const { closeAssistant, setDefaultContext } = useAssistantStore();
  const dashboard = useCurrentDashboard({ orgId, dealId: dealId || "" });

  useEffect(() => {
    if (dealId) {
      setDefaultContext(createDealContext(orgId, dealId, "Deal"));
    } else {
      closeAssistant();
    }

    if (
      dealId &&
      [
        paths.orgs.selected.deals.selected.dashboards.current.getHref(
          orgId,
          dealId || "",
        ),
        paths.orgs.selected.deals.selected.dashboards.selected.getHref(
          orgId,
          dealId || "",
          dashboard?.id || "",
        ),
      ].includes(location.pathname) &&
      dashboard?.id
    ) {
      setDefaultContext(
        createDashboardContext(orgId, dealId, dashboard.id, "Dashboard"),
      );
    }
  }, [location.pathname, dealId, dashboard?.id]);

  return;
};
