import {
  baseURL,
  PaginatedResponse,
  resolveFetchPaginated,
} from "@/api/shared";
import { defaultRetryFunc } from "@/hooks/shared";
import { useAuth } from "@/hooks/shared";
import { QueryConfig } from "@/lib/reactQuery";
import { User } from "@/types";
import { assertUserIsAuthenticated } from "@/utils";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Thread } from "../types/api";

export const getThreads = async (
  user: User,
  orgId: string,
  dealId: string,
  cursor?: string,
  limit?: number,
  mode: "org" | "user" = "user",
): Promise<PaginatedResponse<Thread>> => {
  const params = new URLSearchParams();

  if (cursor) {
    params.append("cursor", cursor);
  }

  if (limit) {
    params.append("limit", limit.toString());
  }

  if (dealId) {
    params.append("deal_id", dealId);
  }

  const url =
    mode === "org"
      ? `${baseURL}/orgs/${orgId}/assistant-threads`
      : `${baseURL}/orgs/${orgId}/users/current/assistant-threads`;
  return await resolveFetchPaginated(
    fetch(`${url}?${params.toString()}`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

type useThreadsOptions = {
  orgId: string;
  dealId: string;
  config?: QueryConfig<typeof getThreads>;
  mode: "org" | "user";
};

export const useThreads = ({
  orgId,
  dealId,
  config,
  mode = "user",
}: useThreadsOptions) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useInfiniteQuery({
    queryKey: [orgId, dealId, "assistant-threads", mode],
    queryFn: ({ pageParam = "" }) => {
      return getThreads(user, orgId, dealId, pageParam, 5, mode);
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage) {
        return null;
      }
      return lastPage.nextCursor.value;
    },
    enabled: !!user,
    retry: defaultRetryFunc,
    ...config,
  });
};
