import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import { createHandle } from "@/utils/router";
import { DashboardView } from "@/features/dashboards/pages/DashboardView";
import { CurrentDashboardView } from "@/features/dashboards/pages/CurrentDashboardView";
import { MatchParam } from "@/types/router";

export const dashboardRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.deals.selected.dashboards.current.path,
    element: <CurrentDashboardView />,
    handle: createHandle("Dashboard", "dashboard"),
  },
  {
    path: paths.orgs.selected.deals.selected.dashboards.selected.path,
    element: <DashboardView />,
    handle: createHandle(MatchParam.dashboardId, "dashboard"),
  },
];
