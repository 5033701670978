import { useNavigate } from "react-router-dom";
import { useUserOrgPermissionsQuery } from "../../hooks";
import { hasPermission } from "../../utils";
import { paths } from "@/config/paths";
import { Button } from "capsa-ui";

interface Props {
  orgId: string;
}

export default function CreateDealButton({ orgId }: Props) {
  const permissionsQuery = useUserOrgPermissionsQuery(orgId);
  const navigate = useNavigate();

  switch (hasPermission(permissionsQuery, "deals.create")) {
    case "loading":
      return <Button loading>Create Deal</Button>;
    case "true":
      return (
        <Button
          onClick={() =>
            navigate(paths.orgs.selected.deals.create.getHref(orgId))
          }
        >
          Create Deal
        </Button>
      );
    default:
      return (
        <Button
          onClick={() =>
            navigate(paths.orgs.selected.deals.create.getHref(orgId))
          }
          disabled
        >
          Create Deal
        </Button>
      );
  }
}
