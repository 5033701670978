import {
  cn,
  HoverCard,
  HoverCardContent,
  HoverCardPortal,
  HoverCardTrigger,
  Icon,
  Text,
} from "capsa-ui";
import { Components } from "react-markdown";
import { SourceNode } from "../types/api";
import React from "react";

type Props = {
  sources?: Record<string, SourceNode>;
  onSourceClick: (source: SourceNode) => void;
};

export const ChatComponents = ({
  sources,
  onSourceClick,
}: Props): Partial<Components> => ({
  // @ts-expect-error - custom component type
  source: ({ id, table }: { id: string; table: boolean }) => {
    const sourceIndex = sources ? Object.keys(sources).indexOf(id) + 1 : -1;
    const isExternalWebpage =
      sources?.[id]?.source_info.type === "external_webpage";
    const pageNumber =
      sources?.[id]?.source_info.type === "file"
        ? sources?.[id]?.source_info.page_number
        : null;
    const sourceName =
      sources?.[id]?.source_info.type === "file"
        ? sources?.[id]?.source_info.name
        : sources?.[id]?.source_info.type === "external_webpage" ||
            sources?.[id]?.source_info.type === "webpage"
          ? sources?.[id]?.source_info.url
          : null;

    if (sourceIndex === -1) {
      return null;
    }

    const source = sources?.[id];

    if (!source) {
      return null;
    }

    return (
      <>
        <HoverCard openDelay={250} closeDelay={100}>
          <HoverCardTrigger asChild>
            {table ? (
              <span
                className="ml-0.5 text-caption text-content-primary -translate-y-1.5 inline-flex items-center justify-center"
                onClick={() => onSourceClick(source)}
                data-source-id={id}
                data-in-table={table ? "true" : undefined}
              >
                <Icon
                  type={isExternalWebpage ? "link" : "file"}
                  size="xs"
                  className={cn("scale-90 cursor-pointer")}
                />
              </span>
            ) : (
              <span
                className="ml-1 align-middle w-4 h-4 min-w-4 min-h-4 bg-surface-primary rounded text-content-primary text-caption inline-flex items-center justify-center select-none cursor-pointer"
                onClick={() => onSourceClick(source)}
                data-source-id={id}
                data-in-table={table ? "true" : undefined}
              >
                <Icon type={isExternalWebpage ? "link" : "file"} size="xs" />
              </span>
            )}
          </HoverCardTrigger>
          <HoverCardPortal>
            <HoverCardContent
              hideWhenDetached={true}
              className="rounded border py-1.5 px-2 max-w-48 flex flex-col gap-1 z-[10]"
              side="top"
            >
              {pageNumber && (
                <Text type="caption" color="subtle" className="w-full">
                  Page {pageNumber}
                </Text>
              )}
              <Text type="label" className="w-full break-all">
                {sourceName}
              </Text>
            </HoverCardContent>
          </HoverCardPortal>
        </HoverCard>
      </>
    );
  },
  h1: (props) => {
    return <h1 className="text-h3 font-medium">{props.children}</h1>;
  },
  h2: (props) => {
    return <h2 className="text-h4 font-medium">{props.children}</h2>;
  },
  h3: (props) => {
    return <h3 className="text-h5 font-medium">{props.children}</h3>;
  },
  strong: (props) => {
    return (
      <strong className="text-content text-body font-medium">
        {props.children}
      </strong>
    );
  },
  table: (props) => {
    return (
      <div className="w-full max-w-full min-w-0 overflow-auto shadow-outline rounded-layout my-4">
        <table
          className={cn(
            "text text-body border-collapse m-0",
            "[&_table]:table-auto",
            "[&_td]:!p-3 [&_td]:!border [&_strong]:!font-default [&_td:has([data-in-table])]:whitespace-nowrap",
            "[&_th]:!px-3 [&_th]:!py-1.5 [&_th]:!border [&_th]:!text-label [&_th]:text-content-1 [&_th]:font-medium",
          )}
        >
          {props.children}
        </table>
      </div>
    );
  },
  td: (props) => {
    return (
      <td>
        {props.children ? (
          props.children
        ) : (
          <Text type="label" className="text-content-1">
            -
          </Text>
        )}
      </td>
    );
  },
});
