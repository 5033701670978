import { Markdown as MarkdownWidget, WidgetCitation } from "@/types/widgets";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  EditorContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  useEditor,
  BubbleMenu,
} from "@tiptap/react";
import { Markdown } from "tiptap-markdown";
import { mergeAttributes, Node } from "@tiptap/core";

import StarterKit from "@tiptap/starter-kit";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Underline from "@tiptap/extension-underline";

import {
  cn,
  HoverCardContent,
  HoverCard,
  HoverCardTrigger,
  Icon,
  Text,
  Toggle,
  WidgetRenderProps,
  HoverCardPortal,
} from "capsa-ui";
import { useUpdateWidget } from "../../api/updateWidget";
import { useValidatedParams } from "@/utils/router";
import { debounce } from "lodash";
import { removeCitations } from "../../utils/bullets";
import { convertTipTapToMarkdown } from "@/features/assistant/utils/tiptap";

interface Props extends WidgetRenderProps {
  widgetId: string;
  data: MarkdownWidget;
  onSourceClick: (source: WidgetCitation) => void;
  editable?: boolean;
}

export const WidgetMarkdownRenderer = ({
  data,
  onSourceClick,
  widgetId,
  editable = false,
  setClipboard,
}: Props) => {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const { mutateAsync } = useUpdateWidget({ orgId, dealId, widgetId });
  const { content, citations } = data.entry.options[0];
  const lastUpdate = useRef("");
  const [editorKey, setEditorKey] = useState(0);

  const transformedContent = useMemo(() => {
    let sourceCount = 0;
    return content.replace(/【([a-zA-Z0-9]{5})】/g, (_, id) => {
      sourceCount++;
      const sourceTag = `<source id="${id}" count="${sourceCount}" />`;
      return sourceTag;
    });
  }, [content]);

  const editor = useEditor(
    {
      editable,
      extensions: [
        StarterKit,
        Markdown,
        CustomTable.configure({
          resizable: false,
          allowTableNodeSelection: false,
        }),
        TableRow,
        // Treat th as td
        TableHeader.extend({
          name: "tableHeader",
          content: "block+",
          tableRole: "cell",
          parseHTML() {
            return [{ tag: "th" }];
          },
          renderHTML({ HTMLAttributes }) {
            return ["td", HTMLAttributes, 0];
          },
        }),
        TableCell,
        Underline,
        Source.configure({
          citations,
          onSourceClick,
        }),
      ],
      content: transformedContent,
      onUpdate: async ({ editor }) => {
        debouncedUpdate(editor);
      },
    },
    [editorKey],
  );

  const debouncedUpdate = useMemo(
    () =>
      debounce(async (editor) => {
        try {
          const markdown = convertTipTapToMarkdown(editor);
          lastUpdate.current = markdown;

          const updatedContent = {
            ...data,
            entry: {
              ...data.entry,
              options: [
                {
                  ...data.entry.options[0],
                  content: markdown,
                },
              ],
            },
          };
          await mutateAsync({ content: [updatedContent] });
        } catch (error) {
          console.error(error);
        }
      }, 1000),
    [data, mutateAsync],
  );

  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  useEffect(() => {
    // only updates if editor is refreshed
    if (content !== lastUpdate.current) {
      setEditorKey((prev) => prev + 1);
    }
  }, [transformedContent]);

  useEffect(() => {
    if (setClipboard) {
      setClipboard(
        new ClipboardItem({
          "text/plain": new Blob([removeCitations(content)], {
            type: "text/plain",
          }),
        }),
      );
    }
  }, [content]);

  return (
    <div>
      {editor && (
        <BubbleMenu
          className="bg-surface border flex rounded-layout shadow-popover gap-1 p-1"
          tippyOptions={{ duration: 100 }}
          editor={editor}
        >
          <Toggle
            pressed={editor.isActive("bold")}
            onClick={() => editor.chain().focus().toggleBold().run()}
            iconRight="bold"
            size="sm"
            variant="ghost"
          />
          <Toggle
            pressed={editor.isActive("italic")}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            iconRight="italic"
            size="sm"
            variant="ghost"
          />
          <Toggle
            pressed={editor.isActive("underline")}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            iconRight="underline"
            size="sm"
            variant="ghost"
          />
          <Toggle
            pressed={editor.isActive("strike")}
            onClick={() => editor.chain().focus().toggleStrike().run()}
            iconRight="strikethrough"
            size="sm"
            variant="ghost"
          />
        </BubbleMenu>
      )}
      <EditorContent
        editor={editor}
        className={cn(
          // editor
          "prose text font-sans text-body max-w-full my-1 px-0.5 [&_*:first-child]:!mt-0",

          // tiptap
          "[&_.tiptap>*:first-child]:!mt-0",

          // headings
          "[&_h1]:text-h1 [&_h1]:font-bold",
          "[&_h2]:text-h2 [&_h2]:font-bold",
          "[&_h3]:text-h3 [&_h3]:font-bold [&_h3]:mb-4",
          "[&_h4]:text-h4 [&_h4]:font-bold [&_h4]:mb-3",
          "[&_h5]:text-h5 [&_h5]:font-bold",
          "[&_h6]:text-h6 [&_h6]:font-bold",

          // text
          "[&_strong]:font-medium",
          "[&_small]:text-label",
          "[&_small]:text-content-1 [&_small]:font-medium",

          // hr
          "[&_hr]:border-t [&_hr]:!my-1.5",

          // lists
          "[&_ul]:pl-4 [&_ul]:list-disc",
          "[&_ol]:pl-4 [&_ol]:list-decimal",
          "[&_li]:mb-2 [&_li]:mt-0 [&_li]:pl-0",

          // tables
          "[&_table]:table-auto [&_table]:!text-body [&_table]:relative [&_table]:w-full [&_table]:!m-0",
          "[&_td]:relative [&_td]:z-[1] [&_td]:p-3 [&_td]:border [&_td]:border-edge-subtle [&_td>p]:mb-0 [&_td]:align-top",
          "[&_tr:first-child_td]:text-label [&_tr:first-child_td]:text-content-1 [&_tr:first-child_td]:py-2 [&_tr:first-child_td]:font-medium",
          "[&_.selectedCell]:bg-surface-primary-subtle [&_.selectedCell]:before:absolute [&_.selectedCell]:before:-inset-px [&_.selectedCell]:before:z-[2] [&_.selectedCell]:before:border [&_.selectedCell]:before:border-edge-primary",
        )}
      />
    </div>
  );
};

const CustomTable = Table.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      "div",
      { class: "table-wrapper overflow-auto mb-6 last:mb-0" },
      ["table", HTMLAttributes, 0],
    ];
  },
});

const Source = Node.create({
  name: "source",
  group: "inline",
  inline: true,
  selectable: false,

  addAttributes: () => ({
    id: { default: null },
    count: { default: null },
    isInTable: { default: false },
  }),
  parseHTML() {
    return [
      {
        tag: "source[id][count]",
        getAttrs: (element) => {
          if (!(element instanceof HTMLElement)) return false;

          const isInTable = !!element.closest("td, th");

          return {
            id: element.getAttribute("id"),
            count: element.getAttribute("count"),
            isInTable,
          };
        },
      },
    ];
  },
  renderHTML: ({ HTMLAttributes, node }) => [
    "source",
    mergeAttributes(HTMLAttributes),
    node.attrs.id,
  ],
  addNodeView: () =>
    ReactNodeViewRenderer(
      ({ selected, node, extension }) => {
        const id = node.attrs.id;
        const isInTable = node.attrs.isInTable;

        const citation = useMemo(() => {
          return (extension.options.citations as WidgetCitation[])?.find(
            (c) => c.searchResult.id === id,
          );
        }, [extension.options.citations, id]);

        const sourceName = useMemo(() => {
          switch (citation?.searchResult.type) {
            case "webpage":
            case "external_webpage":
              return citation.searchResult.url;
            case "file":
              return citation.searchResult.fileName;
            default:
              return "";
          }
        }, [citation]);

        const pageNumber = useMemo(() => {
          if (citation?.searchResult.type === "file") {
            return citation?.searchResult.pageNumber;
          }
          return null;
        }, [citation]);

        const handleClick = () => {
          extension.options.onSourceClick?.(citation);
        };

        if (!citation) {
          return null;
        }

        if (isInTable) {
          return (
            <>
              {" "}
              <NodeViewWrapper
                as="div"
                onClick={handleClick}
                className="text-caption text-content-primary -translate-y-px"
                data-source-id={id}
                data-in-table={isInTable}
              >
                <HoverCard openDelay={250} closeDelay={100}>
                  <HoverCardTrigger asChild>
                    <span>
                      <Icon
                        type={
                          citation?.searchResult.type === "external_webpage"
                            ? "link"
                            : "file"
                        }
                        size="xs"
                        className={cn(
                          "scale-90 cursor-pointer",
                          selected && "brightness-75",
                        )}
                      />
                    </span>
                  </HoverCardTrigger>
                  <HoverCardPortal>
                    <HoverCardContent
                      hideWhenDetached={true}
                      className="rounded border py-1.5 px-2 max-w-48 flex flex-col gap-1 z-[10]"
                      side="top"
                    >
                      {pageNumber && (
                        <Text type="caption" color="subtle" className="w-full">
                          Page {pageNumber}
                        </Text>
                      )}
                      <Text type="label" className="w-full break-all">
                        {sourceName}
                      </Text>
                    </HoverCardContent>
                  </HoverCardPortal>
                </HoverCard>
              </NodeViewWrapper>
            </>
          );
        }

        return (
          <NodeViewWrapper
            as="div"
            onClick={handleClick}
            className="ml-1 -translate-y-px w-4 h-4 min-w-4 min-h-4 inline-flex items-center justify-center"
          >
            <HoverCard openDelay={250} closeDelay={100}>
              <HoverCardTrigger asChild>
                <div
                  className={cn(
                    "w-4 h-4 min-w-4 min-h-4 bg-surface-primary rounded text-content-primary text-caption inline-flex items-center justify-center select-none cursor-pointer",
                    selected && "brightness-90",
                  )}
                  data-source-id={id}
                >
                  <Icon
                    type={
                      citation?.searchResult.type === "external_webpage"
                        ? "link"
                        : "file"
                    }
                    size="xs"
                  />
                </div>
              </HoverCardTrigger>
              <HoverCardPortal>
                <HoverCardContent
                  hideWhenDetached={true}
                  className="rounded border py-1.5 px-2 max-w-48 flex flex-col gap-1 z-[10]"
                  side="top"
                >
                  {pageNumber && (
                    <Text type="caption" color="subtle" className="w-full">
                      Page {pageNumber}
                    </Text>
                  )}
                  <Text type="label" className="w-full break-all">
                    {sourceName}
                  </Text>
                </HoverCardContent>
              </HoverCardPortal>
            </HoverCard>
          </NodeViewWrapper>
        );
      },
      {
        className: "inline-flex items-center justify-center align-middle",
      },
    ),
});
