import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";
import { useAuth } from "@/hooks/shared";
import { assertUserIsAuthenticated } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Thread, ThreadConfig } from "../types/api";
import { AssistantContext } from "../types/state";

export const createThread = async (
  user: User,
  orgId: string,
  body: {
    message?: string;
    config?: ThreadConfig;
    context?: AssistantContext[];
  },
): Promise<Thread> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/users/current/assistant-threads`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(body),
    }),
    true,
  );
};

type useCreateThreadOptions = {
  orgId: string;
};

export const useCreateThread = ({ orgId }: useCreateThreadOptions) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      message,
      config,
      context,
    }: {
      message?: string;
      config?: ThreadConfig;
      context?: AssistantContext[];
    }) => createThread(user, orgId, { message, config, context }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [orgId, "assistant-threads"],
      });
    },
  });
};
