import { Button, Divider, Icon, Text } from "capsa-ui";
import SyncSharepointModal from "@/features/sharepoint/components/SyncSharepointModal";
import { useSharepointStore } from "@/features/sharepoint/store/useSharepointStore";
import React, { useEffect, useMemo, useRef, useState } from "react";

interface FileUploadProps {
  onFileUpload: (files: FileList) => void;
}

const FileUploadComponent: React.FC<FileUploadProps> = ({ onFileUpload }) => {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    SharepointImportButton,
    openModal,
    sharepointEnabled,
    selectedFileIds,
    selectedFolderIds,
    setSelectedFileIds,
    setSelectedFolderIds,
  } = useSharepointStore();

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const droppedFiles = Array.from(e.dataTransfer.files);
      setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
      e.dataTransfer.clearData();
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFiles = Array.from(e.target.files);
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    }
  };

  const removeFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const dataTransfer = new DataTransfer();
    files.forEach((file) => dataTransfer.items.add(file));
    onFileUpload(dataTransfer.files);
  }, [files]);

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSharepointImport = (data: {
    files: string[];
    folders: string[];
  }) => {
    setSelectedFileIds(data.files);
    setSelectedFolderIds(data.folders);
  };

  const resetSharepointFiles = () => {
    setSelectedFileIds([]);
    setSelectedFolderIds([]);
  };

  const sharepointImportString = useMemo(() => {
    const files = selectedFileIds.length;
    const folders = selectedFolderIds.length;

    return `${files > 0 ? `${files} file${files > 1 ? "s" : ""}${folders > 0 ? ", " : ""} ` : ""}${folders > 0 ? `${folders} folder${folders > 1 ? "s" : ""}` : ""} selected`.trim();
  }, [selectedFileIds, selectedFolderIds]);

  return (
    <>
      <SyncSharepointModal handleImport={handleSharepointImport} />
      <div className="rounded-layout content-left space-y-3 relative w-full content-left flex flex-col border p-3 flex-1 max-h-fit overflow-hidden">
        <div
          className="w-full flex flex-col justify-center items-center space-y-2"
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragEnter}
          onDrop={handleDrop}
        >
          <div
            className={`transition bg-surface gap-4 p-10 w-full ${files.length > 0 || selectedFileIds.length > 0 || selectedFolderIds.length > 0 ? "h-fit" : "h-80"} border border-dashed rounded-element flex flex-col justify-center items-center ${
              dragging ? "border-edge-primary bg-surface-1" : "border"
            }`}
          >
            <div className="bg-surface-primary flex justify-center items-center text-content-2 w-fit h-fit p-2 rounded-layout">
              <Icon
                size="hero"
                type="upload"
                className="text-content-primary"
              />
            </div>
            <div className="flex flex-col gap-3 justify-start">
              <Text color="subtle">
                Drag and drop /{" "}
                <span
                  className="text-content-primary clickable pressable"
                  onClick={triggerFileInput}
                >
                  browse files
                </span>{" "}
                to upload
              </Text>
              <input
                ref={fileInputRef}
                type="file"
                multiple
                className="hidden"
                onChange={handleFileSelect}
              />

              {sharepointEnabled && (
                <>
                  <div className="flex-center w-full gap-3">
                    <Divider />
                    <Text type="caption" color="faint">
                      or
                    </Text>
                    <Divider />
                  </div>
                  <SharepointImportButton fullWidth />
                </>
              )}
            </div>
          </div>
        </div>
        {(selectedFileIds.length > 0 || selectedFolderIds.length > 0) && (
          <div className="border flex justify-between items-center px-3 py-2 gap-2 rounded">
            <Icon type="sharepoint" />
            <Text type="label" color="subtle" className="flex-1">
              {sharepointImportString}
            </Text>
            <Button variant="outline" onClick={openModal} type="button">
              Update
            </Button>
            <Button
              variant="outline"
              onClick={resetSharepointFiles}
              iconLeft="delete"
              type="reset"
            />
          </div>
        )}
        {files.length > 0 && (
          <div className="flex flex-col space-y-2 overflow-y-auto">
            {files.map((file, index) => (
              <div
                key={index}
                className="border flex justify-between items-center px-3 py-2 rounded"
              >
                <Text type="label" color="subtle">
                  {file.name}
                </Text>
                <Button
                  variant="outline"
                  onClick={() => removeFile(index)}
                  iconLeft="delete"
                  type="reset"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default FileUploadComponent;
