import {
  AssistantContextDashboard,
  AssistantContextDeal,
  AssistantContextWidget,
} from "../types/state";

export const createDealContext = (
  orgId: string,
  dealId: string,
  name: string,
): AssistantContextDeal => ({
  type: "deal",
  org_id: orgId,
  deal_id: dealId,
  name,
});

export const createDashboardContext = (
  orgId: string,
  dealId: string,
  dashboardId: string,
  name: string,
): AssistantContextDashboard => ({
  type: "dashboard",
  org_id: orgId,
  deal_id: dealId,
  dashboard_id: dashboardId,
  name,
});

export const createWidgetContext = (
  orgId: string,
  dealId: string,
  widgetId: string,
  name: string,
): AssistantContextWidget => ({
  type: "widget",
  org_id: orgId,
  deal_id: dealId,
  widget_id: widgetId,
  name,
});
