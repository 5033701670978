import {
  cn,
  Icon,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "capsa-ui";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import Markdown from "react-markdown";
import { SourceNode, ThreadMessage } from "../types/api";
import { useMemo } from "react";
import rehypeRaw from "rehype-raw";
import rehypeKatex from "rehype-katex";
import { useAuth0 } from "@auth0/auth0-react";
import { ChatComponents } from "./ChatComponents";
import { ChatToolbar } from "./ChatToolbar";
import {
  extractSourceIdsFromMarkdown,
  transformChatMarkdown,
} from "../utils/markdown";
import { motion } from "framer-motion";

type Props = {
  message: ThreadMessage;
  ref?: React.RefObject<HTMLDivElement>;
  onSourceClick: (source: SourceNode) => void;
  sources?: Record<string, SourceNode>;
  lastMessage?: boolean;
  lastReply?: boolean;
};

export const ChatMessage = ({
  message,
  ref,
  onSourceClick,
  sources,
  lastMessage,
  lastReply,
}: Props) => {
  const { user } = useAuth0();

  if (message.role !== "assistant" && message.role !== "user") {
    return null;
  }

  const raw = useMemo(() => {
    return transformChatMarkdown(message.content_parts?.join("") ?? "");
  }, [message.content_parts]);

  const userInitial = useMemo(() => {
    return (
      user?.email?.charAt(0).toUpperCase() || <Icon type="user" size="sm" />
    );
  }, [user?.email]);

  const sourceList = useMemo(
    () => extractSourceIdsFromMarkdown(message.content_parts?.join("") ?? ""),
    [message.content_parts],
  );

  return (
    <div
      className={cn(
        "p-2 flex gap-2.5 rounded min-w-0 w-full",
        message.role === "user" && "bg-surface-2",
      )}
      ref={ref}
      id={message.id}
      data-last-message={lastMessage}
      data-last-reply={lastReply}
    >
      {message.role === "user" && (
        <Tooltip>
          <TooltipTrigger className="!cursor-default h-fit">
            <div className="w-5 h-5 min-w-5 min-h-5 rounded flex-center bg-surface-2 brightness-95">
              <Text
                className="text-label text-content-1 user-select-none"
                weight="bold"
              >
                {userInitial}
              </Text>
            </div>
          </TooltipTrigger>
          <TooltipContent>{user?.email || "you"}</TooltipContent>
        </Tooltip>
      )}
      {message.role === "assistant" && (
        <Tooltip>
          <TooltipTrigger className="!cursor-default h-fit">
            <div className="w-5 h-5 min-w-5 min-h-5 rounded bg-surface-primary-accent flex-center text-content-inverted">
              <Icon type="capsa" size="xs" />
            </div>
          </TooltipTrigger>
          <TooltipContent>Capsa AI</TooltipContent>
        </Tooltip>
      )}
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, ease: "easeOut" }}
        className={cn(
          "flex-1 prose text-content text-body !max-w-none min-w-0",
          // math styling
          "[&_.katex]:block [&_.katex]:border [&_.katex]:rounded-layout [&_.katex]:p-3 [&_.katex]:my-3 [&_.katex]:overflow-auto [&_.katex-html]:hidden",
          "[&_math]:w-fit",
          "[&_math_*]:text [&_math_*]:text-body",
          "[&_mo]:text-content-primary",
          "[&_[fence=true]]:text-content-2",
          "[&_[separator=true]]:text",
        )}
      >
        <Markdown
          rehypePlugins={[rehypeRaw, rehypeKatex]}
          remarkPlugins={[
            remarkGfm,
            [remarkMath, { singleDollarTextMath: false }],
          ]}
          components={ChatComponents({
            sources,
            onSourceClick,
          })}
        >
          {raw}
        </Markdown>
        {message.role === "assistant" && (
          <ChatToolbar
            sources={sources}
            onSourceClick={onSourceClick}
            sourceList={sourceList}
            message={message.content_parts?.join("") ?? ""}
          />
        )}
      </motion.div>
    </div>
  );
};
