import * as React from "react";
import {
  CommandItem,
  CommandGroup,
  CommandInput,
  Command,
  CommandList,
  CommandEmpty,
  Text,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Button,
  Checkbox,
  PopoverContentProps,
  ButtonVariant,
  IconType,
} from "../../..";
import { omit, isEqual } from "lodash";
import { useMemo, useEffect, useState } from "react";

interface MultiSelectProps {
  options: {
    label: string;
    value: string;
  }[];
  selected: string[];
  onValueChange: (value: string[]) => void;
  updateOnSave?: boolean;
  hasReset?: boolean;
  label: string;
  modalPopover?: boolean;
  className?: string;
  size?: "sm" | "default";
  popoverContentProps?: PopoverContentProps;
  disabled?: boolean;
  optionsLabel?: string;
  message?: string;
  variant?: ButtonVariant;
  flat?: boolean;
  iconLeft?: IconType;
}

export const MultiSelect = React.forwardRef<
  HTMLButtonElement,
  MultiSelectProps
>(
  (
    {
      options,
      selected,
      onValueChange,
      label,
      modalPopover = false,
      updateOnSave,
      size,
      popoverContentProps,
      disabled,
      optionsLabel = "Options",
      message,
      hasReset,
      variant = "outline",
      flat = false,
      iconLeft,
      ...props
    },
    ref,
  ) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState(selected);

    const handleInputKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
      if (event.key === "Enter") {
        setIsPopoverOpen(true);
      }
    };

    React.useEffect(() => {
      if (updateOnSave) {
        setSelectedValues(selected);
      }
    }, [selected]);

    const toggleOption = (option: string) => {
      const newSelectedValues = selectedValues.includes(option)
        ? selectedValues.filter((value) => value !== option)
        : [...selectedValues, option];
      setSelectedValues(newSelectedValues);
      if (!updateOnSave) {
        onValueChange(newSelectedValues);
      }
    };

    const handleTogglePopover = () => {
      setIsPopoverOpen((prev) => !prev);
    };

    const selectedOptions = useMemo(
      () => options.filter((option) => selectedValues.includes(option.value)),
      [options, selectedValues],
    );

    const unselectedOptions = useMemo(
      () => options.filter((option) => !selectedValues.includes(option.value)),
      [options, selectedValues],
    );

    const handleSave = () => {
      setIsPopoverOpen(false);
      onValueChange(selectedValues);
    };

    const handleReset = () => {
      setIsPopoverOpen(false);
      onValueChange([]);
    };

    useEffect(() => {
      if (!isPopoverOpen) {
        setSelectedValues(selected);
      }
    }, [isPopoverOpen]);

    const noChanges = useMemo(
      () => isEqual([...selectedValues].sort(), [...selected].sort()),
      [selectedValues, selected],
    );

    return (
      <Popover
        open={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
        modal={modalPopover}
      >
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            onClick={handleTogglePopover}
            disabled={disabled}
            variant={variant}
            iconRight="chevronDown"
            size={size}
            flat={flat}
            iconLeft={iconLeft}
            {...omit(props, ["value"])}
          >
            <>
              {label}
              {selectedValues?.length > 0 && (
                <span className="flex items-center gap-px">
                  <span className="opacity-50">(</span>
                  {selectedValues.length}
                  <span className="opacity-50">)</span>
                </span>
              )}
            </>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-0"
          align="start"
          onEscapeKeyDown={() => setIsPopoverOpen(false)}
          {...popoverContentProps}
        >
          <Command>
            <CommandInput
              placeholder="Search..."
              onKeyDown={handleInputKeyDown}
            />
            {selectedOptions.length === 0 && options.length > 0 && message && (
              <div className="px-2 py-1 mx-1 mt-1 bg-surface-1 rounded-badge">
                <Text type="label" className="text-content opacity-75">
                  {message}
                </Text>
              </div>
            )}
            <CommandList>
              <CommandEmpty>No {optionsLabel} found.</CommandEmpty>
              {selectedOptions.length > 0 && (
                <CommandGroup heading={`Selected ${optionsLabel}`}>
                  {selectedOptions.map((option) => {
                    return (
                      <CommandItem
                        key={option.value}
                        value={option.label}
                        onSelect={() => toggleOption(option.value)}
                        className="cursor-pointer"
                      >
                        <Checkbox
                          checked={true}
                          checkboxClassName="h-icon-sm w-icon-sm mt-px"
                        />
                        <span>{option.label}</span>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              )}
              {unselectedOptions.length > 0 && (
                <CommandGroup
                  heading={
                    selectedOptions.length > 0
                      ? `Other ${optionsLabel}`
                      : `${optionsLabel}`
                  }
                >
                  {unselectedOptions.map((option) => {
                    return (
                      <CommandItem
                        key={option.value}
                        value={option.label}
                        onSelect={() => toggleOption(option.value)}
                        className="cursor-pointer"
                      >
                        <Checkbox
                          checked={false}
                          checkboxClassName="h-icon-sm w-icon-sm mt-px"
                        />
                        <span>{option.label}</span>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              )}
            </CommandList>
            <div className="flex items-center justify-between gap-1 p-1">
              {hasReset && (
                <Button
                  variant="outline"
                  onClick={handleReset}
                  fullWidth
                  className="flex-1"
                >
                  Reset
                </Button>
              )}
              {updateOnSave && (
                <Button
                  onClick={handleSave}
                  fullWidth
                  className="flex-1"
                  disabled={noChanges}
                >
                  Save
                </Button>
              )}
            </div>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

MultiSelect.displayName = "MultiSelect";
