import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import {
  APIKeyCreate,
  APIKeyUpdate,
  createAPIKey,
  listAPIKeys,
  updateAPIKey,
} from "../api/apiKeys";
import { getBillingStatus } from "../api/billing";
import { listDealNodes } from "../api/dealNodes";
import {
  OrgInviteCreate,
  OrgInviteUpdate,
  UserInviteUpdate,
  createOrgInvite,
  deleteOrgInvite,
  listOrgInvites,
  listUserInvites,
  readOrgInvite,
  updateOrgInvite,
  updateUserInvite,
} from "../api/invites";
import {
  UserOrgCreate,
  createUserOrg,
  listUserOrgs,
  readOrg,
} from "../api/orgs";
import { listRoles } from "../api/roles";
import {
  SigningKeyCreate,
  SigningKeyUpdate,
  createSigningKey,
  listSigningKeys,
  updateSigningKey,
} from "../api/signingKeys";
import { listUserOrgRoles } from "../api/userOrgRoles";
import {
  OrgUserUpdate,
  deleteOrgUser,
  listOrgUsers,
  readOrgUser,
  updateOrgUser,
} from "../api/users";
import { assertUserIsAuthenticated } from "../utils";
import { defaultRetryFunc, useAuth } from "./shared";

export const useUserOrgsQuery = () => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: ["orgs"],
    queryFn: () => listUserOrgs(user),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateUserOrgMutation = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: UserOrgCreate) => createUserOrg(user, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["orgs"] });
    },
  });
};

export const useAPIKeysQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "api-keys"],
    queryFn: () => listAPIKeys(user, orgId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateAPIKeyMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: APIKeyCreate) => createAPIKey(user, orgId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "api-keys"] });
    },
  });
};

export const useUpdateAPIKeyMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({
      apiKeyId,
      data,
    }: {
      apiKeyId: string;
      data: APIKeyUpdate;
    }) => updateAPIKey(user, orgId, apiKeyId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "api-keys"] });
    },
  });
};

export const useSigningKeysQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "signing-keys"],
    queryFn: () => listSigningKeys(user, orgId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateSigningKeyMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: SigningKeyCreate) => createSigningKey(user, orgId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "signing-keys"] });
    },
  });
};

export const useUpdateSigningKeyMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({
      signingKeyId,
      data,
    }: {
      signingKeyId: string;
      data: SigningKeyUpdate;
    }) => updateSigningKey(user, orgId, signingKeyId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "signing-keys"] });
    },
  });
};

export const useOrgInviteQuery = (orgId: string, inviteId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "invites", inviteId],
    queryFn: () => readOrgInvite(user, orgId, inviteId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useOrgInvitesQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "invites"],
    queryFn: () => listOrgInvites(user, orgId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateOrgInviteMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: OrgInviteCreate) => createOrgInvite(user, orgId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "invites"] });
    },
  });
};

export const useUpdateOrgInviteMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({
      inviteId,
      data,
    }: {
      inviteId: string;
      data: OrgInviteUpdate;
    }) => updateOrgInvite(user, orgId, inviteId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "invites"] });
    },
  });
};

export const useDeleteOrgInviteMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({ inviteId }: { inviteId: string }) =>
      deleteOrgInvite(user, orgId, inviteId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "invites"] });
    },
  });
};

export const useOrgUsersQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "users"],
    queryFn: () => listOrgUsers(user, orgId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useOrgUserQuery = (orgId: string, userId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "users", userId],
    queryFn: () => readOrgUser(user, orgId, userId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useSubscriptionStatusQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "billing"],
    queryFn: () => getBillingStatus(user, orgId),
    enabled: !!user,
  });
};

export const useUpdateOrgUserMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({ userId, data }: { userId: string; data: OrgUserUpdate }) =>
      updateOrgUser(user, orgId, userId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "users"] });
      queryClient.invalidateQueries({ queryKey: [orgId, "deals"] });
    },
  });
};

export const useDeleteOrgUserMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({ userId }: { userId: string }) =>
      deleteOrgUser(user, orgId, userId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "users"] });
    },
  });
};

export const useUserInvitesQuery = () => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: ["invites"],
    queryFn: () => listUserInvites(user),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useUpdateUserInviteMutation = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({
      inviteId,
      data,
    }: {
      inviteId: string;
      orgId: string;
      data: UserInviteUpdate;
    }) => updateUserInvite(user, inviteId, data),
    onSuccess: (_, { orgId }) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "roles", "permissions"],
      });
      queryClient.invalidateQueries({ queryKey: ["invites"] });
      queryClient.invalidateQueries({ queryKey: ["orgs"] });
    },
  });
};

export const useRolesQuery = (orgId: string, type: "org" | "deal") => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, type === "org" ? "orgRoles" : "dealRoles"],
    queryFn: () => listRoles(user, orgId, type),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useUserOrgPermissionsQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "roles", "permissions"],
    queryFn: async () => {
      const roles = await listUserOrgRoles(user, orgId);
      const permissions = new Set<string>();
      for (const role of roles) {
        for (const permission of role.permissions) {
          permissions.add(permission.permission);
        }
      }
      return permissions;
    },
    enabled: !!user,
    retry: defaultRetryFunc,
    staleTime: 1000 * 60,
  });
};

export const useOrgQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId],
    queryFn: () => readOrg(user, orgId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useQueryParams = <Type extends Record<string, string>>(
  initParams: Type,
): [Type, (arg0: Type) => void] => {
  const [searchParams, setSearchParams] = useSearchParams(initParams);
  const queryParams = Object.fromEntries(searchParams.entries()) as Type;
  const setQueryParams = (params: Type) => setSearchParams(params);
  return [queryParams, setQueryParams];
};

export { useAuth };

export const useListDealNodesQuery = (
  orgId: string,
  dealId: string,
  query: string,
  limit: number,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "nodes", query],
    queryFn: () => listDealNodes(user, orgId, dealId, query, limit),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};
