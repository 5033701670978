import { Button, Icon, Text } from "capsa-ui";
import { useValidatedParams } from "@/utils/router";
import { useNavigate } from "react-router-dom";
import { paths } from "@/config/paths";
import { usePostHog } from "posthog-js/react";
import { useDealFilesQuery } from "@/hooks/deals";
import { useDocumentUploadJobsQuery } from "@/hooks/documentUploadJobs";
import { useLocalVisibility } from "@/hooks/useLocalVisibility";

export const DashboardCTAs = ({ dashboardId }: { dashboardId?: string }) => {
  const { dealId, orgId } = useValidatedParams(["dealId", "orgId"]);
  const filesQuery = useDealFilesQuery(orgId, dealId);
  const jobsQuery = useDocumentUploadJobsQuery(orgId, dealId);
  const posthog = usePostHog();
  const navigate = useNavigate();

  if (!dashboardId) {
    return null;
  }

  const { visible, hide } = useLocalVisibility(
    `dashboard-upload-cta-${dashboardId}-${dealId}`,
  );

  const showFilesCTA = filesQuery.data && filesQuery.data.length === 0;
  const showRegistryDocumentsCTA =
    jobsQuery.data &&
    jobsQuery.data.length === 0 &&
    posthog.isFeatureEnabled("legal_entity_search");

  if ((!showFilesCTA && !showRegistryDocumentsCTA) || !visible) {
    return null;
  }

  return (
    <div className="@container flex items-center gap-2 w-full p-2 border bg-surface rounded-layout flex-wrap">
      <div className="flex flex-1 min-w-[50%] gap-1.5 ml-1">
        <Icon type="info" className="text-content-primary mt-px" size="sm" />
        <Text className="flex-1">
          Add more data sources to improve the quality of your dashboard.
        </Text>
      </div>
      <div className="flex items-center flex-wrap gap-2 justify-end">
        {showFilesCTA && (
          <Button
            variant="outline"
            iconLeft="folderClosed"
            flat
            onClick={() =>
              navigate(
                paths.orgs.selected.deals.selected.files.getHref(orgId, dealId),
              )
            }
          >
            Files
          </Button>
        )}
        {showRegistryDocumentsCTA && (
          <Button
            variant="outline"
            iconLeft="registrySearch"
            flat
            onClick={() =>
              navigate(
                paths.orgs.selected.deals.selected.registrySearchJobs.getHref(
                  orgId,
                  dealId,
                ),
              )
            }
          >
            Registry Documents
          </Button>
        )}
        <Button variant="outline" iconLeft="cancel" flat onClick={hide} />
      </div>
    </div>
  );
};
