/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { paths } from "@/config/paths";
import { CapsaLogo } from "capsa-ui";
import { Outlet, useNavigate } from "react-router-dom";

export default function LoggedOutDashboardLayout() {
  const navigate = useNavigate();
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-surface-gray-100">
        <body class="h-full">
        ```
      */}
      <div>
        <div className="flex flex-1 flex-col">
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-surface border-b">
            <div className="flex flex-1 justify-between px-4">
              <div className="flex items-center">
                <button onClick={() => navigate(paths.root.getHref())}>
                  <CapsaLogo />
                </button>
              </div>
              <div className="flex flex-1">
                {/* <form className="flex w-full md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                      <MagnifyingGlassIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      id="search-field"
                      className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-hidden focus:ring-0 sm:text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                    />
                  </div>
                </form> */}
              </div>
              <div className="ml-4 flex items-center md:ml-6" />
            </div>
          </div>

          <main className="flex-1">
            <div className="py-6">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
