import "@/utils/monitoring";
import { paths } from "@/config/paths";
import info from "@/info";
import { Auth0Provider } from "@auth0/auth0-react";
import { pdfjs } from "react-pdf";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { CapsaLogo, Icon, Text, Toaster, TooltipProvider } from "capsa-ui";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { UploadProvider } from "@/components/UploadProvider";

type AppProviderProps = {
  children: React.ReactNode;
};

const MainErrorFallback = () => {
  return (
    <div className="flex-center flex-col h-screen w-screen" role="alert">
      <div className="flex-center flex-col gap-5 p-5 border rounded-layout">
        <CapsaLogo />
        <div className="flex-center gap-3 p-3 bg-surface-danger rounded text-content-danger">
          <Icon type="alertFilled" />
          <Text>{"Something went wrong."}</Text>
          <span onClick={() => window.location.assign(window.location.origin)}>
            <Text className="text-content-danger underline cursor-pointer">
              {"Refresh"}
            </Text>
          </span>
        </div>
      </div>
    </div>
  );
};

const PDFWorkerProvider = ({ children }: { children: React.ReactNode }) => {
  React.useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
  }, []);

  return <>{children}</>;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const [queryClient] = React.useState(() => new QueryClient());

  return (
    <Sentry.ErrorBoundary fallback={MainErrorFallback}>
      <PDFWorkerProvider>
        <Auth0Provider
          domain={info.auth0Domain}
          clientId={info.auth0ClientId}
          authorizationParams={{
            audience: "https://api.capsa.ai",
            redirect_uri: `${info.appURL}${paths.auth.login.getHref()}`,
          }}
        >
          <QueryClientProvider client={queryClient}>
            <UploadProvider>
              {import.meta.env.DEV && (
                <ReactQueryDevtools
                  initialIsOpen={false}
                  panelProps={{ style: { display: "none" } }}
                  toggleButtonProps={{ style: { display: "none" } }}
                />
              )}

              <TooltipProvider>{children}</TooltipProvider>
              <Toaster />
            </UploadProvider>
          </QueryClientProvider>
        </Auth0Provider>
      </PDFWorkerProvider>
    </Sentry.ErrorBoundary>
  );
};
