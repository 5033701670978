import { useEffect, useState } from "react";

import { useCallback } from "react";

interface VisibilityOptions {
  defaultVisible?: boolean;
  expireAfterDays?: number | null;
}

interface StoredState {
  visible: boolean;
  timestamp: number;
}

export const useLocalVisibility = (
  storageKey: string,
  options: VisibilityOptions = {},
) => {
  const { defaultVisible = true, expireAfterDays = null } = options;

  const [visible, setVisible] = useState<boolean | null>(null);

  const getStoredState = useCallback((): StoredState | null => {
    if (typeof window === "undefined") return null;

    try {
      const stored = localStorage.getItem(storageKey);
      return stored ? JSON.parse(stored) : null;
    } catch (error) {
      console.error("Error reading visibility from localStorage:", error);
      return null;
    }
  }, [storageKey]);

  const saveState = useCallback(
    (isVisible: boolean): void => {
      if (typeof window === "undefined") return;

      try {
        const state: StoredState = {
          visible: isVisible,
          timestamp: Date.now(),
        };
        localStorage.setItem(storageKey, JSON.stringify(state));
        setVisible(isVisible);
      } catch (error) {
        console.error("Error saving visibility to localStorage:", error);
      }
    },
    [storageKey],
  );

  const checkVisibility = useCallback((): boolean => {
    if (typeof window === "undefined") return defaultVisible;

    const storedState = getStoredState();

    if (!storedState) {
      return defaultVisible;
    }

    if (!storedState.visible && expireAfterDays !== null) {
      const expirationMs = expireAfterDays * 24 * 60 * 60 * 1000;
      const expirationTime = storedState.timestamp + expirationMs;

      if (Date.now() > expirationTime) {
        saveState(true);
        return true;
      }
    }

    return storedState.visible;
  }, [defaultVisible, expireAfterDays, getStoredState, saveState]);

  const hide = useCallback((): void => {
    saveState(false);
  }, [saveState]);

  const show = useCallback((): void => {
    saveState(true);
  }, [saveState]);

  const toggle = useCallback((): void => {
    if (visible === null) {
      saveState(!defaultVisible);
    } else {
      saveState(!visible);
    }
  }, [visible, defaultVisible, saveState]);

  const reset = useCallback((): void => {
    if (typeof window === "undefined") return;

    try {
      localStorage.removeItem(storageKey);
      setVisible(defaultVisible);
    } catch (error) {
      console.error("Error resetting visibility in localStorage:", error);
    }
  }, [storageKey, defaultVisible]);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const isItemVisible = checkVisibility();
    setVisible(isItemVisible);

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === storageKey) {
        setVisible(checkVisibility());
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [storageKey, checkVisibility]);

  return {
    visible: visible === null ? defaultVisible : visible,
    isVisible: visible === null ? defaultVisible : visible,
    hide,
    show,
    toggle,
    reset,
  };
};
