import type React from "react";
import { useEffect, useRef, useState } from "react";
import { Icon } from "../../icons";
import { cn } from "../../../lib/utils";

export interface EditableHeadingProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  autofocus?: boolean;
  className?: string;
}

export const EditableHeading = ({
  value,
  onChange,
  placeholder = "Untitled",
  autofocus,
  className,
}: EditableHeadingProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const headingRef = useRef<HTMLHeadingElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLHeadingElement>) => {
    if (e.key === "Enter" && headingRef.current) {
      e.preventDefault();
      headingRef.current.blur();
    }
  };

  const handleInput = (e: React.FormEvent<HTMLHeadingElement>) => {
    if (e.currentTarget.textContent) {
      onChange(e.currentTarget.textContent);
    }
  };

  const focusInput = () => {
    if (headingRef.current) {
      headingRef.current.focus();
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(headingRef.current);
      range.collapse(false);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
  };

  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLHeadingElement>) => {
    handleInput(e);
    setIsEditing(false);
  };

  useEffect(() => {
    if (autofocus) {
      focusInput();
    }
  }, []);

  return (
    <div className="flex gap-1.5 items-center text-content-1 cursor-text">
      <h1
        ref={headingRef}
        contentEditable
        suppressContentEditableWarning={true}
        aria-placeholder={placeholder}
        data-placeholder={placeholder}
        className={cn(
          "outline-hidden font-bold text-heading text-content relative",
          isEditing && "bg-surface-primary",
          className,
        )}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onFocus={handleFocus}
      >
        {value}
      </h1>
      {!isEditing && (
        <button onClick={focusInput}>
          <Icon type="edit" size="sm" className="text-content-1 cursor-text" />
        </button>
      )}
    </div>
  );
};
