const getInfo = () => {
  let auth0Domain;
  let auth0ClientId;
  let orgId;
  let rawOrgId;
  let apiURL;
  let appURL;
  let postHogAPIHost;
  let postHogUIHost;
  let postHogProjectAPIKey;
  const intercomAppId = "hxtegiyh";

  if (process.env.NODE_ENV === "production") {
    auth0Domain = "auth.capsa.ai";
    auth0ClientId = "1rmALf6rxrp76MJFuyWl99P54HC7Wpzo";
    const parts = window.location.hostname.split(".");
    rawOrgId = parts[0];
    orgId = parts[0].split("-")[0];
    if (parts.length === 3 && parts[1] === "capsa") {
      // Is external app
      appURL = `https://${rawOrgId}.capsa.ai`;
      apiURL = `https://api.${rawOrgId}.capsa.ai`;
      postHogAPIHost = `https://php.${rawOrgId}.capsa.ai`;
    } else if (parts.length === 4 && parts[2] === "capsa") {
      // Is internal app
      appURL = `https://${rawOrgId}.app.capsa.ai`;
      apiURL = `https://api.${rawOrgId}.app.capsa.ai`;
      postHogAPIHost = `https://php.${rawOrgId}.app.capsa.ai`;
    } else {
      throw new Error("Invalid hostname.");
    }
    postHogUIHost = "https://eu.posthog.com";
    postHogProjectAPIKey = "phc_uVIMiATd8qmrWXggcMRZveSZVFRvPOdz8eHyFNIvvpO";
  } else {
    auth0Domain = "dev-4naxutuw680r5h8t.eu.auth0.com";
    auth0ClientId = "9yu1GR1QGDamNwHYJRuThNVGKu2aoHkC";
    orgId = "capsaai";
    apiURL = "http://localhost:8080";
    appURL = "http://localhost:3000";
    postHogAPIHost = "https://eu.posthog.com";
    postHogUIHost = undefined;
    postHogProjectAPIKey = "phc_uVIMiATd8qmrWXggcMRZveSZVFRvPOdz8eHyFNIvvpO";
  }
  return {
    auth0Domain,
    auth0ClientId,
    orgId,
    apiURL,
    appURL,
    postHogAPIHost,
    postHogUIHost,
    postHogProjectAPIKey,
    intercomAppId,
  };
};

const info = getInfo();

export default info;
