import React, { useEffect, useMemo, useRef, useState } from "react";
import { cn } from "../../../../lib/utils";
import {
  Button,
  Citation,
  Divider,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../ui";
import { Slot, SlotProps } from "@radix-ui/react-slot";
import * as HoverCardPrimitive from "@radix-ui/react-hover-card";
import { AnimationProps, motion } from "framer-motion";
import { PopoverContentProps } from "@radix-ui/react-popover";

export interface WidgetEntryProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  index: number;
  citations?: Citation[];
  onSourceClick?: (source: Citation) => void;
  onCopy?: () => void;
  editablePopover?: (props: { onClose: () => void }) => React.ReactNode;
  editablePopoverProps?: PopoverContentProps;
  refreshing?: boolean;
  widgetRefreshing?: boolean;
  focused?: boolean;
}

const AnimatedSlot = motion(Slot) as React.ForwardRefExoticComponent<
  SlotProps & React.RefAttributes<HTMLElement> & AnimationProps
>;

export const WidgetEntry = React.forwardRef<HTMLElement, WidgetEntryProps>(
  (
    {
      className,
      index,
      citations,
      onSourceClick,
      onCopy,
      editablePopover,
      editablePopoverProps,
      refreshing,
      widgetRefreshing,
      focused,
      children,
      ...props
    },
    ref,
  ) => {
    const [toolsOpen, setToolsOpen] = useState(false);
    const elementRef = useRef(null);
    const targetRef = (ref || elementRef) as React.RefObject<HTMLDivElement>;
    const [editPopoverOpen, setEditPopoverOpen] = useState(false);

    useEffect(() => {
      const parentContainer = targetRef.current?.closest(".widget-content");
      if (parentContainer) {
        parentContainer.addEventListener("scroll", () => {
          setToolsOpen(false);
        });
      }
    }, []);

    const tools = useMemo(() => {
      const enabled = [];

      if (citations && citations.length > 0) {
        enabled.push(
          <div className="flex">
            {citations.map((citation) => {
              const label =
                "fileName" in citation.searchResult
                  ? citation.searchResult.fileName
                  : citation.searchResult.url;
              return (
                <Tooltip key={citation.searchResult.id}>
                  <TooltipTrigger asChild>
                    <Button
                      size="sm"
                      variant="inverted"
                      flat
                      className="font-normal [&>div]:px-1"
                      onClick={() =>
                        onSourceClick ? onSourceClick(citation) : {}
                      }
                    >
                      <span>
                        {index}.{citation.citationNumber}
                      </span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{label}</TooltipContent>
                </Tooltip>
              );
            })}
          </div>,
        );
      }

      if (onCopy) {
        enabled.push(
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                iconLeft="copy"
                size="sm"
                variant="inverted"
                flat
                onClick={onCopy}
              />
            </TooltipTrigger>
            <TooltipContent>Copy</TooltipContent>
          </Tooltip>,
        );
      }

      if (editablePopover && !refreshing && !widgetRefreshing) {
        enabled.push(
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                iconLeft="edit"
                size="sm"
                variant="inverted"
                flat
                onClick={() => {
                  setToolsOpen(false);
                  setEditPopoverOpen(true);
                }}
              />
            </TooltipTrigger>
            <TooltipContent>Edit</TooltipContent>
          </Tooltip>,
        );
      }

      return enabled;
    }, [citations, onCopy, editablePopover, refreshing, widgetRefreshing]);

    return (
      <Popover
        open={editPopoverOpen}
        onOpenChange={(state) =>
          editablePopover
            ? refreshing || widgetRefreshing
              ? setEditPopoverOpen(false)
              : setEditPopoverOpen(state)
            : undefined
        }
      >
        <HoverCardPrimitive.Root
          openDelay={250}
          closeDelay={100}
          onOpenChange={setToolsOpen}
          open={toolsOpen}
        >
          <PopoverTrigger asChild>
            <HoverCardPrimitive.Trigger asChild>
              <AnimatedSlot
                initial={{ opacity: 0, y: 10, filter: "blur(3px)" }}
                animate={{ opacity: 1, y: 0, filter: "blur(0px)" }}
                transition={{ duration: 0.5, ease: "easeOut" }}
                ref={targetRef as React.RefObject<HTMLElement>}
                className={cn(
                  "p-1 rounded transition-colors bg-surface hover:bg-surface-1 cursor-default",
                  toolsOpen &&
                    tools.length > 0 &&
                    !refreshing &&
                    !widgetRefreshing &&
                    !focused &&
                    !editPopoverOpen &&
                    "bg-surface-1",
                  refreshing && "animate-pulse text-content-1",
                  (focused || editPopoverOpen) &&
                    "!bg-surface-primary text-content-primary [&>span]:text-content-primary",
                  className,
                )}
                {...props}
              >
                {children}
              </AnimatedSlot>
            </HoverCardPrimitive.Trigger>
          </PopoverTrigger>
          {editablePopover && !refreshing && !widgetRefreshing && (
            <PopoverContent
              side="top"
              align="center"
              className="w-fit min-w-[var(--radix-popover-trigger-width)]"
              sideOffset={6}
              hideWhenDetached={true}
              collisionBoundary={targetRef.current?.closest(".widget")}
              collisionPadding={10}
              withArrow={true}
              {...editablePopoverProps}
            >
              {editablePopover({
                onClose: () => setEditPopoverOpen(false),
              })}
            </PopoverContent>
          )}
          {tools.length > 0 && !focused && !editPopoverOpen && (
            <HoverCardPrimitive.Portal>
              <HoverCardPrimitive.Content
                side="top"
                align="end"
                sideOffset={4}
                className={cn(
                  "z-50 flex-center gap-0.5 rounded-md border bg-surface-inverted p-0.5 text-content outline-hidden animate-in slide-in-from-bottom-1 duration-300",

                  className,
                )}
                hideWhenDetached={true}
                collisionBoundary={targetRef.current?.closest(".widget")}
              >
                {tools.map((tool, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && (
                      <Divider
                        direction="vertical"
                        className="h-4 opacity-30"
                      />
                    )}
                    {tool}
                  </React.Fragment>
                ))}
              </HoverCardPrimitive.Content>
            </HoverCardPrimitive.Portal>
          )}
        </HoverCardPrimitive.Root>
      </Popover>
    );
  },
);
WidgetEntry.displayName = "WidgetEntry";
