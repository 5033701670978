import type React from "react";
import { cn } from "../../../lib/utils";

export const Skeleton = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        "animate-pulse rounded bg-surface-1 mix-blend-multiply",
        className,
      )}
      {...props}
    />
  );
};
