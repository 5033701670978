import { withVariants } from "../../../lib/withVariants";

const styles = withVariants({
  slots: {
    treeStyle: "",
    itemStyle: "clickable border-b shrink-0 last:border-b-0 items-stretch",
    contentStyle:
      "bg-surface px-2 flex gap-2 shrink-0 transition items-stretch overflow-hidden",
    labelStyle: "text-body py-3 flex items-center gap-2 shrink-0 flex-1",
    chevronStyle:
      "text-content-1 focus-outer transition duration-75 ease-in-out rounded shrink-0",
    textStyle: "flex-1 truncate",
    iconStyle: "text-content-1 shrink-0",
  },
  variants: {
    isExpanded: {
      true: {
        chevronStyle: "transform rotate-90",
      },
    },
    isSelected: {
      true: {
        contentStyle: "bg-surface-primary-subtle",
        iconStyle: "text-content-primary",
      },
    },
  },
});

export default styles;
