import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { combine } from "zustand/middleware";
import { subscribeWithSelector } from "zustand/middleware";
import { WidgetCitation } from "@/types/widgets";
import { FileViewerParams } from "@/components/FileViewer";

const initialState = {
  isOpen: false,
  source: undefined as WidgetCitation | undefined,
  fileViewerParams: {
    fileId: null,
    pageNumber: null,
    websiteId: null,
    webpageId: null,
    waiting: false,
  } as FileViewerParams,
};

export const useSourceViewerStore = create(
  devtools(
    subscribeWithSelector(
      combine(initialState, (set) => ({
        reset: () => set(initialState),

        openSourceViewer: () => set(() => ({ isOpen: true })),
        closeSourceViewer: () => set(() => ({ isOpen: false })),

        setSource: (source: WidgetCitation) => {
          if (source) {
            if (source.searchResult.type === "file") {
              set({
                fileViewerParams: {
                  fileId: source.searchResult.fileId,
                  pageNumber: source.searchResult.pageNumber,
                  websiteId: null,
                  webpageId: null,
                  waiting: true,
                },
              });
            } else if (source.searchResult.type === "webpage") {
              set({
                fileViewerParams: {
                  fileId: null,
                  pageNumber: null,
                  websiteId: source.searchResult.websiteId,
                  webpageId: source.searchResult.webpageId,
                  waiting: true,
                },
              });
            }
          } else {
            set({
              fileViewerParams: {
                fileId: null,
                pageNumber: null,
                websiteId: null,
                webpageId: null,
                waiting: true,
              },
            });
          }

          set({ source });
        },
        resetSource: () => set({ source: undefined }),

        setFileViewerParams: (params: FileViewerParams) => {
          set({ fileViewerParams: params });
        },
      })),
    ),
    {
      name: "SourceViewerStore",
    },
  ),
);

useSourceViewerStore.subscribe(
  (state) => state.source,
  (source) => {
    const { openSourceViewer } = useSourceViewerStore.getState();
    if (source) {
      openSourceViewer();
    }
  },
);

useSourceViewerStore.subscribe(
  (state) => state.isOpen,
  (isOpen) => {
    const { resetSource } = useSourceViewerStore.getState();
    if (!isOpen) {
      resetSource();
    }
  },
);
