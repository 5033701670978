import { useState } from "react";
import { Wizard } from "react-use-wizard";
import ArrangeTablesPage from "./ArrangeTablesPage";
import SelectFilesPage, { SelectedFile } from "./SelectedFilesPage";
import ConfigureLayoutPrediction, {
  LayoutPredictionConfig,
} from "./ConfigureLayoutPrediction";
import { useValidatedParams } from "@/utils/router";
import { Main } from "@/components/layout/Main";

export default function TableAggregationsCreate() {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  const [layoutPredictionConfig, setLayoutPredictionConfig] =
    useState<LayoutPredictionConfig>({
      periodsPerFile: "one",
      tablesPerPeriod: "multiple",
    });
  return (
    <Main>
      <Wizard>
        <SelectFilesPage
          orgId={orgId}
          dealId={dealId}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
        <ConfigureLayoutPrediction
          orgId={orgId}
          dealId={dealId}
          layoutPredictionConfig={layoutPredictionConfig}
          setLayoutPredictionConfig={setLayoutPredictionConfig}
        />
        <ArrangeTablesPage
          orgId={orgId}
          dealId={dealId}
          selectedFiles={selectedFiles}
          periodsPerFile={layoutPredictionConfig.periodsPerFile}
          tablesPerPeriod={layoutPredictionConfig.tablesPerPeriod}
        />
      </Wizard>
    </Main>
  );
}
