import { Dialog, DialogOverlay, DialogPortal, Icon, Text } from "capsa-ui";
import { useEffect } from "react";

export const TrialEnded = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <Dialog open={true}>
      <DialogPortal>
        <DialogOverlay />
        <div className="fixed inset-0 flex justify-center z-50 p-32">
          <div className="bg-surface py-10 px-16 rounded-layout flex-center flex-col gap-2 h-fit">
            <div className="flex-center gap-2.5">
              <Icon type="capsa" size="lg" className="text-content-primary" />
              <Text type="hero">Your trial has ended.</Text>
            </div>
            <Text>
              Please{" "}
              <a href="mailto:danyal@capsa.ai" className="text-content-primary">
                contact us
              </a>{" "}
              to continue using Capsa AI.
            </Text>
          </div>
        </div>
      </DialogPortal>
    </Dialog>
  );
};
