import { Toaster as Sonner, toast as sonnerToast } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

export const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      className="toaster group"
      closeButton={props.closeButton ?? true}
      toastOptions={{
        classNames: {
          toast:
            "group toast !bg-surface-inverted text-content-inverted shadow-popover text-body !border",
          content: "mr-auto",
          title: "!text-content-inverted !font-medium",
          description: "!text-content-inverted opacity-75 !font-normal",
          actionButton:
            "!bg-surface-primary-accent !text-content-inverted !rounded !text-body !px-3 !py-4 !font-bold !m-0",
          cancelButton:
            "!bg-default !text-content !rounded !text-body !px-3 !py-4 !font-medium !text-content-1 !m-0",
          closeButton: "!bg-surface-inverted !text-content-inverted !border",
        },
      }}
      {...props}
    />
  );
};

export const toast = sonnerToast;
