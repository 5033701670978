import { MarkdownParams } from "../../types";

interface MarkdownFormProps {
  params: MarkdownParams;
  onChange: (params: MarkdownParams) => void;
}

const MarkdownForm: React.FC<MarkdownFormProps> = ({ params, onChange }) => {
  const handleChangeUserMessage = (userMessage: string) => {
    onChange({
      ...params,
      userMessage: userMessage,
    });
  };

  const handleChangeAvailableTools = (
    availableTools: ("search_sources" | "search_web" | "search_web_deeply")[],
  ) => {
    onChange({
      ...params,
      availableTools: availableTools,
    });
  };

  // Toggle the given tool in the availableTools list.
  const toggleTool = (
    tool: "search_sources" | "search_web" | "search_web_deeply",
  ) => {
    const isSelected = params.availableTools.includes(tool);
    const newTools = isSelected
      ? params.availableTools.filter((t) => t !== tool)
      : [...params.availableTools, tool];
    handleChangeAvailableTools(newTools);
  };

  const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  return (
    <div className="space-y-4">
      <textarea
        value={params.userMessage}
        onChange={(e) => {
          handleChangeUserMessage(e.target.value);
          adjustTextareaHeight(e.target);
        }}
        onFocus={(e) => adjustTextareaHeight(e.target)}
        rows={1}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm
              focus:border-blue-500 focus:ring-blue-500"
        style={{ minHeight: "2.5rem", overflow: "hidden" }}
      />

      <div className="space-y-2">
        <div>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={params.availableTools.includes("search_sources")}
              onChange={() => toggleTool("search_sources")}
              className="rounded border-gray-300 text-blue-500 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            <span className="ml-2">Search Sources</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={params.availableTools.includes("search_web")}
              onChange={() => toggleTool("search_web")}
              className="rounded border-gray-300 text-blue-500 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            <span className="ml-2">Search Web</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={params.availableTools.includes("search_web_deeply")}
              onChange={() => toggleTool("search_web_deeply")}
              className="rounded border-gray-300 text-blue-500 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            <span className="ml-2">Search Web Deeply</span>
          </label>
        </div>
      </div>
      <div className="space-y-2">
        <div className="flex flex-col">
          <label className="mb-1">Minimum Iterations</label>
          <input
            type="number"
            value={params.minIterations}
            onChange={(e) =>
              onChange({
                ...params,
                minIterations: parseInt(e.target.value),
              })
            }
            className="w-24 rounded border-gray-300 text-blue-500 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
      </div>
      <div className="space-y-2">
        <div className="flex flex-col">
          <label className="mb-1">Maximum Iterations</label>
          <input
            type="number"
            value={params.maxIterations}
            onChange={(e) =>
              onChange({
                ...params,
                maxIterations: parseInt(e.target.value),
              })
            }
            className="w-24 rounded border-gray-300 text-blue-500 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
      </div>
    </div>
  );
};

export default MarkdownForm;
