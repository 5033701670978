import { MatchParam } from "@/types/router";

const getOrgIdURL = (orgId: Param) => `/orgs/${orgId}`;
const getDealIdURL = (orgId: Param, dealId: Param) =>
  `/orgs/${orgId}/deals/${dealId}`;

type Param = string | undefined;

export const paths = {
  root: {
    path: "/",
    getHref: () => "/",
  },

  auth: {
    login: {
      path: "/authenticate",
      getHref: () => "/authenticate",
    },
    terms: {
      path: "/terms-and-conditions",
      getHref: () => "/terms-and-conditions",
    },
    privacy: {
      path: "/privacy-policy",
      getHref: () => "/privacy-policy",
    },
    verify: {
      path: "/verify-email",
      getHref: () => "/verify-email",
    },
  },

  orgs: {
    path: "/orgs",
    getHref: () => "/orgs",
    selected: {
      path: `/orgs/${MatchParam.orgId}`,
      getHref: (orgId: Param) => getOrgIdURL(orgId),
      deals: {
        path: "deals",
        getHref: (orgId: Param) => `${getOrgIdURL(orgId)}/deals`,
        save: {
          path: "save",
          getHref: (orgId: Param) => `${getOrgIdURL(orgId)}/deals/save`,
        },
        create: {
          path: "create",
          getHref: (orgId: Param) => `${getOrgIdURL(orgId)}/deals/create`,
        },
        selected: {
          path: MatchParam.dealId,
          getHref: (orgId: Param, dealId: Param) => getDealIdURL(orgId, dealId),
          update: {
            path: "update",
            getHref: (orgId: Param, dealId: Param) =>
              `${getOrgIdURL(orgId)}/deals/${dealId}/update`,
          },
          files: {
            path: "files",
            getHref: (orgId: Param, dealId: Param) =>
              `${getDealIdURL(orgId, dealId)}/files`,
            selected: {
              path: MatchParam.fileId,
              getHref: (orgId: Param, dealId: Param, fileId: Param) =>
                `${getDealIdURL(orgId, dealId)}/files/${fileId}`,
            },
          },
          dashboards: {
            path: "dashboards",
            getHref: (orgId: Param, dealId: Param) =>
              `${getDealIdURL(orgId, dealId)}/dashboards`,
            current: {
              path: "dashboards/current", // TODO: change this to be dynamic when we allow dashboard configuration
              getHref: (orgId: Param, dealId: Param) =>
                `${getDealIdURL(orgId, dealId)}/dashboards/current`,
            },
            selected: {
              path: `dashboards/${MatchParam.dashboardId}`,
              getHref: (orgId: Param, dealId: Param, dashboardId: Param) =>
                `${getDealIdURL(orgId, dealId)}/dashboards/${dashboardId}`,
            },
          },
          websites: {
            path: "websites",
            getHref: (orgId: Param, dealId: Param) =>
              `${getDealIdURL(orgId, dealId)}/websites`,
            selected: {
              path: MatchParam.websiteId,
              getHref: (orgId: Param, dealId: Param, websiteId: Param) =>
                `${getDealIdURL(orgId, dealId)}/websites/${websiteId}`,
            },
            create: {
              path: "create",
              getHref: (orgId: Param, dealId: Param) =>
                `${getDealIdURL(orgId, dealId)}/websites/create`,
            },
          },
          tableAggregations: {
            path: "table-aggregations",
            getHref: (orgId: Param, dealId: Param) =>
              `${getDealIdURL(orgId, dealId)}/table-aggregations`,
            create: {
              path: "create",
              getHref: (orgId: Param, dealId: Param) =>
                `${getDealIdURL(orgId, dealId)}/table-aggregations/create`,
            },
            selected: {
              path: MatchParam.tableAggregationId,
              getHref: (
                orgId: Param,
                dealId: Param,
                tableAggregationId: Param,
              ) =>
                `${getDealIdURL(orgId, dealId)}/table-aggregations/${tableAggregationId}`,
              update: {
                path: "update",
                getHref: (
                  orgId: Param,
                  dealId: Param,
                  tableAggregationId: Param,
                ) =>
                  `${getDealIdURL(orgId, dealId)}/table-aggregations/${tableAggregationId}/update`,
              },
            },
          },
          registrySearchJobs: {
            path: "registry-search-jobs",
            getHref: (orgId: Param, dealId: Param) =>
              `${getDealIdURL(orgId, dealId)}/registry-search-jobs`,
            create: {
              path: "create",
              getHref: (orgId: Param, dealId: Param) =>
                `${getDealIdURL(orgId, dealId)}/registry-search-jobs/create`,
            },
          },
          search: {
            path: "search",
            getHref: (orgId: Param, dealId: Param) =>
              `${getDealIdURL(orgId, dealId)}/search`,
          },
          users: {
            path: "users",
            getHref: (orgId: Param, dealId: Param) =>
              `${getDealIdURL(orgId, dealId)}/users`,
            create: {
              path: "create",
              getHref: (orgId: Param, dealId: Param) =>
                `${getDealIdURL(orgId, dealId)}/users/create`,
            },
            selected: {
              path: MatchParam.userId,
              getHref: (orgId: Param, dealId: Param, userId: Param) =>
                `${getDealIdURL(orgId, dealId)}/users/${userId}`,
              update: {
                path: "update",
                getHref: (orgId: Param, dealId: Param, userId: Param) =>
                  `${getDealIdURL(orgId, dealId)}/users/${userId}/update`,
              },
            },
          },
        },
      },
      dashboardTemplates: {
        path: "dashboard-templates",
        getHref: (orgId: Param) => `${getOrgIdURL(orgId)}/dashboard-templates`,
      },
      widgetTemplates: {
        path: "widget-templates",
        getHref: (orgId: Param) => `${getOrgIdURL(orgId)}/widget-templates`,
        selected: {
          path: MatchParam.widgetTemplateId,
          getHref: (orgId: Param, widgetTemplateId: Param) =>
            `${getOrgIdURL(orgId)}/widget-templates/${widgetTemplateId}`,
          update: {
            path: "update",
            getHref: (orgId: Param, widgetTemplateId: Param) =>
              `${getOrgIdURL(orgId)}/widget-templates/${widgetTemplateId}/update`,
          },
        },
      },
      legalEntitySearch: {
        path: "legal-entity-search",
        getHref: (orgId: Param) => `${getOrgIdURL(orgId)}/legal-entity-search`,
      },
      users: {
        path: "users",
        getHref: (orgId: Param) => `${getOrgIdURL(orgId)}/users`,
        selected: {
          path: MatchParam.userId,
          getHref: (orgId: Param, userId: Param) =>
            `${getOrgIdURL(orgId)}/users/${userId}`,
          update: {
            path: "update",
            getHref: (orgId: Param, userId: Param) =>
              `${getOrgIdURL(orgId)}/users/${userId}/update`,
          },
        },
      },
      invites: {
        path: "invites",
        getHref: (orgId: Param) => `${getOrgIdURL(orgId)}/invites`,
        create: {
          path: "create",
          getHref: (orgId: Param) => `${getOrgIdURL(orgId)}/invites/create`,
        },
        selected: {
          path: MatchParam.inviteId,
          getHref: (orgId: Param, inviteId: Param) =>
            `${getOrgIdURL(orgId)}/invites/${inviteId}`,
          update: {
            path: "update",
            getHref: (orgId: Param, inviteId: Param) =>
              `${getOrgIdURL(orgId)}/invites/${inviteId}/update`,
          },
        },
      },
      settings: {
        path: "settings",
        getHref: (orgId: Param) => `${getOrgIdURL(orgId)}/settings`,
      },
    },
  },

  users: {
    path: "/users/current",
    getHref: () => "/users/current",
    orgs: {
      path: "/users/current/orgs",
      getHref: () => "/users/current/orgs",
    },
  },
} as const;
