import { useValidatedParams } from "@/utils/router";
import { Navigate } from "react-router-dom";
import { useCurrentDashboard } from "../api/getCurrentDashboard";

export const CurrentDashboardView = () => {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const dashboard = useCurrentDashboard({ orgId, dealId });

  // If dashboard is selected and exists, render the dashboard
  if (dashboard) {
    return (
      <Navigate
        to={`/orgs/${orgId}/deals/${dealId}/dashboards/${dashboard.id}`}
      />
    );
  }

  return null;
};
