import type React from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "../../../lib/utils";

export const spinnerStyles = cva(
  "stroke-current animate-spin overflow-visible",
  {
    variants: {
      variant: {
        default: "text-content-primary",
        inverted: "text-content-inverted",
      },
      size: {
        xs: "h-icon-xs w-icon-xs",
        sm: "h-icon-sm w-icon-sm",
        default: "h-icon-md w-icon-md",
        lg: "h-icon-lg w-icon-lg",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export type SpinnerVariant = VariantProps<typeof spinnerStyles>["variant"];
export type SpinnerSize = VariantProps<typeof spinnerStyles>["size"];

export interface SpinnerProps
  extends React.ButtonHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof spinnerStyles> {}

export const Spinner = ({
  size = "lg",
  variant = "default",
  className,
}: SpinnerProps) => {
  return (
    <div aria-label="loading..." className={cn(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        className={cn(spinnerStyles({ size, variant }))}
      >
        <circle
          cx="10"
          cy="10"
          r="9"
          strokeOpacity="0.1"
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19"
          strokeWidth="2"
          strokeLinecap="round"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </div>
  );
};
