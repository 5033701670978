import React, { useMemo } from "react";
import { useWidgetTemplatesQuery } from "../hooks/useWidgetTemplates";
import { useDeleteWidgetTemplateMutation } from "../hooks/useDeleteWidgetTemplate";
import { useCreateWidgetTemplateMutation } from "../hooks/useCreateWidgetTemplate";
import { WidgetTemplate } from "../types";
import { useNavigate } from "react-router-dom";
import { paths } from "@/config/paths";

const formatContentType = (contentType: string): string => {
  return (
    contentType
      // Split by underscore and capitalize each word
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ")
  );
};

interface WidgetRowProps {
  widget: WidgetTemplate;
  orgId: string;
  onDuplicate: (widget: WidgetTemplate) => void;
  onDelete: (widgetId: string) => void;
}

function WidgetRow({ widget, orgId, onDuplicate, onDelete }: WidgetRowProps) {
  const navigate = useNavigate();

  return (
    <tr className="border-b">
      <td className="px-4 py-2 w-3/12">
        <div className="flex items-center justify-start">
          <span className="flex-grow pl-2 text-left">{widget.prettyName}</span>
        </div>
      </td>
      <td className="px-4 py-2 w-2/12">
        {formatContentType(widget.contentType)}
      </td>
      <td className="px-4 py-2 w-2/12">
        {widget.editable ? "" : "System Generated"}
      </td>
      <td className="px-4 py-2 w-2/12">
        <div className="flex items-center justify-center space-x-4">
          {widget.editable && (
            <>
              <button
                className="text-blue-500 hover:underline px-2"
                onClick={() =>
                  navigate(
                    paths.orgs.selected.widgetTemplates.selected.update.getHref(
                      orgId,
                      widget.id,
                    ),
                  )
                }
              >
                Edit
              </button>
              <span className="border-l border-gray-300 h-4" />
            </>
          )}
          <button
            className="text-blue-500 hover:underline px-2"
            onClick={() => onDuplicate(widget)}
          >
            Duplicate
          </button>
          {widget.editable && (
            <>
              <span className="border-l border-gray-300 h-4" />
              <button
                className="text-blue-500 hover:underline px-2"
                onClick={() => onDelete(widget.id)}
              >
                Delete
              </button>
            </>
          )}
        </div>
      </td>
    </tr>
  );
}

export function WidgetTable({ orgId }: { orgId: string }) {
  const { data, isLoading, error } = useWidgetTemplatesQuery(orgId);
  const createWidgetTemplateMutation = useCreateWidgetTemplateMutation();
  const deleteWidgetTemplateMutation = useDeleteWidgetTemplateMutation();

  const widgetTemplates = useMemo(() => {
    if (!data?.pages) return [];

    const templateIds = new Set<string>();

    return data.pages
      .flatMap((page) => page?.data || [])
      .filter((template) => {
        if (templateIds.has(template.id)) {
          return false;
        }
        templateIds.add(template.id);
        return true;
      });
  }, [data?.pages]);

  const handleDuplicateWidget = (widget: WidgetTemplate) => {
    createWidgetTemplateMutation.mutate({
      orgId,
      body: {
        prettyName: `${widget.prettyName} (Copy)`,
        contentGenerationParams: widget.contentGenerationParams,
      },
    });
  };

  const handleDeleteWidget = (widgetId: string) => {
    deleteWidgetTemplateMutation.mutate({
      orgId,
      widgetTemplateId: widgetId,
    });
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading widgets</div>;

  return (
    <table className="min-w-full bg-white border border-gray-200">
      <thead>
        <tr className="bg-gray-50">
          <th className="px-4 py-2 text-left">Display Name</th>
          <th className="px-4 py-2 text-left">Content Type</th>
          <th className="px-4 py-2 text-left">Type</th>
          <th className="px-4 py-2 text-center">Actions</th>
        </tr>
      </thead>
      <tbody>
        {widgetTemplates?.map((widget) => (
          <WidgetRow
            key={widget.id}
            widget={widget}
            orgId={orgId}
            onDuplicate={handleDuplicateWidget}
            onDelete={handleDeleteWidget}
          />
        ))}
      </tbody>
    </table>
  );
}
