import { Button, Icon, Spinner, Text } from "capsa-ui";
import {
  useDeleteDocumentUploadJobMutation,
  useDocumentUploadJobsQuery,
} from "@/hooks/documentUploadJobs";
import { useDealFilesQuery } from "@/hooks/deals";
import { useEffect, useState } from "react";
import { useValidatedParams } from "@/utils/router";

export enum DocumentUploadJobStatus {
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  FAILED = "failed",
}

export const DocumentUploadActiveJobs = () => {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const { data, isSuccess } = useDocumentUploadJobsQuery(
    orgId || "",
    dealId || "",
    ["pending", "in_progress", "failed"],
  );
  const filesQuery = useDealFilesQuery(orgId || "", dealId || "");
  const mutation = useDeleteDocumentUploadJobMutation(
    orgId || "",
    dealId || "",
  );
  const [removedIds, setRemovedIds] = useState<string[]>([]);

  useEffect(() => {
    filesQuery.refetch();
  }, [data]);

  if (!isSuccess) {
    return null;
  }

  return (
    <>
      {data.length > 0 && (
        <div className="flex flex-col gap-1">
          {data.map((syncJob) => {
            const status = syncJob.status;
            const totalFiles = syncJob.data.documents_to_sync.length;
            const ingestedFiles = syncJob.data.documents_synced.length;

            const handleDismiss = () => {
              mutation.mutate({
                documentUploadJobId: syncJob.id,
              });
              setRemovedIds([...removedIds, syncJob.id]);
            };

            if (removedIds.includes(syncJob.id)) {
              return null;
            }

            if (
              status === DocumentUploadJobStatus.FAILED ||
              status === DocumentUploadJobStatus.COMPLETED
            ) {
              return (
                <div
                  key={syncJob.id}
                  className="flex gap-2 items-center border rounded p-4"
                >
                  {status === DocumentUploadJobStatus.FAILED && (
                    <>
                      <Icon
                        type="alertFilled"
                        className="text-content-danger"
                      />
                      <Text type="label" color="subtle">
                        Document import failed
                      </Text>
                    </>
                  )}
                  {status === DocumentUploadJobStatus.COMPLETED && (
                    <>
                      <Icon
                        type="checkFilled"
                        className="text-content-primary"
                      />
                      <Text type="label" color="subtle">
                        Imported{" "}
                        {totalFiles > 0 && (
                          <Text type="label" weight="bold">
                            {ingestedFiles} Documents
                          </Text>
                        )}
                      </Text>
                    </>
                  )}
                  <div className="flex-1" />
                  <div className="h-0 flex-center">
                    <Button
                      onClick={handleDismiss}
                      variant="outline"
                      iconLeft="cancel"
                      size="sm"
                    />
                  </div>
                </div>
              );
            }

            return (
              <div
                key={syncJob.id}
                className="flex gap-2 items-center border rounded p-4"
              >
                <Spinner />
                {totalFiles > 0 && (
                  <Text type="label" color="subtle">
                    {`Importing Registry Files (${ingestedFiles}/${totalFiles})`}
                  </Text>
                )}
                <div className="flex-1" />
                <Icon className="text-content-1" size="sm" />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
