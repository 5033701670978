import { DocumentIcon } from "@heroicons/react/24/outline";

/**
 * Displays a message when a file is still being processed
 */
export function StillProcessing() {
  return (
    <div className="h-full w-full rounded-md flex items-center justify-center border border-gray-200">
      <div className="flex flex-col">
        <DocumentIcon className="text-gray-200 max-w-32 max-h-32" />
        <p className="text-gray-400">File still processing.</p>
      </div>
    </div>
  );
}

/**
 * Displays a message when no file or webpage is selected
 */
export function Unselected() {
  return (
    <div className="h-full w-full rounded-md flex items-center justify-center border border-gray-200">
      <div className="flex flex-col">
        <DocumentIcon className="text-gray-200 max-w-32 max-h-32" />
        <p className="text-gray-400">No reference selected.</p>
      </div>
    </div>
  );
}

/**
 * Displays an error message when a document has expired
 */
export function DocumentError() {
  return (
    <div className="h-full w-full rounded-md flex items-center justify-center border border-gray-200">
      <div className="flex flex-col">
        <DocumentIcon className="text-gray-200 max-w-32 max-h-32" />
        <p className="text-gray-400 mb-3">This document has expired.</p>
        <button
          onClick={() => window.location.reload()}
          type="button"
          className="mb-6 mx-auto rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Reload
        </button>
      </div>
    </div>
  );
}
