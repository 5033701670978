import { useWebsiteQuery } from "../../../hooks/websites";
import { Button } from "capsa-ui";
import { useValidatedParams } from "@/utils/router";
import { Main } from "@/components/layout/Main";

function downloadFromURL(url: string) {
  if (process.env.NODE_ENV !== "production") {
    url = url.replace("http://storage", "http://localhost");
  }
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.click();
}

export default function Website() {
  const { orgId, dealId, websiteId } = useValidatedParams([
    "orgId",
    "dealId",
    "websiteId",
  ]);
  const websiteQuery = useWebsiteQuery(orgId, dealId, websiteId);

  return (
    <Main title="Website">
      <>
        {websiteQuery.isLoading && <div>Loading...</div>}
        {websiteQuery.isError && <div>Error loading website.</div>}
        {websiteQuery.isSuccess && (
          <div className="space-y-2 mt-2">
            <div>
              <h1 className="text-sm font-semibold">URL:</h1>
              <p className="text-sm">{websiteQuery.data.url}</p>
            </div>
            <div>
              <h1 className="text-sm font-semibold">
                Number of Items Ingested:
              </h1>
              <p className="text-sm">{websiteQuery.data.items_ingested}</p>
            </div>
            <div>
              <h1 className="text-sm font-semibold">Status:</h1>
              <p className="text-sm">{websiteQuery.data.ingestion_status}</p>
            </div>
            {websiteQuery.data.download_url && (
              <div>
                <Button
                  onClick={() => {
                    if (websiteQuery.data.download_url) {
                      downloadFromURL(websiteQuery.data.download_url);
                    }
                  }}
                >
                  <p>Download</p>
                </Button>
              </div>
            )}
          </div>
        )}
      </>
    </Main>
  );
}
