import {
  Button,
  MultiSelect,
  cn,
  TextArea,
  Toggle,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "capsa-ui";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useCreateThread } from "../api/createThread";
import { useValidatedParams } from "@/utils/router";
import { useDealFilesQuery } from "@/hooks/deals";
import { useWebsitesQuery } from "@/hooks/websites";
import { useSendMessage } from "../api/sendMessage";
import { useThread } from "../api/getThread";
import { useAssistantStore } from "../store/useAssistantStore";
import { useConfigureThread } from "../api/configureThread";
import { usePostHog } from "posthog-js/react";
import { ThreadConfig } from "../types/api";
import { useGlobalStore } from "@/store";

const TOGGLE_COLLAPSE_WIDTH = 450;

export const ChatInput = () => {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const filesQuery = useDealFilesQuery(orgId, dealId);
  const websitesQuery = useWebsitesQuery(orgId, dealId);
  const { superAdminReviewModeEnabled } = useGlobalStore();

  const [formSubmitting, setFormSubmitting] = useState(false);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [inputWidth, setInputWidth] = useState(0);
  const [input, setInput] = useState("");
  const [focused, setFocused] = useState(false);
  const createThread = useCreateThread({
    orgId,
  });
  const {
    threadId,
    setThreadId,
    resetThreadId,
    context,
    threadStatus,
    setThreadStatus,
  } = useAssistantStore();

  const sendMessage = useSendMessage({
    orgId,
    threadId,
  });
  const configureThread = useConfigureThread({
    orgId,
    threadId,
  });
  const thread = useThread({
    orgId,
    threadId,
    mode: superAdminReviewModeEnabled ? "org" : "user",
  });

  const DEFAULT_THREAD_CONFIG = {
    search_web_enabled: false,
    search_web_deeply_enabled: false,
    file_ids: null,
    website_ids: null,
  };
  const [initThreadConfig, setInitThreadConfig] = useState<ThreadConfig>(
    DEFAULT_THREAD_CONFIG,
  );

  useEffect(() => {
    setInitThreadConfig(DEFAULT_THREAD_CONFIG);
  }, [orgId, dealId]);

  const postHog = usePostHog();

  useEffect(() => {
    if (inputRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setInputWidth(entry.contentRect.width);
        }
      });

      resizeObserver.observe(inputRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [inputRef.current]);

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    try {
      e?.preventDefault();

      if (!context) {
        throw new Error("No assistant thread context.");
      }

      if (!threadId) {
        const newThread = await createThread.mutateAsync({
          message: input,
          config: initThreadConfig,
          context: [context],
        });
        setThreadId(newThread.id);
      } else {
        setThreadStatus("processing");
        sendMessage.mutateAsync({
          message: input,
          context: [context],
        });
      }
      setInitThreadConfig({
        search_web_enabled: false,
        search_web_deeply_enabled: false,
        file_ids: null,
        website_ids: null,
      });
      setInput("");
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitOnce = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (formSubmitting) return;
    setFormSubmitting(true);
    await handleSubmit(e);
    setFormSubmitting(false);
  };

  const thinking = useMemo(() => {
    return threadStatus !== "ready";
  }, [threadStatus]);

  const thisThreadConfig = useMemo<ThreadConfig>(() => {
    return threadId && thread.data?.state.config !== undefined
      ? thread.data.state.config
      : initThreadConfig;
  }, [
    threadId,
    thread.data,
    initThreadConfig.search_web_enabled,
    initThreadConfig.search_web_deeply_enabled,
    initThreadConfig.file_ids,
    initThreadConfig.website_ids,
  ]);

  const handleSearchWebToggle = async () => {
    if (threadId) {
      configureThread.mutateAsync({
        ...thisThreadConfig,
        search_web_enabled: !thisThreadConfig.search_web_enabled,
        search_web_deeply_enabled: false,
      });
    } else {
      setInitThreadConfig(() => ({
        ...thisThreadConfig,
        search_web_enabled: !thisThreadConfig.search_web_enabled,
        search_web_deeply_enabled: false,
      }));
    }
  };

  const handleDeepSearchWebToggle = async () => {
    if (threadId) {
      configureThread.mutateAsync({
        ...thisThreadConfig,
        search_web_enabled: false,
        search_web_deeply_enabled: !thisThreadConfig.search_web_deeply_enabled,
      });
    } else {
      setInitThreadConfig(() => ({
        ...thisThreadConfig,
        search_web_enabled: false,
        search_web_deeply_enabled: !thisThreadConfig.search_web_deeply_enabled,
      }));
    }
  };

  const filterableSources = useMemo(() => {
    const filesList =
      filesQuery.data?.map((item) => ({
        value: item.id,
        label: item.name,
        type: "file",
      })) || [];
    const websitesList =
      websitesQuery.data?.map((item) => ({
        value: item.id,
        label: item.url.replace("https://", "").replace(/\/$/, ""),
        type: "website",
      })) || [];
    return [...filesList, ...websitesList];
  }, [filesQuery.data, websitesQuery.data]);

  const handleFilterChange = async (ids: string[]) => {
    try {
      const sources = ids
        .map((id) => filterableSources.find((source) => source.value === id))
        .filter((source) => !!source);
      const fileIds = sources
        .filter((source) => source && source?.type === "file")
        .map((source) => source?.value);
      const websiteIds =
        sources
          .filter((source) => source?.type === "website")
          .map((source) => source?.value) || [];

      if (threadId) {
        configureThread.mutateAsync({
          ...thisThreadConfig,
          file_ids: fileIds,
          website_ids: websiteIds,
        });
      } else {
        setInitThreadConfig(() => ({
          ...thisThreadConfig,
          file_ids: fileIds,
          website_ids: websiteIds,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filteredSources = useMemo(() => {
    const fileIds = thisThreadConfig.file_ids || [];
    const websiteIds = thisThreadConfig.website_ids || [];
    return [...fileIds, ...websiteIds];
  }, [thisThreadConfig.file_ids, thisThreadConfig.website_ids]);

  return (
    <form
      className="flex flex-col gap-2 px-3 shrink-0"
      onSubmit={handleSubmitOnce}
    >
      <div
        className={cn(
          "flex flex-col gap-0 border rounded-layout overflow-hidden cursor-text bg-surface",
          focused && "border-edge-primary shadow-focus",
        )}
      >
        <TextArea
          name="message"
          inputRef={inputRef}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={"Ask Capsa AI..."}
          className={cn("relative")}
          inputClassName={cn(
            "resize-none min-h-0 focus:outline-none focus:ring-0 !shadow-none !border-transparent bg-transparent",
          )}
          autoFocus
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey && !thinking) {
              e.preventDefault();
              handleSubmitOnce();
            }
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={superAdminReviewModeEnabled}
          autoSize
          maxHeight={300}
        />
        <div className="flex justify-end gap-0.5 p-2">
          {threadId && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  type="reset"
                  variant="outline"
                  iconLeft="arrowLeft"
                  onClick={() => resetThreadId()}
                  flat
                  size="sm"
                />
              </TooltipTrigger>
              <TooltipContent>Back</TooltipContent>
            </Tooltip>
          )}
          {postHog.isFeatureEnabled("search_web") && (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="relative focus-within:z-[1]">
                  <Toggle
                    iconLeft="website"
                    pressed={thisThreadConfig.search_web_enabled}
                    type="reset"
                    onPressedChange={handleSearchWebToggle}
                    size="sm"
                    disabled={superAdminReviewModeEnabled}
                  >
                    {inputWidth > TOGGLE_COLLAPSE_WIDTH ? "Web Search" : ""}
                  </Toggle>
                </span>
              </TooltipTrigger>
              <TooltipContent>
                {`${thisThreadConfig.search_web_enabled ? "Turn off" : "Turn on"} web search`}
              </TooltipContent>
            </Tooltip>
          )}
          {postHog.isFeatureEnabled("search_web_deeply") && (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="relative focus-within:z-[1]">
                  <Toggle
                    iconLeft="network"
                    pressed={thisThreadConfig.search_web_deeply_enabled}
                    type="reset"
                    onPressedChange={handleDeepSearchWebToggle}
                    size="sm"
                    disabled={superAdminReviewModeEnabled}
                  >
                    {inputWidth > TOGGLE_COLLAPSE_WIDTH
                      ? "Deep Web Search"
                      : ""}
                  </Toggle>
                </span>
              </TooltipTrigger>
              <TooltipContent>
                {`${thisThreadConfig.search_web_deeply_enabled ? "Turn off" : "Turn on"} deep web search`}
              </TooltipContent>
            </Tooltip>
          )}
          <Tooltip>
            <TooltipTrigger asChild>
              <MultiSelect
                key="filters"
                options={filterableSources}
                onValueChange={handleFilterChange}
                updateOnSave
                selected={filteredSources}
                variant="outline"
                label="Sources"
                optionsLabel="Sources"
                message="Select sources for the Assistant to consider, or leave empty to use all available."
                hasReset
                flat={true}
                popoverContentProps={{
                  side: "bottom",
                  align: "end",
                  className: "min-w-[350px] p-0",
                }}
                size="sm"
                iconLeft="documents"
                disabled={superAdminReviewModeEnabled}
              />
            </TooltipTrigger>
            <TooltipContent>Filter sources</TooltipContent>
          </Tooltip>
          <div className="flex-1" onClick={() => inputRef.current?.focus()} />
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                iconRight="chevronRight"
                type="submit"
                disabled={
                  !input.trim() || thinking || superAdminReviewModeEnabled
                }
                flat
                size="sm"
              />
            </TooltipTrigger>
            <TooltipContent>Send message</TooltipContent>
          </Tooltip>
        </div>
      </div>
    </form>
  );
};
