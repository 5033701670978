import { TimeSeries } from "@/types/widgets";
import {
  cn,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  TextField,
  WidgetEntry,
  WidgetRenderProps,
  Button,
  Combobox,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "capsa-ui";
import { useEffect, useMemo, useState } from "react";
import {
  getTableRowUnit,
  TableDict,
  timeSeriesTransformer,
  updateTimeSeries,
} from "../../utils/timeSeries";
import { useValidatedParams } from "@/utils/router";
import { formatNumber } from "@/utils/units";
import { additionalCurrencyList, commonCurrencyList } from "@/utils/currency";
import { useUpdateWidget } from "../../api/updateWidget";

interface Props extends WidgetRenderProps {
  content: TimeSeries[];
  widgetId: string;
}

export const WidgetContentTable = ({
  content,
  onSource,
  setClipboard,
  widgetId,
  refreshing,
}: Props) => {
  const { headers, rows, columns, dataDict } = useMemo(
    () => timeSeriesTransformer(content),
    [content],
  );

  useEffect(() => {
    if (!setClipboard) return;

    const html = `<table><tbody>${Object.entries(dataDict)
      .map(([row, items]) => {
        const rowUnit = items.find((item) => item.value.unit)?.value.unit;
        const formattedRow = rowUnit
          ? `${row} (${getTableRowUnit(rowUnit)})`
          : row;
        return `<tr><td><b>${formattedRow}</b></td>${items
          .slice(1)
          .map((item) => `<td>${item.value.trueValue}</td>`)
          .join("")}</tr>`;
      })
      .join("")}</tbody></table>`;

    const clipboardItem = new ClipboardItem({
      "text/html": new Blob([html], { type: "text/html" }),
      "text/plain": new Blob([html], { type: "text/plain" }),
    });

    setClipboard(clipboardItem);
  }, [content]);

  return (
    <div>
      {rows.length > 0 && columns.length > 0 && (
        <Table>
          <TableHeader>
            <TableRow>
              {headers.map((heading, index) => (
                <TableHead
                  key={heading}
                  className={cn(
                    "px-1",
                    index === 0 ? "text-left" : "text-right",
                  )}
                >
                  {heading}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {Object.entries(dataDict).map(([row, items], rowIndex) => {
              const filteredUnits = items
                .slice(1)
                .filter((item) => item.value.trueValue !== "-");
              const rowUnit =
                filteredUnits.length > 0 &&
                filteredUnits.every(
                  (item) => item.value.unit === filteredUnits[0].value.unit,
                )
                  ? filteredUnits[0].value.unit || null
                  : null;

              return (
                <TableRow key={row}>
                  {items.map((item, index) => {
                    const showRowUnit = rowUnit && index === 0;
                    const showItemUnit =
                      !rowUnit && index > 0 && item.value.unit;

                    const unitPosition =
                      item.value.unit === "%" ? "after" : "before";

                    const cellContent = (
                      <span>
                        {showItemUnit && unitPosition === "before" && (
                          <Text type="label" color="subtle" className="mr-px">
                            {getTableRowUnit(item.value.unit || "")}
                          </Text>
                        )}
                        {item.value.displayValue}
                        {showRowUnit && (
                          <Text type="label" color="subtle" className="ml-px">
                            ({getTableRowUnit(rowUnit)})
                          </Text>
                        )}
                        {showItemUnit && unitPosition === "after" && (
                          <Text type="label" color="subtle" className="ml-px">
                            {getTableRowUnit(item.value.unit || "")}
                          </Text>
                        )}
                      </span>
                    );

                    return (
                      <TableCell
                        key={item.entry.identifiers?.id || index}
                        className={cn(
                          index === 0 ? "text-left font-medium" : "text-right",
                          item.value.displayValue === "-" && "text-content-2",
                        )}
                      >
                        {index > 0 ? (
                          <WidgetEntry
                            onSourceClick={onSource}
                            index={index + rowIndex * columns.length}
                            citations={item.citations}
                            refreshing={item.entry.refreshing}
                            widgetRefreshing={refreshing}
                            editablePopover={({ onClose }) =>
                              index > 0 ? (
                                <WidgetContentTableCellEditor
                                  onClose={onClose}
                                  index={index}
                                  rowIndex={rowIndex}
                                  rowUnit={rowUnit}
                                  item={item}
                                  data={content}
                                  widgetId={widgetId}
                                />
                              ) : undefined
                            }
                          >
                            <div className="min-w-full">{cellContent}</div>
                          </WidgetEntry>
                        ) : (
                          <div className="min-w-full">{cellContent}</div>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

const WidgetContentTableCellEditor = ({
  onClose,
  index,
  rowIndex,
  rowUnit,
  item,
  data,
  widgetId,
}: {
  onClose: () => void;
  index: number;
  rowIndex: number;
  rowUnit: string | null;
  item: TableDict;
  data: TimeSeries[];
  widgetId: string;
}) => {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const { mutateAsync } = useUpdateWidget({ orgId, dealId, widgetId });
  const [value, setValue] = useState<string>(item.value.trueValue.toString());
  const [unit, setUnit] = useState<string | null>(
    rowUnit || item.value.unit || null,
  );
  const [updating, setUpdating] = useState(false);
  const [removing, setRemoving] = useState(false);

  useEffect(() => {
    setUnit(rowUnit || item.value.unit || null);
    setValue(item.value.trueValue.toString());
    setUpdating(false);
  }, [item.value.trueValue, rowUnit, item.value.unit]);

  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement> | null,
    onClose: () => void,
    remove?: boolean,
  ) => {
    try {
      e?.preventDefault();

      if (remove) {
        setUpdating(false);
        setRemoving(true);
      } else {
        setUpdating(true);
        setRemoving(false);
      }

      const newValue = remove ? null : parseFloat(value);
      const newUnit = remove ? null : unit;

      await mutateAsync({
        content: updateTimeSeries(data, rowIndex, index - 1, newValue, newUnit),
      });
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setUpdating(false);
      setRemoving(false);
    }
  };

  const noUpdate =
    item.value.trueValue.toString() === value &&
    (unit === rowUnit || unit === item.value.unit);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/[^0-9.-]/g, "");

    if (inputValue === "" || inputValue === "." || inputValue === "-") {
      setValue(inputValue);
      return;
    }

    const numericValue = parseFloat(inputValue);

    if (!isNaN(numericValue)) {
      if (inputValue.includes(".")) {
        const [whole, decimal] = inputValue.split(".");
        setValue(`${whole}.${decimal.slice(0, 2)}`);
      } else {
        setValue(numericValue.toString());
      }
    }
  };

  return (
    <form
      onSubmit={(e) => onSubmit(e, onClose)}
      className="flex flex-col gap-1.5"
    >
      <fieldset className="flex gap-1 items-center">
        <Combobox
          options={[
            { value: "-", label: "-" },
            { value: "%", label: "%" },
            { value: "", label: "", isDivider: true },
            ...commonCurrencyList.map(({ code, symbol }) => ({
              value: code,
              label: `${code} - ${symbol}`,
            })),
            { value: "", label: "", isDivider: true },
            ...additionalCurrencyList.map(({ code, symbol }) => ({
              value: code,
              label: `${code} - ${symbol}`,
            })),
          ]}
          selected={unit || "-"}
          onSelect={(newUnit) => setUnit(newUnit === "-" ? null : newUnit)}
          width="100%"
          minWidth="150px"
        />
        <TextField
          id="editable-value"
          value={value === "" || value === null || value === "-" ? "" : value}
          onChange={handleChange}
          autoFocus
          className="w-fit"
          placeholder="-"
          disabled={updating}
          step="0.01"
          autoSize
          autoComplete="off"
        />
        {unit !== "%" && !isNaN(parseFloat(value)) && (
          <Text type="label" color="subtle" className="px-1.5">
            ({formatNumber(parseFloat(value))})
          </Text>
        )}
      </fieldset>
      <div className="flex gap-1.5">
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type="reset"
              variant="outline"
              disabled={updating}
              loading={removing}
              onClick={() => onSubmit(null, onClose, true)}
              iconLeft="delete"
            />
          </TooltipTrigger>
          <TooltipContent>Remove cell</TooltipContent>
        </Tooltip>
        <Button
          type="submit"
          disabled={noUpdate}
          loading={updating}
          fullWidth
          className="flex-1"
        >
          Update
        </Button>
      </div>
    </form>
  );
};

export const WidgetContentTableToMarkdown = (content: TimeSeries[]) => {
  const { headers, rows, columns, dataDict } = timeSeriesTransformer(content);

  if (rows.length === 0 || columns.length === 0) return "";

  const headerRow = `| ${headers.join(" | ")} |`;
  const separator = `| ${headers.map((_, i) => (i === 0 ? ":---" : "---:")).join(" | ")} |`;
  const dataRows = Object.entries(dataDict).map(([, items]) => {
    const rowUnit = items.find((item) => item.value.unit)?.value.unit;
    return `| ${items
      .map((item, index) => {
        let value = item.value.displayValue;
        if (index === 0) {
          value = `**${value}**`;
        }
        if (index === 0 && rowUnit) {
          value = `${value} ${rowUnit ? "*(" + getTableRowUnit(rowUnit) + ")*" : ""}`;
        }
        return value;
      })
      .join(" | ")} |`;
  });

  return [headerRow, separator, ...dataRows].join("\n");
};
