import { useParams } from "react-router-dom";
import { z } from "zod";

export const useValidatedParams = <T extends Params[]>(
  paramsList: T,
): Record<T[number], string> & Record<string, string> => {
  const params = useParams();

  const schema = createSchema(paramsList);
  const result = schema.safeParse(params);

  if (!result.success) {
    console.error(`Invalid params: ${JSON.stringify(result.error.errors)}`);
    return { ...params } as Record<T[number], string> & Record<string, string>;
  }

  // Ensure all non-validated params are treated as strings
  const validatedParams = result.data;
  const allParamsAsStrings = Object.keys(params).reduce(
    (acc, key) => {
      acc[key] = params[key] ?? "";
      return acc;
    },
    {} as Record<string, string>,
  );

  return { ...allParamsAsStrings, ...validatedParams };
};

const createSchema = <T extends Params[]>(paramsList: T) =>
  z.object(
    paramsList.reduce(
      (acc, param) => ({
        ...acc,
        [param]: z.string(),
      }),
      {} as Record<T[number], z.ZodString>,
    ),
  );

type Params =
  | "orgId"
  | "dealId"
  | "fileId"
  | "inviteId"
  | "userId"
  | "tableAggregationId"
  | "websiteId"
  | "dashboardId";
import { Handle } from "@/types/router";
import { IconType } from "capsa-ui";

export const createHandle = (label: string, icon?: IconType): Handle => ({
  crumb: {
    label,
    icon,
  },
});
