import { gfm } from "@joplin/turndown-plugin-gfm";
import TurndownService from "turndown";
import { Editor } from "@tiptap/react";

export const convertTipTapToMarkdown = (editor: Editor) => {
  const markdownConverter = new TurndownService({
    headingStyle: "atx",
    bulletListMarker: "-",
    codeBlockStyle: "fenced",
  });
  markdownConverter.use(gfm);
  markdownConverter.addRule("source", {
    filter: ["source"],
    replacement: (_, node) => {
      return `【${(node as HTMLElement).getAttribute("id")}】`;
    },
  });
  markdownConverter.addRule("underline", {
    filter: ["u"],
    replacement: (content) => {
      return `<u>${content}</u>`;
    },
  });
  markdownConverter.addRule("table", {
    filter: ["table"],
    replacement: function (_, node) {
      const rows = Array.from(node.querySelectorAll("tr"));

      const nonEmptyRows = rows.filter((row) => {
        const cells = Array.from(row.querySelectorAll("td, th"));
        return cells.some(
          (cell) =>
            cell?.textContent?.trim() !== "" || cell?.querySelector("source"),
        );
      });

      if (nonEmptyRows.length === 0) return "";

      const markdownRows = nonEmptyRows.map((row) => {
        const cells = Array.from(row.querySelectorAll("td, th"));
        return `| ${cells
          .map((cell) => {
            const text = cell?.textContent?.trim() || " ";

            // Handle Sources
            const source = cell?.querySelector("source");
            if (source) {
              const sourceId = source.getAttribute("id");
              return (
                text.replace(source?.textContent || "", "") + `【${sourceId}】`
              );
            }

            return text;
          })
          .join(" | ")} |`;
      });

      const columnCount = nonEmptyRows[0].querySelectorAll("td, th").length;
      const separator = `| ${Array(columnCount).fill("---").join(" | ")} |`;

      return `\n${markdownRows[0]}\n${separator}\n${markdownRows.slice(1).join("\n")}\n`;
    },
  });

  return markdownConverter.turndown(editor.getHTML());
};
