import * as React from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";

import { cn } from "../../../lib/utils";
import { cva, VariantProps } from "class-variance-authority";

export const avatarStyles = cva(
  "relative flex shrink-0 overflow-hidden rounded",
  {
    variants: {
      size: {
        sm: "h-icon-sm w-icon-sm",
        default: "h-icon-md w-icon-md",
        lg: "h-icon-lg w-icon-lg",
        hero: "h-8 w-8 rounded-layout",
      },
    },
    defaultVariants: {
      size: "default",
    },
  },
);

export type AvatarSize = VariantProps<typeof avatarStyles>["size"];

export interface AvatarProps
  extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>,
    VariantProps<typeof avatarStyles> {
  src?: string;
  initials?: string;
}

export const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  AvatarProps
>(({ className, src, initials, size = "default", ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(avatarStyles({ size }), className)}
    {...props}
  >
    <AvatarImage src={src} />
    <AvatarFallback>{initials ? initials.toUpperCase() : ""}</AvatarFallback>
  </AvatarPrimitive.Root>
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn("aspect-square h-full w-full", className)}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      "flex h-full w-full items-center justify-center rounded bg-surface-primary-accent text-content-inverted text-label font-bold",
      className,
    )}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;
