import { useNavigate } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useDealQuery, useUpdateDealMutation } from "../../hooks/deals";
import { DealRead } from "../../api/deals";
import { useUserDealPermissionsQuery } from "../../hooks/userDealRoles";
import { hasPermission } from "../../utils";
import { paths } from "@/config/paths";
import { useValidatedParams } from "@/utils/router";
import { Main } from "../layout/Main";
import { Button, TextField } from "capsa-ui";

interface FormValues {
  name: string;
  companyName: string;
  companyAliases: { value: string }[];
}

function DealsUpdateForm({ deal }: { deal: DealRead }) {
  const permissionQuery = useUserDealPermissionsQuery(deal.org_id, deal.id);
  const { control, handleSubmit, reset, register } = useForm<FormValues>({
    defaultValues: {
      name: deal.name,
      companyName: deal.company_name,
      companyAliases: deal.company_aliases.map((value) => ({ value })),
    },
  });
  const navigate = useNavigate();
  const mutation = useUpdateDealMutation(deal.org_id, deal.id);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "companyAliases",
  });
  switch (hasPermission(permissionQuery, "deals.update")) {
    case "loading":
      return (
        <div className="flex border-b-[1px] border-gray-200 pb-4">
          <p className="text-sm max-w-lg text-gray-700">Loading</p>
        </div>
      );
    case "false":
      return (
        <div className="flex border-b-[1px] border-gray-200 pb-4">
          <p className="text-sm max-w-lg text-gray-700">
            You do not have permission to update this deal.
          </p>
        </div>
      );
    case "true":
      return (
        <div>
          <form
            onSubmit={handleSubmit((values) => {
              mutation.mutate(
                {
                  name: values.name,
                  company_name: values.companyName,
                  company_aliases: values.companyAliases.map(
                    (alias) => alias.value,
                  ),
                },
                {
                  onSuccess: () => {
                    navigate(
                      paths.orgs.selected.deals.selected.getHref(
                        deal.org_id,
                        deal.id,
                      ),
                    );
                    reset();
                  },
                },
              );
            })}
          >
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <div className="flex flex-col gap-4">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="name"
                        label="Deal Name"
                        type="text"
                        {...field}
                        value={field.value ?? ""}
                      />
                    )}
                  />
                  <Controller
                    name="companyName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="companyName"
                        label="Company Name"
                        type="text"
                        {...field}
                        value={field.value ?? ""}
                      />
                    )}
                  />
                  {fields.length > 0 && (
                    <div className="flex flex-col gap-2">
                      <label className="text-label text-content-1 font-bold">
                        Company Aliases
                      </label>
                      <div className="flex flex-col gap-2">
                        {fields.map((field, index) => (
                          <div
                            className="flex gap-2 items-end relative"
                            key={index}
                          >
                            <TextField
                              id={`companyAliases`}
                              key={field.id}
                              {...register(`companyAliases.${index}.value`)}
                            />
                            <Button
                              variant="ghost"
                              iconLeft="cancel"
                              flat
                              onClick={() => remove(index)}
                              className="absolute right-1 top-1"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="py-6">
              <div className="flex justify-end gap-2 flex-wrap">
                <Button
                  variant="outline"
                  iconLeft="add"
                  className="w-fit"
                  onClick={(e) => {
                    e.preventDefault();
                    append({ value: "" });
                  }}
                >
                  Add Alias
                </Button>
                <div className="flex-1" />
                <Button
                  variant="outline"
                  onClick={() =>
                    navigate(paths.orgs.selected.deals.getHref(deal.org_id))
                  }
                >
                  Cancel
                </Button>
                <Button type="submit">Update</Button>
              </div>
            </div>
          </form>
        </div>
      );
    default:
      return (
        <div className="flex border-b-[1px] border-gray-200 pb-4">
          <p className="text-sm max-w-lg text-gray-700">
            Error loading permissions.
          </p>
        </div>
      );
  }
}

export default function DealsUpdate() {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const dealQuery = useDealQuery(orgId as string, dealId as string);
  if (dealQuery.isLoading) {
    return (
      <Main>
        <p>Loading...</p>
      </Main>
    );
  }

  if (dealQuery.isError) {
    return (
      <Main>
        <p>Error loading deal.</p>
      </Main>
    );
  }

  return (
    <Main title="Update deal">
      <DealsUpdateForm deal={dealQuery.data} />
    </Main>
  );
}
