import { User } from "../../../types";
import {
  baseURL,
  mapToSnakeCase,
  PaginatedResponse,
  resolveFetch,
  resolveFetchPaginated,
} from "../../../api/shared";
import {
  WidgetTemplate,
  CreateWidgetTemplateBody,
  UpdateWidgetTemplateBody,
} from "../types";

export const getWidgetTemplate = async (
  user: User,
  orgId: string,
  widgetTemplateId: string,
): Promise<WidgetTemplate> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/widget_templates/${widgetTemplateId}`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};

export const listWidgetTemplates = async (
  user: User,
  orgId: string,
): Promise<PaginatedResponse<WidgetTemplate>> => {
  return await resolveFetchPaginated(
    fetch(`${baseURL}/orgs/${orgId}/widget_templates?limit=100`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};

export const createWidgetTemplate = async (
  user: User,
  orgId: string,
  body: CreateWidgetTemplateBody,
): Promise<{ id: string }> => {
  const snake_case_body = mapToSnakeCase(body);
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/widget_templates`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(snake_case_body),
    }),
    true,
  );
};

export const updateWidgetTemplate = async (
  user: User,
  orgId: string,
  widgetTemplateId: string,
  body: UpdateWidgetTemplateBody,
): Promise<{ id: string }> => {
  const snake_case_body = mapToSnakeCase(body);
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/widget_templates/${widgetTemplateId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(snake_case_body),
    }),
    true,
  );
};

export const deleteWidgetTemplate = async (
  user: User,
  orgId: string,
  widgetTemplateId: string,
): Promise<void> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/widget_templates/${widgetTemplateId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};
