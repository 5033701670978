import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { combine } from "zustand/middleware";
import { subscribeWithSelector } from "zustand/middleware";
import { AssistantContext } from "../types/state";
import { ThreadStatus } from "../types/api";

const initialState = {
  isOpen: false,
  threadId: undefined as string | undefined,
  defaultContext: undefined as AssistantContext | undefined,
  context: undefined as AssistantContext | undefined,
  threadStatus: undefined as ThreadStatus | undefined,
};

export const useAssistantStore = create(
  devtools(
    persist(
      subscribeWithSelector(
        combine(initialState, (set) => ({
          reset: () => set(initialState),

          toggleAssistant: () => set((state) => ({ isOpen: !state.isOpen })),
          openAssistant: () => set(() => ({ isOpen: true })),
          closeAssistant: () => set(() => ({ isOpen: false })),

          setThreadId: (threadId: string) => set(() => ({ threadId })),
          resetThreadId: () => set(() => ({ threadId: undefined })),

          setThreadStatus: (status: ThreadStatus) =>
            set(() => ({ threadStatus: status })),

          setDefaultContext: (context: AssistantContext) =>
            set(() => ({ defaultContext: context })),
          setContext: (context: AssistantContext) => set(() => ({ context })),
        })),
      ),
      {
        name: "AssistantStore",
      },
    ),
  ),
);

useAssistantStore.subscribe(
  (state) => state.isOpen,
  (isOpen) => {
    const { resetThreadId, defaultContext, setContext } =
      useAssistantStore.getState();
    if (!isOpen) {
      if (defaultContext) {
        setContext(defaultContext);
      }
      resetThreadId();
    }
  },
);

useAssistantStore.subscribe(
  (state) => state.defaultContext,
  (defaultContext) => {
    const { setContext } = useAssistantStore.getState();
    if (defaultContext) {
      setContext(defaultContext);
    }
  },
);
