import { Icon, Button, Spinner, Text } from "capsa-ui";
import {
  useDeleteSharepointSyncJobMutation,
  useListSyncJobsQuery,
} from "@/features/sharepoint/hooks/syncJobs";
import { SharepointSyncJobStatus } from "@/features/sharepoint/types/sharepoint";
import { useDealFilesQuery } from "@/hooks/deals";
import { useEffect, useState } from "react";
import { useValidatedParams } from "@/utils/router";

export const SharepointSync = () => {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const { data, isSuccess } = useListSyncJobsQuery(orgId || "", dealId || "", [
    SharepointSyncJobStatus.PENDING,
    SharepointSyncJobStatus.IN_PROGRESS,
    SharepointSyncJobStatus.FAILED,
  ]);
  const filesQuery = useDealFilesQuery(orgId || "", dealId || "");
  const deleteJob = useDeleteSharepointSyncJobMutation();
  const [removedIds, setRemovedIds] = useState<string[]>([]);

  useEffect(() => {
    filesQuery.refetch();
  }, [data]);

  if (!isSuccess) {
    return null;
  }

  return (
    <>
      {data.length > 0 && (
        <div className="flex flex-col gap-1">
          {data.map((syncJob) => {
            const status = syncJob.jobStatus;

            const totalFiles = syncJob.externalFileIds.length;
            const ingestedFiles = syncJob.syncedFileIds.length || 0;
            const totalFolders = syncJob.externalFolderIds.length;

            const handleDismiss = () => {
              deleteJob.mutate({
                jobId: syncJob.id,
              });
              setRemovedIds([...removedIds, syncJob.id]);
            };

            if (removedIds.includes(syncJob.id)) {
              return null;
            }

            if (
              status === SharepointSyncJobStatus.FAILED ||
              status === SharepointSyncJobStatus.COMPLETED
            ) {
              return (
                <div
                  key={syncJob.id}
                  className="flex gap-2 items-center border rounded p-4"
                >
                  {status === SharepointSyncJobStatus.FAILED && (
                    <>
                      <Icon
                        type="alertFilled"
                        className="text-content-danger"
                      />
                      <Text type="label" color="subtle">
                        Sharepoint import failed
                      </Text>
                    </>
                  )}
                  {status === SharepointSyncJobStatus.COMPLETED && (
                    <>
                      <Icon
                        type="checkFilled"
                        className="text-content-primary"
                      />
                      <Text type="label" color="subtle">
                        Imported{" "}
                        {ingestedFiles > 0 && (
                          <Text type="label" weight="bold">
                            {ingestedFiles} Files
                          </Text>
                        )}
                        {ingestedFiles > 0 && totalFolders > 0 && (
                          <Text type="label"> and </Text>
                        )}
                        {totalFolders > 0 && (
                          <Text type="label" weight="bold">
                            {totalFolders} Folders
                          </Text>
                        )}{" "}
                        from Sharepoint
                      </Text>
                    </>
                  )}
                  <div className="flex-1" />
                  <div className="h-0 flex-center">
                    <Button
                      onClick={handleDismiss}
                      variant="outline"
                      iconLeft="cancel"
                      size="sm"
                    />
                  </div>
                </div>
              );
            }

            return (
              <div
                key={syncJob.id}
                className="flex gap-2 items-center border rounded p-4"
              >
                <Spinner />
                {totalFolders > 0 && (
                  <Text type="label" color="subtle">
                    {`Importing ${totalFolders} folder${totalFolders > 1 ? "s" : ""}`}
                  </Text>
                )}
                {totalFiles > 0 && totalFolders > 0 && (
                  <Text color="faint">|</Text>
                )}
                {totalFiles > 0 && (
                  <Text type="label" color="subtle">
                    {`Importing Files (${ingestedFiles}/${totalFiles})`}
                  </Text>
                )}
                <div className="flex-1" />
                <Icon type="sharepoint" />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
