import { Dashboard } from "../types/api";
import { useAuth } from "@/hooks/shared";
import { assertUserIsAuthenticated } from "@/utils";
import { useDashboards } from "./getDashboards";
import { useGlobalStore } from "@/store";
import { useEffect, useState } from "react";

type useCurrentDashboardOptions = {
  orgId: string;
  dealId: string;
};

export const useCurrentDashboard = ({
  orgId,
  dealId,
}: useCurrentDashboardOptions) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  const dashboardsQuery = useDashboards({ orgId, dealId });
  const { selectedDashboards, setSelectedDashboards } = useGlobalStore();
  const key = `${orgId},${dealId}`;
  const selectedDashboardId =
    key in selectedDashboards ? selectedDashboards[key] : undefined;
  const [selectedDashboard, setSelectedDashboard] = useState<
    Dashboard | undefined
  >(undefined);

  useEffect(() => {
    // If no dashboard is selected, set the first dashboard as the current dashboard
    if (
      dashboardsQuery.data &&
      dashboardsQuery.data.length > 0 &&
      !selectedDashboardId
    ) {
      setSelectedDashboards(orgId, dealId, dashboardsQuery.data[0].id);
      setSelectedDashboard(dashboardsQuery.data[0]);
    }
    // If the selected dashboard is not in the list of dashboards, set the first dashboard as the current dashboard
    else if (
      dashboardsQuery.data &&
      dashboardsQuery.data.length > 0 &&
      selectedDashboardId &&
      !dashboardsQuery.data.find((d) => d.id === selectedDashboardId)
    ) {
      setSelectedDashboards(orgId, dealId, dashboardsQuery.data[0].id);
      setSelectedDashboard(dashboardsQuery.data[0]);
    }
    // If the selected dashboard is in the list of dashboards, set the selected dashboard
    else if (
      dashboardsQuery.data &&
      selectedDashboardId &&
      dashboardsQuery.data.find((d) => d.id === selectedDashboardId)
    ) {
      setSelectedDashboard(
        dashboardsQuery.data.find((d) => d.id === selectedDashboardId),
      );
    }
  }, [
    orgId,
    dealId,
    dashboardsQuery.data,
    selectedDashboardId,
    setSelectedDashboards,
    setSelectedDashboard,
  ]);

  return selectedDashboard;
};
